import Layout from "../../components/layout/Layout"
import { Helmet } from 'react-helmet';

const PrivacyPolicyRU = () => {
    return (
        <div >
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 10, marginBottom: 30 }}>
                <h2>Политика конфиденциальности</h2>
                <p>Политика конфиденциальности регулирует сбор, использование и обработку персональных данных пользователей веб-сайта <strong>SkTech</strong> (далее — «Сайт»), который предоставляет услуги по управлению проектами. Пользуясь нашим Сайтом, Пользователь соглашается с условиями данной Политики.</p>

                <h3>1. Сбор и использование персональных данных</h3>
                <ol>
                    <li>
                        <p>Сайт собирает следующие данные:</p>
                        <ul>
                            <li>Имя, фамилия, электронная почта, номер телефона;</li>
                            <li>Информация, которую Пользователь предоставляет добровольно (во время регистрации, запросов).</li>
                        </ul>
                    </li>
                    <li>
                        <p>Цели обработки данных:</p>
                        <ul>
                            <li>Предоставление доступа к Сервису;</li>
                            <li>Техническая поддержка;</li>
                            <li>Информирование о новостях и обновлениях;</li>
                            <li>Аналитика для улучшения качества услуг.</li>
                        </ul>
                    </li>
                </ol>

                <h3>2. Защита и хранение данных</h3>
                <ol>
                    <li>Сайт обеспечивает надлежащий уровень защиты персональных данных Пользователей от несанкционированного доступа, утраты или изменений.</li>
                    <li>Срок хранения: до момента удаления профиля Пользователя или прекращения работы Сайта.</li>
                </ol>

                <h3>3. Раскрытие персональных данных</h3>
                <ol>
                    <li>
                        Сайт не передает персональные данные третьим лицам, за исключением случаев, когда они могут передаваться:
                        <ul>
                            <li>Платежным системам для осуществления транзакций;</li>
                            <li>Партнерам для обслуживания Сайта;</li>
                            <li>Уполномоченным органам в соответствии с законодательством Украины.</li>
                        </ul>
                    </li>
                </ol>

                <h3>4. Использование файлов cookie</h3>
                <ol>
                    <li>Файлы cookie используются для сохранения настроек Пользователя, анализа трафика, улучшения работы Сайта.</li>
                    <li>Пользователь может отказаться от cookie в настройках браузера, но это может ограничить функциональность.</li>
                </ol>

                <h3>5. Права Пользователей</h3>
                <ol>
                    <li>
                        Пользователь имеет право:
                        <ul>
                            <li>Доступа к своим данным и их изменения;</li>
                            <li>Ограничения обработки или удаления данных;</li>
                        </ul>
                    </li>
                    <li>Запросы подаются через контактную информацию, указанную ниже.</li>
                </ol>

                <h3>6. Изменения в Политике</h3>
                <p>Сайт оставляет за собой право изменять Политику. Обновления публикуются с датой вступления в силу. Продолжение использования Сайта означает принятие изменений.</p>

                <h3>7. Контактная информация</h3>
                <p>ООО "САНПОИНТ АЙ-ТИ СОЛЮШНС"<br />
                    Код ЕГРПОУ: 42783354<br />
                    Адрес: ул. Серповая 11, Киев, Украина<br />
                    Email: sktech@sunpoint.com.ua</p>

                <p>Дата последнего обновления: 27.11.2024</p>

            </div>
        </div>
    )
}
export default PrivacyPolicyRU;