import { ProjectTask } from "../stores/ProjectTasksStore";
import SkTechApiClient from "./base/SkTechApiClient";


export default class ProjectTaskApiClient extends SkTechApiClient {

  public addTask = async (task: ProjectTask): Promise<any> => {
    return await this.Post(`/api/ProjectTask`, {
      title: task.title,
      description: task.description,
      startDate: task.startDate.format('YYYY-MM-DD'),
      deadLine: task.dueDate.format('YYYY-MM-DD'),
      companyUserId: task.companyUserId,
      projectId: task.projectId,
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getTasks = async (): Promise<any> => {
    return await this.Get(`/api/ProjectTask`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getTaskById = async (id: string): Promise<any> => {
    return await this.Get(`/api/ProjectTask/getByID/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getTaskByProjectId = async (id: string): Promise<any> => {
    return await this.Get(`/api/ProjectTask/ByProjectId/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public deleteTaskById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/ProjectTask/Delete?id=${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public editProjects = async (task: ProjectTask): Promise<any> => {
    return await this.Post(`/api/ProjectTask/${task.id}`,
      task
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

}