import { Popover, Input, Button, message, Flex, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useStore } from "../../stores/root-store-context";
import { Roles } from "../../stores/EmployeesStore";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import MessageNotHaveProjects from "./MessageNotHaveProjects";
import { Project } from "../../stores/ProjectsStore";

const AddTimerBlock = () => {
  const [timeLog, setTimeLog] = useState<any>({ description: "" });
  const { t } = useTranslation();
  const { applicationStore, timeReportStore, projectsStore } = useStore();

  const [currentTimer, setCurrentTimer] = useState<any>();
  const [userProjects, setUserProjects] = useState<any[]>([]);

  const getTimer = async () => {
    timeReportStore.setLoading(true);
    const timer = await timeReportStore.getTimer(applicationStore.user.id);
    setCurrentTimer(timer);
    timeReportStore.setLoading(false);
  }

  const fetchProjects = async () => {
    const projects: any = await projectsStore.getAll();
    const userProjects = projects?.filter((project: any) =>
      project.developers && (
        project.developers.some((user: any) => user.id === applicationStore.user.id) ||
        project.projectResponsibleId === applicationStore.user.id
      )
    );
    setUserProjects(userProjects);

  }

  const startTimer = async () => {
    if (timeLog.projectId === undefined) message.error("Choose the project");
    else {
      await timeReportStore.startTimer(timeLog.projectId);
      getTimer();
      document.dispatchEvent(new CustomEvent("timerStarted"));
    }
    fetchProjects();
  };

  useEffect(() => {
    if (projectsStore.projects.length > 0) {
        const userProjects = projectsStore.projects?.filter((project: Project) => {
            return project.isPublish || (project.developers && (
                project.developers.some((user: any) => user.id === applicationStore.user.id) ||
                project.projectResponsibleId === applicationStore.user.id
            ))
        }
        );
        setUserProjects(userProjects)
    }
}, [projectsStore.projects])

  useEffect(() => {
    projectsStore.getAll()
  }, [])

  return (
    <Popover
      trigger={['click']}
      placement="bottom"
      content={<Flex>
        {userProjects.length > 0 ?
          <>
            <Select
              placeholder={t("SelectProject")}
              disabled={currentTimer}
              onClick={() => {
              }}
              value={currentTimer ? currentTimer.projectId : timeLog.projectId}
              onSelect={(value) =>
                setTimeLog({ ...timeLog, projectId: value })
              }
              style={{ width: "150px" }}
              options={userProjects?.map((project: any, index: number) => (
                { key: project.id, value: project.id, label: project.title }
              ))}
            >
            </Select>
            <Button type="link" shape="circle" icon={<PlayCircleOutlined style={{ fontSize: '22px' }} />} style={{ marginLeft: 10 }} onClick={startTimer} />
          </>
          :
          <MessageNotHaveProjects />}
      </Flex>}>
      <Button type="link" shape="circle" icon={<PlayCircleOutlined style={{ fontSize: '22px' }} />} style={{ marginLeft: 10 }} />
    </Popover>

  );
}

export default AddTimerBlock;
