import SkTechApiClient from "./base/SkTechApiClient";

export default class PaymentApiClient extends SkTechApiClient {
  public getBalance = async (companyId: string): Promise<any> => {
    return await this.Get(`/api/Payment/balance/${companyId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Failed to fetch balance.");
      })
      .catch(function (error) {
        console.error("Error fetching balance:", error);
        throw error;
      });
  };

  public getPaymentDate = async (companyId: string): Promise<any> => { 
    return await this.Get(`/api/Payment/payment-date/${companyId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Failed to fetch payment date.");
      })
      .catch(function (error) {
        console.error("Error fetching payment date:", error);
        throw error;
      });
};

  public getTransactionHistory = async (companyId: string): Promise<any> => {
    return await this.Get(`/api/Payment/transactionHistory/${companyId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Failed to fetch transaction history.");
      })
      .catch(function (error) {
        console.error("Error fetching transaction history:", error);
        throw error;
      });
  };
  
  public createLiqPayRequest = async (companyId?: string, formData?: any): Promise<any> => {
    var baseUrl = process.env.REACT_APP_API_URL as string;
    return await this.Post(`/api/Payment/liqpay-request`, { companyId, formData, baseUrl })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Failed to create LiqPay request.");
      })
      .catch(function (error) {
        console.error("Error creating LiqPay request:", error);
        throw error;
      });
  };

  public changeTariffPlan = async (companyId: string, newTariffPlanId: number): Promise<any> => {
    return await this.Post(`/api/Payment/changeTariffPlan`, { companyId, newTariffPlanId })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Failed to change tariff plan.");
      })
      .catch(function (error) {
        console.error("Error changing tariff plan:", error);
        throw error;
      });
  };
  
}
