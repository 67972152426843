import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import ApplicationStore from './ApplicationStore';

enum Status {
  Active = 0,
  Done = 1,
  Overdue = 2,
  Approved = 3,
  Rejected = 4
}

class SignalRService {
  
  private connection: HubConnection | null = null;

  constructor(private applicationStore: ApplicationStore) {}

  public startConnection = async () => {

    if (this.connection && this.connection.state === 'Connected') {
        console.log('Already connected to SignalR Hub');
        return;
    }


    try {
      const token = localStorage.getItem('token');
      console.log('Starting SignalR connection...');
      this.connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/notificationHub`, {
          accessTokenFactory: () => token ? token : Promise.reject('No token available'),
          withCredentials: true
        })
        .configureLogging(LogLevel.Debug) 
        .withAutomaticReconnect()
        .build();

      await this.connection.start();
      console.log('Connected to SignalR Hub');

      this.connection.on('ReceiveNotification', (notification) => {
        console.log('ReceiveNotification event triggered:', notification);
        this.formatAndHandleNotification(notification);
      });

      this.connection.on('ReceiveTask', (task) => {
        console.log('ReceiveTask event triggered:', task);
        if (typeof task.status === 'number') {
          task.status = Status[task.status];
        }
        this.handleIncomingTask(task);
      });

      this.connection.on('ReceiveTaskUpdate', (taskId, taskTitle, taskStatus) => {
        console.log('Task updated:', { taskId, taskTitle, taskStatus });
        this.updateTaskInStore(taskId, taskTitle, taskStatus);
      });

      console.log('Subscribed to SignalR events');
    } catch (error) {
      console.error('SignalR connection error: ', error);
    }
  };

  private updateTaskInStore = (taskId: number, taskTitle: string, taskStatus: string) => {
    const task = this.applicationStore.tasks.find(t => t.key === taskId.toString());
    if (task) {
      task.label = taskTitle;
      task.status = taskStatus;
      this.applicationStore.tasks = [...this.applicationStore.tasks];
    }
  };

  private formatAndHandleNotification = (notification: { id?: number; userId: number, text: string; date: string; type: string; link: string; isRead?: boolean }) => {
    notification.date = new Date(notification.date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/\//g, '.').replace(',', '');

    this.handleIncomingNotification(notification);
  };

  private handleIncomingNotification = (notification: any) => {
    console.log('Received notification:', notification);

    if (!notification.id) {
        console.error('Notification ID is missing. Cannot process notification.');
        return;
    }

    const translationParams =
    typeof notification.translationParams === 'string'
      ? JSON.parse(notification.translationParams)
      : notification.translationParams;



    if (this.applicationStore.notifications) {
        this.applicationStore.notifications.push({
            label: notification.text,
            translationKey: notification.translationKey,
            translationParams: translationParams,
            key: notification.id.toString(),
            onClick: () => window.location.href = notification.link,
            date: notification.date,
            isRead: notification.isRead || false,
            link:notification.link
        });
        console.log('Notification added to store:', this.applicationStore.notifications);
        this.applicationStore.notifications = [...this.applicationStore.notifications];
    } else {
        console.warn('ApplicationStore notifications array is undefined');
    }
  };

  private handleIncomingTask = (task: any) => {
    console.log('Received task:', task);

    if (!task.id) {
        console.error('Task ID is missing. Cannot process task.');
        return;
    }

    const formattedDate = new Date(task.startDate).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).replace(/\//g, '.').replace(',', '');

    const translationParams =
        typeof task.translationParams === 'string'
            ? JSON.parse(task.translationParams)
            : task.translationParams;

    if (this.applicationStore.tasks) {
        this.applicationStore.tasks.push({
            key: task.id.toString(),
            translationKey: task.translationKey,
            translationParams: translationParams,
            label: task.title,
            onClick: () => window.location.href = task.linkTask,
            isCompleted: task.status === 'Done',
            date: formattedDate,
            linkTask: task.linkTask,
            status: task.status
        });
        console.log('Task added to store:', this.applicationStore.tasks);
        this.applicationStore.tasks = [...this.applicationStore.tasks];
    } else {
        console.warn('ApplicationStore tasks array is undefined');
    }
};


}

export default SignalRService;
