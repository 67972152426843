import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Select, Image, Spin, Table, TableProps, Tag, Typography, message, AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EyeOutlined, MailTwoTone } from '@ant-design/icons';
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Employee, Skill } from "../../api/EmployeesApiClient";
import { Roles } from "../../stores/EmployeesStore";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { useTranslation } from "react-i18next";
import { getUserComponent } from "../../helpers/user";
import { Option } from "antd/es/mentions";
import { AddJobTitle } from "./AddJobTitle";

const Search = Input.Search;

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}
export type InviteUser = {
    email: string,
    role: string,
    chiefId: string,
}
const Users = observer(() => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { applicationStore, employeesStore } = useStore();
    const [searchText, setSearchText] = useState("");
    const [filteredUser, setFilteredUser] = useState<Employee[]>([]);

    const [selectedUser, setSelectedUser] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const [isModalJobTitle, setIsModalJobTitle] = useState(false);

    const showModalJobTitle = () => {
        setIsModalJobTitle(true);
    };

    const hideModalJobTitle = () => {
        setIsModalJobTitle(false);
    };

    const getUserName = (userId: string) => {
        const user = employeesStore.users?.find(el => el.id === userId);
        if (user) {
            return `${user.firstName} ${user.lastName}`
        } else {
            return ""
        }
    };

    const columns: TableProps<Employee>['columns'] = [
        {
            title: `${t("user.Name")}`,
            dataIndex: 'photoId',
            key: 'photo',
            render: (data, record) => <div>{getUserComponent(record, applicationStore.company?.Tenaut)}</div>,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.firstName)

        },
        // {
        //     title: `${t("user.BirthDate")}`,
        //     dataIndex: 'birthDay',
        //     key: 'birthDay',
        //     width: "220",
        //     render: (date) => <div>{date?.format('YYYY-MM-DD')}</div>,
        //     sorter: (a: Employee, b: Employee) => DateSort(a.birthDay?.format('YYYY-MM-DD'), b.birthDay?.format('YYYY-MM-DD'))
        // },
        {
            title: `${t("ImmediateChief")}`,
            dataIndex: 'chiefId',
            key: 'immediateChief',
            render: (immediateChief, record) => {
                if (applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager || applicationStore.user.id === record.id) {
                    return (
                        <Flex gap={5} align="center">
                        <AutoComplete
                            style={{ height: 40, width: 250 }}
                            placeholder={t("ImmediateChief")}
                            value={getUserName(immediateChief)}
                            onChange={(e, option: any) => {
                                // form.setFieldValue("chiefId", option?.id)
                                record.chiefId = option?.id;
                                employeesStore.editUser({
                                    id: record.id,
                                    chiefId: option?.id
                                }).then(() => {
                                    employeesStore.getUsers().catch(console.log)
                                })
                            }}
                            options={employeesStore.users?.filter(user => user.id !== record.id).map((el, index) => ({
                                value: el.id,
                                id: el.id, label: <Flex align="center">
                                    <Image
                                        preview={false}
                                        width={40}
                                        height={40}
                                        style={{ borderRadius: "50%", objectFit: "cover", minWidth: 40 }}
                                        src={el.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${el.photoId}` :
                                            `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                                        }
                                    />
                                    <Flex vertical justify="start" gap={5} style={{ marginLeft: 20 }}>
                                        <div>{el.firstName + " " + el.lastName}</div>
                                        <div>{el.email}</div>
                                    </Flex>
                                </Flex>
                            }))}
                        />
                        <Button disabled={!( applicationStore.user.roleId === Roles.Owner || applicationStore.user.id === record.id)} 
                        onClick={() => {
                            employeesStore.editUser({
                                id: record.id,
                                chiefId: -1
                            }).then(() => {
                                employeesStore.getUsers().catch(console.log)
                            })
                        }}
                        type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                        </Flex>
                    )
                } else if (applicationStore.user.roleId === Roles.User) {
                    return (
                        <div>{getUserComponent(employeesStore.users.find(el => el.id === immediateChief), applicationStore.company?.Tenaut, 20)}</div>
                    )
                } else {
                    return (
                        <div>{getUserComponent(employeesStore.users.find(el => el.id === immediateChief), applicationStore.company?.Tenaut, 20)}</div>
                    )
                }
            },
            sorter: (a: Employee, b: Employee) => TextSort(a.jobTitleId || "", b.jobTitleId || "")
        },
        {
            title: `${t("user.JobTitle")}`,
            dataIndex: 'jobTitleId',
            key: 'jobTitle',
            render: (jobTitleId, record) => {
                if (applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager) {
                    return (
                        <>
                            <AutoComplete
                                key={"length" + employeesStore.jobTitles.length}
                                value={employeesStore.jobTitles.find(el => el.id === jobTitleId)?.title}
                                style={{ minWidth: 200 }}
                                placeholder={`${t("user.JobTitle")}`}
                                options={[...employeesStore.jobTitles?.map((el, index) => ({
                                    key: el.id,
                                    value: el.title,
                                    label: el.title,
                                    data: el
                                })), {
                                    label: <Button onClick={() => {
                                        setSelectedUser(record.id);
                                        showModalJobTitle();
                                    }}>{t("CreateJobTitle")}</Button>,
                                }]}

                                onSelect={(value, option: any) => {
                                    if (option?.value) {
                                        employeesStore.editUser({
                                            id: record.id,
                                            jobTitleId: option?.key
                                        }).then(() => {
                                            employeesStore.getUsers().catch(console.log)
                                        })
                                    }
                                }}
                            />
                        </>
                    );
                }
                const jobTitle = employeesStore.jobTitles.find(el => el.id === jobTitleId)?.title;

                return <div>{jobTitle || t(" ")}</div>;
            },
            sorter: (a: Employee, b: Employee) => TextSort(a.jobTitleId || "", b.jobTitleId || "")
        },
        {
            title: `${t("RoleInCompany")}`,
            dataIndex: 'roleId',
            key: 'role',
            render: (roleId: number, record) => {
                const isOwnerRole = record.roleId === Roles.Owner;

                if (isOwnerRole) {
                    return <div>{t("Owner")}</div>;
                }


                if (record.roleId === Roles.Owner) {
                    return (
                        <Select
                            style={{ width: 200 }}
                            disabled={applicationStore.user.id === record.id && applicationStore.user.roleId === Roles.Owner}
                            value={roleId}
                            placeholder={t("RoleInCompany")}
                            options={[
                                { value: Roles.Owner, label: t("Owner"), disabled: true },
                                { value: Roles.Manager, label: t("Manager") },
                                { value: Roles.User, label: t("User") },
                                { value: Roles.Viewer, label: t("Viewer") },
                                { value: Roles.Financier, label: t("Financier") },
                            ]}
                            onSelect={(value, option: any) => {
                                if (option?.value) {
                                    employeesStore.editUser({
                                        id: record.id,
                                        roleId: option.value
                                    }).then(async () => {
                                        await employeesStore.getUsers().catch(console.log)
                                    })
                                }
                            }}
                        />
                    )
                } else if (applicationStore.user.roleId === Roles.Owner) {
                    return (
                        <Select
                            style={{ width: 200 }}
                            disabled={applicationStore.user.id === record.id && applicationStore.user.roleId === Roles.Owner}
                            value={roleId}
                            placeholder={t("RoleInCompany")}
                            options={[
                                { value: Roles.Manager, label: t("Manager") },
                                { value: Roles.User, label: t("User") },
                                { value: Roles.Viewer, label: t("Viewer") },
                                { value: Roles.Financier, label: t("Financier") },
                            ]}
                            onSelect={(value, option: any) => {
                                if (option?.value) {
                                    employeesStore.editUser({
                                        id: record.id,
                                        roleId: option.value
                                    }).then(async () => {
                                        await employeesStore.getUsers().catch(console.log)
                                    })
                                }
                            }}
                        />
                    )
                } else if (applicationStore.user.roleId === Roles.Manager) {
                    return (
                        <Select
                            style={{ width: 200 }}
                            value={roleId}
                            placeholder={t("RoleInCompany")}
                            options={[
                                { value: Roles.Manager, label: t("Manager") },
                                { value: Roles.User, label: t("User") },
                                { value: Roles.Viewer, label: t("Viewer") },
                                { value: Roles.Financier, label: t("Financier") },
                            ]}
                            onSelect={(value, option: any) => {
                                if (option?.value) {
                                    employeesStore.editUser({
                                        id: record.id,
                                        roleId: option.value
                                    }).then(async () => {
                                        await employeesStore.getUsers().catch(console.log)
                                    })
                                }
                            }}
                        />
                    )
                } else {
                    return <div>{t(Roles[roleId])}</div>;
                }
            },
            sorter: (a: Employee, b: Employee) => TextSort(a.roleId?.toString() || "", b.roleId?.toString() || "")
        },
        {
            title: `${t("Skills")}`,
            dataIndex: 'userSkills',
            key: 'userSkills',
            render: (skills) => <Flex wrap style={{ maxWidth: 200 }} gap={10}>
                {skills?.map((el: any) => {
                    return <Tag color="blue">{el.skill.title}</Tag>;
                })}
            </Flex>,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.firstName),
            filters: applicationStore.skills?.map(skill => { return { text: skill.title, value: skill.skillId } }),
            onFilter: (value, record) => record.userSkills.some(el => el.skill.skillId === value),


        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: Employee) => {
                console.log(record)
                if (record.isApproveInvite === false) {
                    return (
                        <>
                            <Flex justify="space-between">
                                <Popconfirm
                                    title={t("ResendTheInvitation")}
                                    description={t("SendNewEmailInvite")}
                                    okText={t("Yes")}
                                    cancelText={t("No")}
                                    onConfirm={async () => {
                                        try {
                                            employeesStore.setLoading(true);
                                            employeesStore.resendInvite(record.email, record.role, record.chiefId || "").then((res: any) => {
                                                message.success(t("InvitationSuccessfullyResend"));
                                            }).catch((err: any) => {
                                                message.error(t("FailedResend"));
                                            }).finally(() => {
                                                employeesStore.setLoading(false);
                                            });
                                        } catch (err) {
                                            employeesStore.setLoading(false);

                                            message.error(t("FailedResend"));
                                        }
                                    }}
                                >
                                    <Button title={t("ResendInvite")} disabled={
                                        !(applicationStore.user.roleId === Roles.Manager
                                            || applicationStore.user.roleId === Roles.Owner)
                                    } type="primary" icon={<MailTwoTone />} >
                                        {t("ResendInvite")}
                                    </Button>
                                </Popconfirm>
                            </Flex>

                        </>
                    );
                }
                return (
                    <>
                        <Flex justify="space-between" gap={10}>
                            <Button type="primary" shape="circle" onClick={() => {
                                navigate(`./${record.id}`)
                            }} icon={<EyeOutlined />} />
                            <Popconfirm

                                title="Delete the user"
                                description="Are you sure to delete this user?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={async () => {
                                    try {
                                        await employeesStore.deleteUser(record.id);
                                        message.success("User deleted successfully");
                                    } catch (error) {
                                        message.error("Failed to delete user");
                                    }
                                }}
                            >
                                <Button disabled={
                                    !(applicationStore.user.roleId === Roles.Manager
                                        || applicationStore.user.roleId === Roles.Owner)
                                } type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Flex>

                    </>
                );


            },
        }
    ];


    const onFinish: FormProps<InviteUser>['onFinish'] = async (user: InviteUser) => {
        console.log('Success:', user);
        message.open({
            type: 'loading',
            content: t("SendingInvitation"),
            key: 'invitation'
        });
        applicationStore.inviteUser(user).then(() => {
            message.destroy("invitation");
            message.success(t("TheInvitationWasSentToMail"));
            form.resetFields();
            hideModal();
            employeesStore.getUsers().then(res => {
                setFilteredUser(employeesStore.users)
            }).catch(console.log)
        }).catch(error => {
            message.destroy("invitation");
            message.error(error.message);
        })
    };

    useEffect(() => {
        employeesStore.getUsers().then(res => {
            setFilteredUser(employeesStore.users)
        })
    }, [])

    useEffect(() => {
        let data = employeesStore.users;
        if (searchText.length > 0) {
            data = data.filter(el =>
                el.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                el.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                el.email?.toLowerCase().includes(searchText.toLowerCase())
            );
        }
        data = data.filter(el => el.isActive !== false);
        data = data.map(el => { return { ...el, key: el.id } });
        setFilteredUser(data);
    }, [employeesStore.users, searchText]);

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`${t("Employers")}`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <Spin spinning={employeesStore.isLoading}>
                    {applicationStore.isFreezeModalVisible ? (
                        <p style={{ padding: '20px', textAlign: 'center' }}>Access to users is limited until the subscription is renewed.</p>
                    ) : (
                        <>
                            <Flex justify="space-between" style={{ marginBottom: 10 }}>
                                <Search style={{ width: 300 }} onChange={(e) => {
                                    setSearchText(e.target.value);
                                }} />
                                <div>
                                    <Flex>
                                        {applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager ?
                                            <Button type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }} onClick={showModal}>
                                                {`${t("Add_user")}`}
                                            </Button> : <></>
                                        }
                                        <Button style={{ marginLeft: "10px" }} onClick={() => {
                                            navigate(`organogram`)
                                        }} type="primary">{t("Organogram")}</Button>
                                    </Flex>
                                </div>
                            </Flex>
                            <Table columns={columns} dataSource={filteredUser.filter(user => user.isActive !== false)} onRow={(record) => ({
                                style: record.isApproveInvite === false ? {
                                    backgroundColor: '#c6c6c6',
                                    cursor: "not-allowed",
                                    pointerEvents: 'stroke'
                                } : {},
                            })} />
                        </>
                    )}
                </Spin>
            </div>
            <Modal title={t("InviteNewUser")} open={isModalOpen} footer={null} onCancel={hideModal}>
                <Form form={form} onFinish={onFinish} initialValues={{ role: "user", "chiefId": applicationStore.user.id, "chief": applicationStore.user.email }} labelCol={{ span: 24 }}>
                    <Form.Item<any> name="chiefId" hidden >
                        <Input />
                    </Form.Item>
                    <Form.Item<InviteUser> label={t("Email")} name="email" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Email" pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                    </Form.Item>
                    <Form.Item<InviteUser> label={t("RoleInCompany")} name="role" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Select
                            options={[
                                { value: 'manager', label: 'Manager (allow add/edit other users)' },
                                { value: 'user', label: 'User (allow add/edit projects)' },
                                { value: 'financier', label: 'Financier (allow salaries)' },
                                { value: 'viewer', label: 'Viewer' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item<any> label={t("ImmediateChief")} name="chief">
                        <AutoComplete
                            style={{ height: 40 }}
                            placeholder="Chief user"
                            onChange={(e, option: any) => {
                                form.setFieldValue("chiefId", option?.id)
                            }}
                            options={employeesStore.users?.map((el, index) => ({
                                value: el.email,
                                id: el.id, label: <Flex gap={5}>
                                    <Image
                                        width={40}
                                        height={40}
                                        preview={false}
                                        style={{ borderRadius: "50%", objectFit: "cover" }}
                                        src={el.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${el.photoId}` :
                                            `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                                        }
                                    />
                                    <span>{el.email}</span>
                                </Flex>
                            }))}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, float: "right" }}>
                            {`${t("Add_user")}`} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <AddJobTitle isModalOpen={isModalJobTitle} hideModal={hideModalJobTitle} userId={selectedUser} />

        </Layout>
    )
})
export default Users;