import { Popover, Input, Button, message, Flex } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useStore } from "../../stores/root-store-context";
import { Roles } from "../../stores/EmployeesStore";
import { useTranslation } from "react-i18next";
import AddTimerBlock from "./AddTimerBlock";

const TimerBlock = () => {
  const [currentTimer, setCurrentTimer] = useState<any>();
  const [timeLog, setTimeLog] = useState<any>({ description: "" });
  const [timeDifference, setTimeDifference] = useState("");
  const { t } = useTranslation();
  const { applicationStore, timeReportStore } = useStore();

  const getTimer = useCallback(async () => {
    timeReportStore.setLoading(true);
    let timer = await timeReportStore.getTimer(applicationStore.user.id);
    timer =
      (applicationStore.user.roleId.toString() === Roles.Financier.toString() || applicationStore.user.roleId.toString() === Roles.Owner.toString()) &&
        timer
        ? timer.log
        : timer;
    setCurrentTimer(timer);
    timeReportStore.setLoading(false);
  }, [applicationStore.user.id, timeReportStore]);

  const stopTimer = async () => {
    await timeReportStore.stopTimer(
      currentTimer.projectId,
      timeLog.description
    );
    setCurrentTimer(undefined);
    getTimer();
    document.dispatchEvent(new CustomEvent("timerStopped"));
    timeReportStore.getAll();
  };

  const deleteTimer = async () => {
    console.log(currentTimer);
    const response = await timeReportStore.deleteTimer(currentTimer.id);
    if (response) {
      message.success("Deleted");
      document.dispatchEvent(new CustomEvent("timerStopped"));
      timeReportStore.getAll();
    } else {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    let animationFrameId: number;

    const updateDifference = () => {
      if (!currentTimer) return;

      const startTime = dayjs.utc(currentTimer.startTime).local();
      const now = dayjs();
      const difference = dayjs.duration(now.diff(startTime));
      const formattedDifference = `${Math.max(0, difference.hours())}ч ${Math.max(0, difference.minutes())}м ${Math.max(0, difference.seconds())}с`;
      setTimeDifference(formattedDifference);

      animationFrameId = requestAnimationFrame(updateDifference);
    };

    if (currentTimer) {
      animationFrameId = requestAnimationFrame(updateDifference);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [currentTimer]);

  useEffect(() => {
    document.addEventListener("timerStarted", getTimer);
    document.addEventListener("timerStopped", getTimer);

    getTimer();
  }, [getTimer]);

  if (!currentTimer) {
    return <AddTimerBlock />
  }

  return (
    <Popover
      trigger="click"
      content={
        <div>
          <Input.TextArea
            style={{ width: "250px", height: "60px" }}
            placeholder={t("Description")}
            value={timeLog.description}
            onChange={(e) =>
              setTimeLog({
                ...timeLog,
                description: e.target.value,
              })
            }
          />
          <Flex style={{ justifyContent: "space-between" }}>
            {/* <DeleteOutlined
              type="danger"
              style={{ marginLeft: "5px", fontSize: "20px", color: 'red' }}
              onClick={deleteTimer}
            /> */}
            <Button danger shape="circle" onClick={deleteTimer} icon={<DeleteOutlined style={{}} />} style={{ marginTop: 5 }} />

            <Button
              type="primary"
              shape="circle"
              style={{ marginTop: 5 }}
              // style={{ marginTop: "5px", borderRadius: "30px" }}
              onClick={stopTimer}
              icon={<SaveOutlined />}
            >
              {/* {t("Log")} */}
            </Button>
          </Flex>
        </div>
      }
    >
      <span
        style={{
          whiteSpace: "nowrap",
          cursor: "pointer",
          background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
          color: "#FFFFFF",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "12px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "20px",
          textShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
        }}
      >
        {timeDifference}
      </span>
    </Popover>
  );
};

export default TimerBlock;
