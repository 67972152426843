import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import ApplicationStore from "./ApplicationStore";
import axios from "axios";
import { InviteUser } from "../pages/Users/UsersList";
import { Employee, HistoryItem, Skill } from "../api/EmployeesApiClient";
import { UploadFile } from "antd";

export enum Roles {
  Owner = 1,
  Manager = 2,
  User = 3,
  Viewer = 7,
  Financier = 8
}

export type JobTitle = {
  id: string,
  title: string,
  description: string
}

class EmployeesStore {
 
  isLoading: boolean = true;

  ApiStore!: ApiStore;
  ApplicationStore!: ApplicationStore;
  user: Employee;
  users!: Employee[];
  roles!: any[];
  jobTitles!: JobTitle[];

  constructor(apiStore: ApiStore, applicationStore: ApplicationStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.ApplicationStore = applicationStore;
    this.user = {} as Employee;
    this.user.userSkills = [];
    this.user.userProjects = [];
    this.users = [];
    this.roles = [];
    this.jobTitles = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  setUser = (user: Employee) => {
    this.user = user;
  };

  checkEmail = async (email: string) => {
    return await this.ApiStore.EmployeesApiClient.EmailCheck(email)
  };

  inviteUser = async (user: InviteUser): Promise<boolean> => {
    return await this.ApiStore.EmployeesApiClient.inviteUser(user.email, user.role, user.chiefId)
  };

  addSkill = (skill: Skill) => {
    // this.user.userSkills.push(skill);
  }

  resendInvite = async (email: string, role: string, chiefId: string): Promise<boolean> => {
    return await this.ApiStore.EmployeesApiClient.inviteUser(email, role, chiefId);
}

  removeSkill = (skillId: string) => {
    // this.user.skills = this.user.skills.filter(skill => skill.skillId !== skillId);
  }

  addJobTitle = async (title: string, description: string) => {
    this.isLoading = true;
    return await this.ApiStore.EmployeesApiClient.addJobTitle(
      title,
      description
    ).then(res => {
      return res;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  getJobTitles = async () => {
    this.isLoading = true;
    const result = await this.ApiStore.EmployeesApiClient.getJobTitles().then(res => {
      this.jobTitles = res;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  getUsers = async () => {
    this.isLoading = true;
    await this.getJobTitles();
    const users = await this.ApiStore.EmployeesApiClient.getUsers().then(result => {
      this.users = result;
      return result;
    }).finally(() => {
      this.isLoading = false;
    });
    return users;
  }

  getUserById = async (id: string): Promise<Employee> => {
    console.log("getUserById");
    this.isLoading = true;
    await this.getJobTitles();
    const user = await this.ApiStore.EmployeesApiClient.getUserById(id).then(result => {
      this.user = {
        ...result,
        jobTitle: this.jobTitles?.find(el => el.id === result.jobTitleId)?.title || ""
      };
      return this.user;
    }).finally(() => {
      this.isLoading = false;
    });
    return user;
  }

  editUser = async (user: Employee | any) => {
    this.isLoading = true;
    console.log(user);
    const result = await this.ApiStore.EmployeesApiClient.editUser(user).finally(() => {
      this.isLoading = false;
    });

    return result;
  }

  uploadPhoto = async (file: UploadFile, userId: string) => {
    this.isLoading = true;
    const photo = await this.ApiStore.EmployeesApiClient.photoUpload(file, userId).finally(() => {
      this.isLoading = false;
    });
    return photo;
  }

  getHistoryByUser = async (id: string): Promise<HistoryItem[]> => {
    this.isLoading = true;
    const user = await this.ApiStore.EmployeesApiClient.getHistoryByUser(id).then(result => {
      return result;
    }).finally(() => {
      this.isLoading = false;
    });
    return user;
  }
  addExperience = async (company: string, position: string, description: string, startDate: any, endDate: any) => {
    await this.ApiStore.EmployeesApiClient.addExperience(company, position, description, startDate, endDate).then(result => {
      return result;
    });
  }
  removeExperience = async (company: string, position: string, description: string, startDate: any, endDate: any) => {
    return await this.ApiStore.EmployeesApiClient.removeExperience(company, position, description, startDate, endDate).then(result => {
      return result;
    });
  }

  deleteUser = async (userId: string): Promise<void> => {
    this.setLoading(true);
    try {
      await this.ApiStore.EmployeesApiClient.deleteUser(userId);
      this.users = this.users.filter(user => user.id !== userId);
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

}
export default EmployeesStore;
