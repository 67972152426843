import { DatePicker, Modal, Switch, Table, Tag, Image, Flex, Button, Popconfirm} from "antd";
import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { TimeReport } from "../../api/TimeReportsApiClient";
import { useStore } from "../../stores/root-store-context";
import { Employee } from "../../api/EmployeesApiClient";
import { Roles } from "../../stores/EmployeesStore";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getUserComponent } from "../../helpers/user";
import './BarChart.css';
import { useTranslation } from "react-i18next";

const TimeReportsByDay = observer(
  ({
    startDate, 
    endDate, 
    showModalEdit, 
    deleteTime, 
    setIsChangeUser, 
    setEditTimeReport
  }: {
    startDate: Dayjs;
    endDate: Dayjs;
    showModalEdit: () => void;
    deleteTime: (id: string) => Promise<void>;
    setIsChangeUser: (status: boolean) => void;
    setEditTimeReport: (report: TimeReport) => void;
  }) => {
    const { projectsStore, employeesStore, timeReportStore, applicationStore } = useStore();
    const isFinancier = applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner;
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState<any>([]);
    const [users, setUsers] = useState<Employee[]>([]);
    const [timeReports, setTimeReports] = useState<TimeReport[]>();
    const [timeReportsWithPayment, setTimeReportsWithPayment] = useState<any[]>();
    const [displayMode, setDisplayMode] = useState<"time" | "payment">("time");
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState<any>(null)
    const [totalHoursPerDay, setTotalHoursPerDay] = useState<any>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [logsForSelectedDay, setLogsForSelectedDay] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<Employee | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const { t } = useTranslation();

    
    const toggleDisplayMode = () => {
      setDisplayMode((prevMode) => (prevMode === "time" ? "payment" : "time"));
    };

    async function fetchTimeReports() {
      const timeReports = await timeReportStore.getAll();
    
      if (timeReports && timeReports.length > 0) {
        runInAction(() => {
          setTimeReports([...timeReports]); 
        });
      } else {
        console.log("fetchTimeReports - отчеты пусты или не найдены.");
      }
    }
   
    const generateColumns = async () => {
      const newColumns: any = [
          {
              title: t("User"),
              dataIndex: "user",
              key: "user",
              fixed: "left",
              render: (user: Employee, row: any) => (
                <div style={{ display: "flex", alignItems: "center", paddingLeft: row.user === t("Total Time") || row.user === t("Total Payment") ? "0px" : "10px" }}> 
                  {row.user !== t("Total Time") && row.user !== t("Total Payment") && (
                    <img
                      src={
                        user.photoId
                          ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${user.photoId}`
                          : "https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn"
                      }
                      alt={user.firstName}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: "15px"
                      }}
                    />
                  )}
                  <div>
                    <div>{user.firstName} {user.lastName}</div>
                    <div style={{ color: "gray" }}>{user.email}</div>
                  </div>
                </div>
              ),
          },     
          {
              title: isFinancier && displayMode === 'payment' ? t("Total Payment") : t("Total Time"),
              dataIndex: "totalTime",
              key: "totalTime",
              fixed: "left",
              width: 100
          },
      ];

      if (isFinancier && displayMode === "time") {
          newColumns.push({
              title: t("Total Payment"),
              dataIndex: "totalPayment",
              key: "totalPayment",
              fixed: "left",
              width: 100,
          });
      }
  
      const groupedColumns: any[] = [];
      let currentDate = startDate.clone();
      let currentMonth = currentDate.format("MMMM");
      let monthColumns: any[] = [];
  
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
          const dayOfWeek = currentDate.day();
          const month = currentDate.format("MMMM");
  
          if (month !== currentMonth) {

              groupedColumns.push({
                  title: (
                      <div style={{ textAlign: 'left', paddingLeft: '10px' }}>
                          {`${currentMonth} ${currentDate.format("YYYY")}`}
                      </div>
                  ),
                  children: monthColumns,
              });
              monthColumns = [];
              currentMonth = month;
          }
  
          monthColumns.push({
              title: (
                  <>
                      <div>{currentDate.format("DD")}</div>
                      <div style={{ fontSize: "12px", color: "#999" }}>{currentDate.format("dd")}</div>
                  </>
              ),
              dataIndex: currentDate.format("YYYY-MM-DD"),
              key: currentDate.format("YYYY-MM-DD"),
              width: 70,
          });
  
          currentDate = currentDate.add(1, 'day');
      }
  
      if (monthColumns.length > 0) {
          groupedColumns.push({
              title: (
                  <div style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      {`${currentMonth} ${currentDate.format("YYYY")}`}
                  </div>
              ),
              children: monthColumns,
          });
      }
  
      setColumns(newColumns.concat(groupedColumns));
  };
  
  

  const showLogsForDay = (user: Employee, date: string) => {
    if (!timeReports || timeReports.length === 0) {
        console.log("Нет данных для генерации.");
        return;
    }

    const formattedSelectedDate = dayjs(date).format("YYYY-MM-DD");

    const logs = timeReports.filter(report => {
        let reportUserId = report.userId;

        const reportDate = isFinancier && (report as any).log?.endTime
            ? dayjs.utc((report as any).log?.endTime).format("YYYY-MM-DD")
            : dayjs.utc(report.endTime).format("YYYY-MM-DD");

        if (isFinancier) {
            reportUserId = (report as any).log.userId; 
        }

        return reportUserId === user.id && reportDate === formattedSelectedDate;
    });

      setSelectedUser(user);
      setSelectedDate(date);
      setLogsForSelectedDay(logs);
      setIsModalVisible(true);
  };



  

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setLogsForSelectedDay([]);
    setSelectedUser(null);
    setSelectedDate(null);
  };
  

    async function fetchUsers() {
      const users = await employeesStore.getUsers();
      runInAction(() => {
        setUsers(users);
      });
    }

    const getCellBackgroundColor = (hoursWorked: number) => {
      if (hoursWorked === 0) return '#fcfcfc';
      if (hoursWorked > 0 && hoursWorked <= 8) return '#c8e6c9';
      if (hoursWorked > 8) return '#ffcccb';
    };
    
    const generateTotalTimeRow = (totalMinutesByDay: { [key: string]: number }, totalPaymentsByDay: { [key: string]: number }) => {
      const totalRow: any = { user: "Total Time" };
      let grandTotalMinutes = 0;
      let grandTotalPayments = 0;
      let currentDate = startDate.clone();
  
      const maxMinutes = Math.max(...Object.values(totalMinutesByDay));
      const maxHours = maxMinutes > 0 ? maxMinutes / 60 : 14;
  
      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
          const dateKey = currentDate.utc().format("YYYY-MM-DD");
          const totalMinutesForDay = totalMinutesByDay[dateKey] || 0;
          const totalPaymentsForDay = totalPaymentsByDay[dateKey] || 0;
          grandTotalMinutes += totalMinutesForDay;
          grandTotalPayments += totalPaymentsForDay;
  
          const hoursWorked = totalMinutesForDay / 60;
          const heightPercentage = maxHours > 0 ? (hoursWorked / maxHours) * 80 : 0;
  
          totalRow[dateKey] = (
              <div className="bar-chart-container">
                  <div className="bar-chart-value">{hoursWorked.toFixed(1)}</div>
                  <div className="bar-chart-bar" style={{ height: `${heightPercentage}px` }} />
              </div>
          );
  
          currentDate = currentDate.add(1, "day");
      }
  
      totalRow.totalTime = (
          <div style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#2e1b9a",
              top: 0,
              padding: "10px",
              background: "#c5c4e9",
              textAlign: "center",
              borderRadius: "8px"
          }}>
              {`${Math.floor(grandTotalMinutes / 60)}:${String(grandTotalMinutes % 60).padStart(2, "0")}`}
          </div>
      );
  
      totalRow.totalPayment = (
          <div style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#388e3c",
              top: 0,
              padding: "10px",
              background: "#a5d6a7",
              textAlign: "center",
              borderRadius: "8px"
          }}>
              {`$${grandTotalPayments.toFixed(2)}`}
          </div>
      );
  
      return totalRow;
  };
  
    
    
    
  const generateData = async () => {
    if (!timeReports || timeReports.length === 0) {
        console.log("Нет данных для генерации.");
        return;
    }

    const timeLogsByUserAndDay: any = {};
    const totalMinutesByDay: { [key: string]: number } = {};
    const paymentLogsByUserAndDay: any = {};
    const totalPaymentsByDay: { [key: string]: number } = {};

    timeReports.forEach((report: any) => {
        const userId = report.userId || report.log?.userId;
        const reportDate = isFinancier ? dayjs.utc(report.log?.endTime).format("YYYY-MM-DD") : dayjs.utc(report.endTime).format("YYYY-MM-DD");

        const totalTime = report.totalTime || report.log?.totalTime;
        const paymentForDay = report.payment || report.log?.payment || 0;

        if (totalTime) {
            const [hours, minutes] = totalTime.split(":").map(Number);
            const totalMinutes = hours * 60 + minutes;

            if (!timeLogsByUserAndDay[userId]) timeLogsByUserAndDay[userId] = {};
            if (!timeLogsByUserAndDay[userId][reportDate]) timeLogsByUserAndDay[userId][reportDate] = 0;
            timeLogsByUserAndDay[userId][reportDate] += totalMinutes;

            if (!totalMinutesByDay[reportDate]) totalMinutesByDay[reportDate] = 0;
            totalMinutesByDay[reportDate] += totalMinutes;
        }

        if (!paymentLogsByUserAndDay[userId]) paymentLogsByUserAndDay[userId] = {};
        if (!paymentLogsByUserAndDay[userId][reportDate]) paymentLogsByUserAndDay[userId][reportDate] = 0;
        paymentLogsByUserAndDay[userId][reportDate] += paymentForDay;

        if (!totalPaymentsByDay[reportDate]) totalPaymentsByDay[reportDate] = 0;
        totalPaymentsByDay[reportDate] += paymentForDay;
    });

    const data = users.map((user) => {
        let currentDate = startDate.clone();
        const row: any = { user: user };
        let totalMinutesForUser = 0;
        let totalPaymentForUser = 0;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
            const dateKey = currentDate.utc().format("YYYY-MM-DD");
            const totalMinutes = timeLogsByUserAndDay[user.id]?.[dateKey] || 0;
            const paymentForDay = paymentLogsByUserAndDay[user.id]?.[dateKey] || 0;
            totalMinutesForUser += totalMinutes;
            totalPaymentForUser += paymentForDay;

            const hoursWorked = totalMinutes / 60;
            const dayOfWeek = currentDate.day();

            if (dayOfWeek === 0 || dayOfWeek === 1) {
              row[dateKey] = totalMinutes === 0
                  ? <div style={{ padding: '8px', borderRadius: '5px', textAlign: 'center', color: '#999' }}></div>
                  : (
                      <div
                          style={{
                              backgroundColor: getCellBackgroundColor(hoursWorked),
                              padding: '8px',
                              borderRadius: '5px',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              color: '#333',
                              cursor: 'pointer'
                          }}
                          onClick={() => showLogsForDay(user, dateKey)}
                      >
                          {`${Math.floor(totalMinutes / 60)}:${String(totalMinutes % 60).padStart(2, '0')}`}
                      </div>
                  );
          } else {
              row[dateKey] = (
                  <div
                      style={{
                          backgroundColor: getCellBackgroundColor(hoursWorked),
                          padding: '8px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#333',
                          cursor: 'pointer'
                      }}
                      onClick={() => showLogsForDay(user, dateKey)}
                  >
                      {totalMinutes === 0
                          ? '0:00'
                          : `${Math.floor(totalMinutes / 60)}:${String(totalMinutes % 60).padStart(2, '0')}`}
                  </div>
              );
          }

            currentDate = currentDate.add(1, "day");
        }

        row.totalTime = `${Math.floor(totalMinutesForUser / 60)}:${String(totalMinutesForUser % 60).padStart(2, '0')}`;
        row.totalPayment = `$${totalPaymentForUser.toFixed(2)}`;
        return row;
    });

    const totalRow = generateTotalTimeRow(totalMinutesByDay, totalPaymentsByDay);
    data.unshift(totalRow);

    setDataSource(data);
};

    
    
  
    const getPaymentCellBackgroundColor = (payment: number) => {
      if (payment === 0) return '#fcfcfc';
      if (payment > 0 && payment <= 10) return '#e0f7df';
      if (payment > 10 && payment <= 50) return '#b2f0af';
      return '#4caf50';
    };

    async function generatePaymentData(paymentReports: any[]) {
      const paymentsByUserAndDay: any = {};
      const totalPaymentsByDay: any = {}; 
      
      paymentReports.forEach((report: any) => {
        const userId = report.log.userId;
        const reportDate = dayjs.utc(report.log.endTime).format("YYYY-MM-DD");
        const payment = report.payment;
    
        if (!paymentsByUserAndDay[userId]) {
          paymentsByUserAndDay[userId] = {};
        }
        if (!paymentsByUserAndDay[userId][reportDate]) {
          paymentsByUserAndDay[userId][reportDate] = 0;
        }
        paymentsByUserAndDay[userId][reportDate] += payment;
    
        if (!totalPaymentsByDay[reportDate]) {
          totalPaymentsByDay[reportDate] = 0;
        }
        totalPaymentsByDay[reportDate] += payment;
      });
    
      const data = users.map((user) => {
        let currentDate = startDate.clone();
        const row: any = { user: user };
        let totalPaymentForUser = 0;
    
        while (
          currentDate.isBefore(endDate) ||
          currentDate.isSame(endDate, "day")
        ) {
          const dateKey = currentDate.utc().format("YYYY-MM-DD");
          const paymentForDay = paymentsByUserAndDay[user.id]?.[dateKey] || 0;
          totalPaymentForUser += paymentForDay;
    
          row[dateKey] = (
            <div
              style={{
                backgroundColor: getPaymentCellBackgroundColor(paymentForDay),
                padding: '8px',
                borderRadius: '5px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              {`$${paymentForDay.toFixed(2)}`} 
            </div>
          );
    
          currentDate = currentDate.add(1, "day");
        }
    
        row.totalTime = `$${totalPaymentForUser.toFixed(2)}`; 
        return row;
      });
    
      const totalRow = generateTotalPaymentRow(totalPaymentsByDay);
  data.unshift(totalRow);

  setDataSource(data);
    };

    const generateTotalPaymentRow = (totalPaymentsByDay: { [key: string]: number }) => {
      const totalRow: any = { user: "Total Payment" };
      let grandTotalPayments = 0;
      let currentDate = startDate.clone();
    
      const maxPayments = Math.max(...Object.values(totalPaymentsByDay));
      const maxAmount = maxPayments > 0 ? maxPayments : 100;
    
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        const dateKey = currentDate.utc().format("YYYY-MM-DD");
        const totalPaymentsForDay = totalPaymentsByDay[dateKey] || 0;
        grandTotalPayments += totalPaymentsForDay;
    
        const heightPercentage = maxAmount > 0 ? (totalPaymentsForDay / maxAmount) * 80 : 0;
    
        totalRow[dateKey] = (
          <div className="bar-chart-container">
            <div className="bar-chart-value" style={{ color: '#2e7d32' }}> 
              ${totalPaymentsForDay.toFixed(2)}
            </div>
            <div
              className="bar-chart-bar"
              style={{
                height: `${heightPercentage}px`,
                backgroundColor: totalPaymentsForDay > 0 ? '#81c784' : '#fcfcfc', 
                borderRadius: '5px',
              }}
            />
          </div>
        );
    
        currentDate = currentDate.add(1, "day");
      }
    
      totalRow.totalTime = (
        <div
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#388e3c",
            padding: "10px",
            background: "#a5d6a7",
            textAlign: "center",
            borderRadius: "8px",
          }}
        >
          ${grandTotalPayments.toFixed(2)}
        </div>
      );
    
      return totalRow;
    };
    
    
        
    async function fetchData() {
      setLoading(true); 
      const users = await employeesStore.getUsers(); 
      setUsers(users); 
    
      await generateColumns();
    
      if (displayMode === "time") {
        await fetchTimeReports();
        if (timeReports && timeReports.length > 0) {
          await generateData(); 
          console.log("displayMode time, info from fetchData");
        } else {
          console.log("There are no time reports to display");
        }
      } 
    
      if (isFinancier && displayMode === "payment") {
        const timeReports = await timeReportStore.getAll();
        
        if (timeReports) {
          console.log("time reports1", timeReports);
          
          try {
            const paymentReports = timeReports;
            setTimeReportsWithPayment(paymentReports);
            await generatePaymentData(paymentReports); 
            console.log("displayMode payment, info from fetchData");
          } catch (error) {
            console.error("Ошибка при установке timeReportsWithPayment:", error);
          }
        } else {
          console.log("There are no paid reports to display");
        }
      }
    
      setLoading(false); 
    }
    
    
    useEffect(() => {

        fetchData();

    }, [startDate, endDate, displayMode]);

    useEffect(() => {
      if (displayMode === "time") {
        if (timeReports && timeReports.length > 0) {
          generateData();
        }
      }
      if (displayMode === "payment") {
        if (timeReportsWithPayment && timeReportsWithPayment.length > 0) {
          generatePaymentData(timeReportsWithPayment);
        }
      }
    }, [timeReports]);
    
    const getProjectTitleById = (projectId: string) => {
      const project = projectsStore.projects.find(p => p.id === projectId);
      return project ? project.title : '';
    };
    

 
    
    return (
      <div style={{ overflowX: "auto", overflowY: "hidden"}}>
        {loading ? (
          <div>{t("Loading data...")}</div>
        ) : (
          <>
            {timeReports && timeReports.length > 0 ? (
              <>
              {isFinancier && (
                  <div style={{ marginTop: "10px", textAlign: "center", padding: "0 20px" }}>
                  <label style={{ marginBottom: "10px", marginRight: "10px" }}>{t("Time")}</label>
                  <Switch onChange={toggleDisplayMode} checked={displayMode === "payment"} style={{ marginRight: "10px" }} /> 
                  <label>{t("Payment")}</label>
                </div>
                )}
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: 'max-content' }} 
                  pagination={false}
                />
              </>
            ) : (
              <div>{t("No data to display")}</div>
            )}
          </>
        )}
       <Modal
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>{getUserComponent(selectedUser || undefined, applicationStore.company?.Tenaut)}</div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '20px' }}>
                <div style={{ marginBottom: '5px' }}>{dayjs(selectedDate).format('DD MMMM YYYY')}</div>
                <div>
                  {t("Total Time")}: {(() => {
                    const totalMinutes = logsForSelectedDay.reduce((total, log) => {
                      const [hours, minutes] = (isFinancier ? log.log.totalTime : log.totalTime).split(':');
                      return total + parseInt(hours) * 60 + parseInt(minutes);
                    }, 0);
                    const hours = Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;
                    return `${hours}:${String(minutes).padStart(2, '0')}`;
                  })()} {t("hours")}
                </div>
              </div>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          <Table
            columns={[
              {
                title: t("Project"),
                dataIndex: 'projectTitle',
                key: 'projectTitle',
                align: 'center',
              },
              {
                title: t("Description"),
                dataIndex: 'description',
                key: 'description',
                align: 'center',
                render: (description) => description || '(no description)' 
              },
              {
                title: t("Time Logged"),
                dataIndex: 'totalTime',
                key: 'totalTime',
                align: 'center',
                render: (time) => {
                  const [hours, minutes] = time.split(':');
                  return `${parseInt(hours)}:${String(minutes).padStart(2, '0')}`;
                }
              },
              // {
              //   title: 'Actions',
              //   key: 'actions',
              //   render: (_: any, record: any) => (
              //     <Flex justify="space-between">
              //       <Button type="text" shape="circle" onClick={() => {
              //         console.log("el el el el", record);
              //         setIsChangeUser(false);
              //         setEditTimeReport(record);
              //         showModalEdit();
              //       }}>
              //         <EditOutlined />
              //       </Button>
              //       <Popconfirm
              //         title="Delete the time-report"
              //         description="Are you sure you want to delete this time report?"
              //         okText="Yes"
              //         cancelText="No"
              //         onConfirm={() => {
              //           deleteTime(isFinancier ? record.log.id : record.id).then(() => {
              //             setLogsForSelectedDay((prevLogs) => prevLogs.filter((log) =>
              //               isFinancier ? log.log.id !== record.log.id : log.id !== record.id
              //             ));
              //             fetchTimeReports();
              //           });
              //         }}
              //       >
              //         <Button danger type="text" shape="circle"><DeleteOutlined /></Button>
              //       </Popconfirm>
              //     </Flex>
              //   )
              // }
            ]}
            dataSource={logsForSelectedDay.map(log => ({
              id: isFinancier ? log.log.id : log.id,
              projectTitle: isFinancier
                ? getProjectTitleById(log.log.projectId)
                : getProjectTitleById(log.projectId),
              description: isFinancier ? log.log.description : log.description,
              totalTime: isFinancier ? log.log.totalTime : log.totalTime,
            }))}
            pagination={false}
        />
        </Modal>
      </div>
    );      
        
  }
);

export default TimeReportsByDay;