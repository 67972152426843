import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from 'antd';
import { RootStoreContext } from './stores/root-store-context';
import RootStore from './stores/root-stores';
import Router from './routes/Router';
import './i18n';
import './App.css';
import 'react-quill/dist/quill.snow.css';
import i18n from './i18n';
import { getAntdLocale } from './helpers/locales';
import { Drawer } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App: React.FC = () => {

  return (
    <ConfigProvider
      locale={getAntdLocale(i18n.language)}
      theme={{
        token: {
          // colorLink: "#5a7890",
          // colorLinkHover: "#5a7890",
          // colorLinkActive: "#5a7890",
        },

        components: {
          Drawer: {
            paddingLG: 4,
          },
          // Menu: {
          //   colorBgContainer: '#FFFFFF',
          //   colorText: "#e6f4ff",
          //   itemSelectedColor: "#5a7890",
          //   fontSize: 14,
          //   itemSelectedBg: "#CBD8E2",
          //   itemColor: "#5a7890",
          //   itemHoverBg: "#CBD8E2",
          //   itemHoverColor: "#5a7890",
          //   colorBgLayout: "#FFFFFF",
          //   borderRadius: 0,
          //   borderRadiusLG: 0,
          //   itemMarginBlock: 0,
          //   itemMarginInline: 0
          // },
          Input: {
            borderRadius: 0,
          },
          // Form: {
          //   size: 13,
          // },
          Button: {
            borderRadius: 0,
            colorPrimary: "#384bff",
            // colorPrimaryHover: "#5a7890",
            // colorPrimaryActive: "#5a7890",
            // colorPrimaryTextActive: "#ffffff",
          },
          // Table: {
          //   cellPaddingBlock: 12,
          //   cellPaddingInline: 8,
          //   cellFontSizeSM: 13,
          //   cellPaddingInlineSM: 4,
          //   headerBg: "rgba(105, 105, 105, 0.30)",
          //   headerSortActiveBg: "rgba(105, 105, 105, 0.40)",
          //   headerSortHoverBg: "rgba(105, 105, 105, 0.45)",
          //   rowHoverBg: "rgb(235, 235, 235)",
          //   borderColor: "rgba(105, 105, 105, 0.2)",
          //   bodySortBg: "rgba(255, 255, 255, 0)",
          //   colorPrimary: "rgb(30, 42, 52)"
          // },
          // Layout: {
          //   siderBg: "#FFFFFF",
          // },
          // Descriptions: {
          //   itemPaddingBottom: 0,
          // }
        },
      }}
    >

      <RootStoreContext.Provider value={new RootStore()}>
      <GoogleOAuthProvider clientId={`522104468705-r41tkugbe1cutsss81l36bk3n9qsropb.apps.googleusercontent.com`}>
        <AntdApp>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AntdApp>
        </GoogleOAuthProvider>,
      </RootStoreContext.Provider>
    </ConfigProvider>
  );
};

export default App;