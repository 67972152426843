import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Image, Spin, Table, TableProps, Tag, Typography, message, DatePicker, App, Checkbox, Switch, Space, Card } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Employee } from "../../api/EmployeesApiClient";
import { NewProject, Project } from "../../stores/ProjectsStore";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from 'dayjs';
import { Roles } from "../../stores/EmployeesStore";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { ProjectTask } from "../../stores/ProjectTasksStore";
const { Text } = Typography;



const TaskPage = observer(() => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const navigate = useNavigate();
    const { id } = useParams();

    const { applicationStore, projectsStore, projectTaskStore } = useStore();

    const [task, setTask] = useState<ProjectTask>();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (id) {


            projectTaskStore.getById(id).then(res => {
                setTask(res);
            }).catch(error => {
                message.error(error.message);
            }).finally()

            // projectTaskStore.getAll().then(res => {
            //     setTasks(res);
            // }).catch(error => {
            //     message.error(error.message);
            // })
        }
    }, [])

    const onFinish: FormProps<NewProject>['onFinish'] = async (project: NewProject) => {
        console.log('Success:', project);
        message.open({
            type: 'loading',
            content: 'Waiting...',
            key: 'loading'
        });

        // projectsStore.createNew(project).then(() => {
        //     message.destroy("project");
        //     message.success("The project was created");
        //     projectsStore.getAll()
        //     form.resetFields();
        //     hideModal()
        // }).catch(error => {
        //     message.destroy("project");
        //     message.error(error.message);
        // })
    };
    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`Task`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <Spin spinning={projectTaskStore.isLoading}>
                    <Card style={{ margin: 20, textAlign: "center" }}>
                        <h4 title="Task name">{task?.title}</h4>
                        <Text type="success">Start Date: {task?.startDate?.format('YYYY-MM-DD')}</Text>
                        <Text type="success">Due Date: {task?.dueDate?.format('YYYY-MM-DD')}</Text>
                        <p>{task?.description}</p>
                    </Card>
                </Spin>
            </div>
            <Modal title="Create Task" open={isModalOpen} footer={null} onCancel={hideModal}>
                <Form form={form} onFinish={onFinish} initialValues={{ startDate: dayjs(), isActive: true }} layout="vertical">
                    <Form.Item<NewProject> label="Task name" name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Task name" />
                    </Form.Item>
                    <Form.Item<NewProject> label="Description" name="description" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                    <Form.Item<NewProject> label="Start Date" name="startDate" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <DatePicker placeholder="Start Date" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, float: "right" }}>
                            Create Project <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )
})
export default TaskPage;