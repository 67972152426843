import { UploadFile, UploadProps } from "antd"
import {
  FilePdfOutlined, FileWordOutlined, PictureOutlined, FileExcelOutlined, FileZipOutlined, FileTextOutlined, FileUnknownOutlined
} from '@ant-design/icons';

const fileTypeIcons: Record<string, JSX.Element> = {
  'jpeg': <PictureOutlined />,
  'png': <PictureOutlined />,
  'pdf': <FilePdfOutlined />,
  'word': <FileWordOutlined />,
  'xmls': <FileExcelOutlined />,
  'zip': <FileZipOutlined />,
  'txt': <FileTextOutlined />,
};

const getFileIcon = (fileType: string | number) => {
  return fileTypeIcons[fileType] || <FileUnknownOutlined />;
};

export const customItemRender: UploadProps['itemRender'] = (originNode, file) => {
  return (
    <div style={{ display: 'inline-block', margin: '10px' }}>
      <div
        style={{
          width: '104px',
          height: '104px',
          border: '1px solid #d9d9d9',
          borderRadius: '2px',
          backgroundColor: '#fafafa',
          display: 'flex',
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          color: '#999',
        }}
      >
        {getFileIcon(file.name.split('.').pop()?.toLowerCase() || '')}
        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: 10, overflow: "hidden", width: "80%" }}>{file.name}</div>
      </div>
    </div>
  );
};

export const handleDownload = (file: UploadFile) => {
  const a = document.createElement('a');
  a.href = file.url || "";
  a.target = "_blank"
  a.download = file.name || 'download';
  document.body.appendChild(a);
  a.click();
  a.remove();
};