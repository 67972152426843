import { Flex } from "antd";
import { useTranslation } from "react-i18next";

const OgranogramBlock = ({ children, ...props }: any) => {
  const { t } = useTranslation();

  const getTranslatedRole = (role: string) => {
    return t(role?.charAt(0).toUpperCase() + role?.slice(1).toLowerCase());
  };

  return (
    <Flex
      style={{
        border: "1px solid #0073e6",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        height: "180px",
        width: "180px",
        padding: "15px",
        margin: "10px auto",
        textAlign: "center",
        fontSize: "14px",
        backgroundColor: "#f8f9fa",
        transition: "transform 0.2s",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        ...props.style,
      }}
      onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
      onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      {...props}
    >
      <div>
        <img
          style={{
            borderRadius: "50%",
            width: "80px",
            height: "80px",
            marginBottom: "10px",
            objectFit: "cover",
          }}
          src={
            children.photoId != null
              ? `http://res.cloudinary.com/dnl3x07wo/image/upload/v1724393335/${children.photoId}`
              : `	https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
          }
        />
      </div>
      <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
        {children.firstName} {children.lastName}
      </div>
      <div style={{ color: "#555" }}>
         {children.role !== undefined ? getTranslatedRole(children.role?.title) : t("NoRole")}
      </div>
    </Flex>
  );
};
export default OgranogramBlock;
