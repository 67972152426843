import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useStore } from "../../stores/root-store-context";
import Layout from "../../components/layout/Layout";
import OgranogramBlock from "../../components/blocks/OrganogramBlock";
import { Flex } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const OrganogramPage = () => {
  const { applicationStore, employeesStore } = useStore();
  const [users, setUsers] = useState<any>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchUsers = async () => {
    const fetchedUsers = await employeesStore.getUsers();
    setUsers(fetchedUsers);
  };
  const handleClick = (user: any) => {
    const tenaut = applicationStore.company.Tenaut;
    navigate("/" + tenaut + "/employers/" + user.id);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const manager = users.find(
    (user: any) => user.id === applicationStore.user.chiefId
  );
  const colleagues = users.filter((user: any) =>
    manager ? user.chiefId === manager.id : user.id === applicationStore.user.id
  );
  const subordinates = users.filter(
    (user: any) => user.chiefId === applicationStore.user.id
  );
  if (!manager) {
    return (
      <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("Employers")}>
        <Flex
          vertical
          style={{ margin: "15px auto", display: "flex", justifyContent: "center" }}
          className="container"
        >
          <Flex>
            <Button style={{ float: "left", borderRadius: 0, margin: "10px" }} onClick={() => {
              navigate(-1)
            }}>{t("Back")}</Button>
          </Flex>
          {colleagues.map((manager: any, index: number) => (
            <Tree
              key={index}
              label={
                <OgranogramBlock onClick={() => handleClick(manager)}>
                  {manager}
                </OgranogramBlock>
              }
            >
              {subordinates.map((employee: any, empIndex: number) => (
                <TreeNode
                  key={empIndex}
                  label={
                    <OgranogramBlock onClick={() => handleClick(employee)}>
                      {employee}
                    </OgranogramBlock>
                  }
                />
              ))}
            </Tree>
          ))}
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout headerStyle={1} footerStyle={2} breadcrumbTitle="Employees">
      <Flex
        style={{ margin: "15px", display: "flex", justifyContent: "center", overflow: "auto" }}
      >
        <Tree
          label={
            <OgranogramBlock onClick={() => handleClick(manager)}>
              {manager}
            </OgranogramBlock>
          }
        >
          {colleagues.map((user: any, index: number) => (
            <TreeNode
              key={index}
              label={
                <OgranogramBlock onClick={() => handleClick(manager)}>
                  {user}
                </OgranogramBlock>
              }
            >
              {user.id === applicationStore.user.id &&
                subordinates.map((employee: any, empIndex: number) => (
                  <TreeNode
                    key={empIndex}
                    label={
                      <OgranogramBlock onClick={() => handleClick(employee)}>
                        {employee}
                      </OgranogramBlock>
                    }
                  />
                ))}
            </TreeNode>
          ))}
        </Tree>
      </Flex>
    </Layout>
  );
};
export default OrganogramPage;
