import { Badge, Button, Calendar, DatePicker, Dropdown, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Table, Tooltip, message } from "antd";
import Layout from "../../components/layout/Layout";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import utc from 'dayjs/plugin/utc';
import { Option } from "antd/es/mentions";
import { DeleteOutlined } from '@ant-design/icons';
import AddEvent from "./AddEvent";
import { getUserComponent } from "../../helpers/user";
import dayjs, { Dayjs } from 'dayjs';
import { CalendarEvent } from "../../api/EventsApiClient";

dayjs.extend(utc);

const EventPage = observer(({events}:{events:any[]}) => {
  const { employeesStore, applicationStore, eventStore, taskStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    eventStore.getAll()
  }, []);

  const deleteEvent = async (id: any) => {
    message.open({
      type: 'loading',
      content: 'Deleting...',
      key: 'deleting'
    });
    await eventStore.deleteById(id).then(() => {
      message.destroy('deleting');
      message.success("Success");
      taskStore.fetchTasksByUserId(applicationStore.user.id);
      applicationStore.initUserTasks();
    }).catch(() => {
      message.error("Error");

    }).finally(() => {
      eventStore.getAll().catch(console.log);
    });
  }

  const columns = [
    {
      title: t("user.User"),
      dataIndex: 'userId',
      onFilter: (value: any, record: any) => record.userEmail.includes(value),
      sorter: (a: any, b: any) => TextSort(a.userEmail, b.userEmail),
      render: (userId: string) => <div>{getUserComponent(employeesStore.users.find(user=>user.id===userId), applicationStore.company?.Tenaut)}</div>,
      key: 'userEmail',
    },
    {
      title: t("EventType"),
      dataIndex: 'type',
      onFilter: (value: any, record: any) => record.type.includes(value),
      render: (type: any) => t(`${type}`),
      key: 'type',
    },
    {
      title: t("StartDate"),
      dataIndex: 'startDate',
      render: (date: any) => <div>{date?.format('YYYY-MM-DD')}</div>,
      sorter: (a: any, b: any) => DateSort(a.startDate?.format('YYYY-MM-DD'), b.startDate?.format('YYYY-MM-DD')),
      key: 'startDate',
    },
    {
      title: t("DueDate"),
      dataIndex: 'endDate',
      render: (date: any) => <div>{date?.format('YYYY-MM-DD')}</div>,
      sorter: (a: any, b: any) => DateSort(a.startDate?.format('YYYY-MM-DD'), b.startDate?.format('YYYY-MM-DD')),
      key: 'endDate',
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        return (
          <>
            <Flex justify="space-between">
              <Popconfirm
                title="Delete the event"
                description="Are you sure to delete this event?"
                okText="Yes"
                cancelText="No"
                onConfirm={(e) => {
                  deleteEvent(record.id)
                }}
              >
                <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Flex>
          </>
        );
      },
    }
  ];
  return (
    <Spin spinning={eventStore.isLoading} style={{ minHeight: 600 }}>
      <div className="container" style={{ margin: "20px auto" }}>
        <Flex justify="end">
          <Button onClick={showModal} type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }}>
            {`${t("Add")}`}
          </Button>
        </Flex>
      </div>
      <div className="container">
        <Table
          rowHoverable
          dataSource={events}
          columns={columns}
          pagination={false}
        />
      </div>
      <AddEvent isModalOpen={isModalOpen} hideModal={hideModal} />
    </Spin>
  )
})
export default EventPage;