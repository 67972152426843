import {
  AutoComplete,
  Button,
  Flex,
  Form,
  FormProps,
  message,
  Modal,
  Select,
  TimePicker,
  Image,
  Input,
  DatePicker,
} from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import dayjs, { Dayjs } from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { TimeReport } from "../../api/TimeReportsApiClient";

export const EditTimeReport = observer(
  (props: {
    isModalOpen: boolean;
    hideModal: () => void;
    id: string;
    time: string;
    endTime: Dayjs;
    description: string;
  }) => {
    const { isModalOpen, hideModal, id, time, endTime, description } = props;
    const { applicationStore, timeReportStore, employeesStore, projectsStore } =
      useStore();

    const [form] = Form.useForm();
    const { t } = useTranslation();

    const onFinish: FormProps<any>["onFinish"] = async (timeReport: any) => {
      const id = form.getFieldValue("id");
      const description = form.getFieldValue("description");
      const timeStr = dayjs(form.getFieldValue("time")).format("HH:mm:ss");
      const dateStr = dayjs(form.getFieldValue("endTime")).format("YYYY-MM-DD");
      timeReportStore
        .edit(id, timeStr, dateStr, description)
        .then(() => {
          timeReportStore.getMyTime(applicationStore.user.id);
        })
        .finally(() => {
          timeReportStore.getAll();
          form.resetFields();
          hideModal();
        });
    };

    useEffect(() => {
      form.setFieldValue("id", id);
      form.setFieldValue("time", dayjs(time, "HH:mm"));
      form.setFieldValue("endTime", endTime);
      form.setFieldValue("description", description);
    }, [id, time, endTime, description, isModalOpen]);

    return (
      <Modal
        title={t("LogTime")}
        open={isModalOpen}
        footer={null}
        onCancel={hideModal}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          labelAlign="left"
        >
          <Form.Item<any> name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item<any>
            label={t("Time")}
            name="time"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <TimePicker
              defaultValue={dayjs("00:00", "HH:mm")}
              format={"HH:mm"}
              onCalendarChange={(date: any) => {
                form.setFieldValue("time", date);
              }}
            />
          </Form.Item>
          <Form.Item<any>
            label={t("Date")}
            name="endTime"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <DatePicker
            showNow={false}
              defaultValue={dayjs()}
              onCalendarChange={(date: any) => {
                form.setFieldValue("date", date);
              }}
            />
          </Form.Item>
          <Form.Item<any>
            label={t("Description")}
            name="description"
            style={{ width: "100%" }}
            rules={[{ required: false }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              style={{ height: 65, float: "right" }}
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
