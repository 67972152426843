import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "./base/SkTechApiClient";

export type Task = {
  id: number;
  title: string;
  translationKey?: string;
  translationParams?: Record<string, any>;
  startDate: string;
  endDate: string;
  status: 'Active' | 'Done' | 'Overdue' | 'Approved' | 'Rejected';
  linkTask: string;
  userId: string;
};

export default class TasksApiClient extends SkTechApiClient {
  
  public getTasksByUserId = async (userId: string): Promise<Task[]> => {
    return await this.Get(`api/UserTask/User/${userId}`)
      .then((response: AxiosResponse<Task[]>) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch((error: AxiosError) => {
        console.error("Failed to fetch tasks", error);
        return [];
      });
  }

  public addTask = async (task: Omit<Task, 'id'>): Promise<boolean> => {
    return await this.Post(`/api/UserTask/AddTask`, task)
      .then((response: AxiosResponse) => {
        return response.status === 201;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to add task", error);
        return false;
      });
  }

  public markTaskAsCompleted = async (taskId: number): Promise<boolean> => {
    return await this.Patch(`/api/UserTask/MarkAsCompleted/${taskId}`, {})
      .then((response: AxiosResponse) => {
        return response.status === 204;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to mark task as completed", error);
        return false;
      });
  }

  public approveTask = async (taskId: number): Promise<boolean> => {
    return await this.Patch(`/api/UserTask/${taskId}/approve`, {})
      .then((response: AxiosResponse) => {
        return response.status === 204;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to approve task", error);
        return false;
      });
  }

  public rejectTask = async (taskId: number): Promise<boolean> => {
    return await this.Patch(`/api/UserTask/${taskId}/reject`, {})
      .then((response: AxiosResponse) => {
        return response.status === 204;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to reject task", error);
        return false;
      });
  }
}
