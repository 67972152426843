import React, { useEffect } from "react";
import { Typography, Divider, Flex, Button } from "antd";
import { EditOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface InfoBlockProps {
  title: string;
  children: React.ReactNode;
  onEdit: () => void;

}

const InfoBlock: React.FC<InfoBlockProps> = ({ title, children, onEdit }) => {
  useEffect(() => {
    console.log(children);
  }, []);
  // Проверяем, пустой ли массив или содержит только пустые строки
  const childrenArray = React.Children.toArray(children);

  // Проверяем, пустой ли массив или содержит ли он элементы с пустыми children
  if (
    childrenArray.length === 0 ||
    childrenArray.every(
      (child) =>
        (typeof child === "string" && child.trim() === "") ||
        (React.isValidElement(child) &&
          typeof child.props.children === "string" &&
          child.props.children.trim() === "")
    )
  ) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "16px",
        borderRadius: "8px",
        marginBottom: "16px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        // maxWidth: "600px",
        width: "100%",
      }}
    >
      <Flex justify="space-between">
        <Title level={4} style={{ marginBottom: "8px", color: "#2a2a2a" }}>
          {title}
        </Title>
        <Button onClick={onEdit} shape="circle" type="text"><i className="fa fa-pencil" style={{ fontSize: 20, color: "gray" }} aria-hidden="true"></i></Button>
      </Flex>

      <div>{children}</div>
      {/* <Divider style={{ margin: "12px 0" }} /> */}
    </div>
  );
};

export default InfoBlock;
