import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from "dayjs";

export type Salary = {
  id: string;
  salaryPerHour: number;
  startDate: Dayjs;
  endDate: Dayjs;
  userId: string;
};

export default class SalaryApiClient extends SkTechApiClient {
  public getAll = async () => {
    return await this.Get(`/api/Salary`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };
  public getCurrentSalaries = async () => {
    return await this.Get(`/api/Salary/AllCurrentSalaries`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };
  public salariesByPeriod = async (startDate: any, endDate: any) => {
    return await this.Get(
      `/api/Salary/GetByPeriod?startDate=${startDate}&endDate=${endDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };
  public addSalary = async (value: any) => {
    return await this.Post(`/api/Salary`, value)
      .then((response) => {
        console.log("response");
        console.log(response);
        return response;
      })
      .catch(function (error) {
        return error.response.data;
      });
  };
  public deleteSalary = async (salaryId: any) => {
    return await this.Delete(`/api/Salary?id=${salaryId}`)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public updateSalary = async (salaryId: any, value: any) => {
    return await this.Put(`/api/Salary?id=${salaryId}`, value)
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        return response;
      })
      .catch(function (error) {
        return error.response.data;
      });
  };
  public getSalaryForUserAtDate = async (userId: string, date: string) => {
    return await this.Get(`/api/Salary/GetSalaryForUserAtDate?userId=${userId}&date=${date}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch(function (error) {
        console.log(error);
        return null;
      });
  };
}
