import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Select, Image, Spin, Table, TableProps, Tag, Typography, message, DatePicker, App, Checkbox, Switch } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Employee } from "../../api/EmployeesApiClient";
import { NewProject, Project } from "../../stores/ProjectsStore";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from 'dayjs';
import { Roles } from "../../stores/EmployeesStore";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { SkillLevelNameColor } from "../../helpers/skills";
import { useTranslation } from "react-i18next";

const Search = Input.Search;

const ProjectsList = observer(() => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { applicationStore, projectsStore } = useStore();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filteredProject, setFilteredProject] = useState<Project[]>([]);
    const [expandedSkills, setExpandedSkills] = useState<{ [key: string]: boolean }>({});

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const update = () => {
        projectsStore.getAll().then(res => {
            setFilteredProject(projectsStore.projects)
        })
    }
    const columns: TableProps<Project>['columns'] = [
        {
            title: `${t("Title")}`,
            dataIndex: 'title',
            key: 'title',
            render: (text) => <div>{text}</div>,
            sorter: (a: Project, b: Project) => TextSort(a.title, b.title)

        },
        // {
        //     title: 'Description',
        //     dataIndex: 'description',
        //     key: 'description',
        //     render: (text) => <div>{text}</div>,
        //     sorter: (a: Project, b: Project) => TextSort(a.description, b.description)

        // },
        {
            title: `${t("StartDate")}`,
            dataIndex: 'startDate',
            key: 'startDate',
            render: (date) => <div>{date.format('YYYY-MM-DD')}</div>,
            sorter: (a: Project, b: Project) => DateSort(a.startDate?.format('YYYY-MM-DD'), b.startDate?.format('YYYY-MM-DD'))

        },
        {
            title: `${t("ResponsibleManager")}`,
            dataIndex: 'projectResponsible',
            key: 'projectResponsible',
            render: (user) => <div>{user?.email}</div>,
            sorter: (a: Project, b: Project) => TextSort(a.projectResponsible?.email, b.projectResponsible?.email)
        },
        {
            title: `${t("Skills")}`,
            dataIndex: 'projectSkills',
            key: 'projectSkills',
            render: (skills, project) => <Flex wrap style={{ maxWidth: 200 }} gap={10}>
                {(expandedSkills[project.id] ? skills : skills.slice(0, 5)).map((el: any) => (
                    <Tag color="blue" key={el.skill.title}>{el.skill.title}</Tag>
                ))}
                {skills?.length > 5 && (
                    expandedSkills[project.id] ? (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: false
                        }))} style={{ cursor: 'pointer' }} color="blue">Show less</Tag>
                    ) : (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: true
                        }))} style={{ cursor: 'pointer' }} color="blue">+{skills.length - 5} more</Tag>
                    )
                )}
            </Flex>,
            filters: applicationStore.skills?.map(skill => { return { text: skill.title, value: skill.skillId } }),
            onFilter: (value, record) => record.projectSkills.some(el => el.skillId === value),
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: Project) => {
                return (
                    <>
                        <Flex justify="space-between">
                            <Button type="primary" shape="circle" onClick={() => {
                                navigate(`./${record.id}`)
                            }} icon={<EyeOutlined />} />
                            <Popconfirm

                                title="Delete the project"
                                description="Are you sure to delete this project?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    projectsStore.deleteById(record?.id).then(() => {
                                        message.success("The project was deleted!");
                                    }).catch(error => {
                                        message.error(error.message);
                                    }).finally(update)
                                }}
                            >
                                <Button
                                    disabled={!(applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager)}
                                    type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Flex>
                    </>
                );
            },
        }
    ];
    const onFinish: FormProps<NewProject>['onFinish'] = async (project: NewProject) => {
        console.log('Success:', project);
        message.open({
            type: 'loading',
            content: 'Creating project...',
            key: 'project'
        });

        projectsStore.createNew(project).then(() => {
            message.destroy("project");
            message.success("The project was created");
            projectsStore.getAll().then(() => {
                setFilteredProject(projectsStore.projects);
            });
            form.resetFields();
            hideModal()
        }).catch(error => {
            message.destroy("project");
            message.error(error.message);
        })
    };

    const getFilteredProjects = () => {
        let data = projectsStore.projects;
        if (searchText.length > 0) {
            data = data.filter(el => el.title?.toLowerCase().includes(searchText.toLowerCase()))
        }
        data = data.map(el => { return { ...el, key: el.id } });
        return data;
    }

    useEffect(() => {
        update();
    }, [])

    useEffect(() => {
        setFilteredProject(getFilteredProjects());
    }, [searchText])

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`${t("Projects")}`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <Spin spinning={projectsStore.isLoading}>
                    {applicationStore.isFreezeModalVisible ? (
                        <p style={{ padding: '20px', textAlign: 'center' }}>
                            Access to projects is limited until the subscription is renewed.
                        </p>
                    ) : (
                        <>
                            <Flex justify="space-between">
                                <Search style={{ width: 300 }} onChange={(e) => {
                                    setSearchText(e.target.value);
                                }} />
                                <Button disabled={!(applicationStore.user.roleId === Roles.Manager || applicationStore.user.roleId === Roles.Owner)} type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }} onClick={showModal}>
                                    {`${t("Add")}`}
                                </Button>
                            </Flex>
                            <Table columns={columns} dataSource={filteredProject} rowHoverable
                                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '50', '100'] }} />
                        </>
                    )}
                </Spin>
            </div>
            <Modal title={`${t("Create")} ${t("Project")}`} open={isModalOpen} footer={null} onCancel={hideModal}>
                <Form form={form} onFinish={onFinish} initialValues={{ startDate: dayjs(), isActive: true, isPublish: true }} layout="vertical">
                    <Form.Item<Project> label={`${t("Title")}`} name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder={`${t("Title")}`} />
                    </Form.Item>
                    <Form.Item<Project> label={`${t("Description")}`} name="description" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <TextArea rows={4} placeholder={`${t("Description")}`} />
                    </Form.Item>
                    <Form.Item<Project> label={`${t("StartDate")}`} name="startDate" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <DatePicker placeholder={`${t("StartDate")}`} />
                    </Form.Item>
                    <Form.Item<Project> label={`${t("IsActive")} ${t("Project")}`} name="isActive" style={{ width: "100%" }} >
                        <Switch />
                    </Form.Item>
                    <Form.Item<Project> label={`${t("IsPublish")} ${t("Project")}`} name="isPublish" style={{ width: "100%" }}  >
                        <Switch />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, float: "right" }}>
                            {`${t("Create")}`} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    )
})
export default ProjectsList;