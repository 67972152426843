import { Button, Flex, Form, message, Modal, Select, Input, DatePicker, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Option } from "antd/es/mentions";

export const AddEducation = observer((props: { isModalOpen: boolean, hideModal: () => void, userId: string | undefined, fetchEducation: () => void }) => {
    const { isModalOpen, hideModal, userId, fetchEducation } = props;
    const { educationStore } = useStore();
    const [messageApi] = message.useMessage();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const onFinish = async (data: any) => {
        console.log(data);
        console.log(form.getFieldsValue());
        if (props.userId) {
            educationStore.addEducation(props.userId).then(res => {
                message.success("Success");
                form.resetFields();
                hideModal();
                educationStore.resetEducation();
            }).catch(console.log)
            fetchEducation();
        }

    };

    useEffect(() => {
        educationStore.getCountries();
        console.log(props);

    }, [])

    return (
        <Spin spinning={educationStore.isLoading}>
            <Modal
                title="New Education"
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    hideModal();
                    form.resetFields();
                    educationStore.resetEducation();
                }}
            >
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Flex vertical={false} justify="space-between">
                        <Form.Item<any>
                            label={`${t("Country")}`}
                            name="country"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                showSearch
                                onChange={(value: string, option: any) => {
                                    educationStore.setCountry({ code: option.key, name: option.value });
                                }}
                                value={educationStore.education.country}
                                placeholder="Select a country"
                                options={educationStore.countries?.map((country) => (
                                    { key: country.code, value: country.name, label: country.name }
                                ))}
                            />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("City")}`}
                            name="educationCity"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                disabled={educationStore.education.country === ""}
                                value={educationStore.education.city}
                                showSearch
                                onChange={(value: string) => {
                                    educationStore.setCity({ name: value });
                                }}
                                placeholder="Select a city"
                                options={educationStore.cities?.map((city) => (
                                    { key: city.name, value: city.name, label: city.name }
                                ))}
                            />
                        </Form.Item>
                    </Flex>
                    <Form.Item<any>
                        label={`${t("University")}`}
                        name="title"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            disabled={educationStore.education.city === ""}
                            value={educationStore.education.university}
                            showSearch
                            mode="tags"
                            onChange={(value: string | string[]) => {
                                const universityValue = Array.isArray(value) ? value.join(", ") : value;
                                educationStore.setUniversity(universityValue);
                            }}
                            placeholder="Select a university"
                            options={educationStore.universities?.map((university) => (
                                { key: university.name, value: university.name, label: university.name }
                            ))}
                        />
                    </Form.Item>
                    <Form.Item<any>
                        label={`${t("Profession")}`}
                        name="rate"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder={`${t("Profession")}`}
                            onChange={(e) =>
                                educationStore.setProfession(e.target.value)
                            }
                            value={educationStore.education.profession}
                        />
                    </Form.Item>
                    <Form.Item<any>
                        label={`${t("Degree")}`}
                        name="degree"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder={t("Degree")}
                            value={educationStore.education.degree}
                            onChange={(value: string) => {
                                educationStore.setDegree(value);
                            }}
                            mode="tags"
                            options={[
                                { value: 'Bachelor', label: t("Bachelor") },
                                { value: 'Master', label: t("Master") },
                                { value: 'PhD', label: t("PhD") },
                            ]}
                        />
                    </Form.Item>
                    <Flex vertical={false} justify="space-between">
                        <Form.Item<any>
                            label={`${t("StartDate")}`}
                            name="startDate"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                picker="year"
                                value={educationStore.education.startDate}
                                onChange={(e) => {
                                    educationStore.setStartYear(e)
                                }}
                                placeholder="Start Year"
                            />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("DueDate")}`}
                            name="dueDate"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                picker="year"
                                value={educationStore.education.endDate}
                                onChange={(e) => educationStore.setEndYear(e)}
                                placeholder="Last Year"
                            />
                        </Form.Item>
                    </Flex>
                    <Form.Item>
                        <Button
                            type="primary"
                            className="theme-btn"
                            htmlType="submit"
                            style={{ height: 65, float: "right" }}
                        >
                            {t("Save")} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    )
})