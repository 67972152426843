import { Flex, Table, Image, TableProps, Button, Popconfirm } from "antd"
import { observer } from "mobx-react-lite"
import { TextSort } from "../../helpers/SorterHelper";
import { DownOutlined, UpOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStore } from "../../stores/root-store-context";
import { Link } from "react-router-dom";
import { TimeReport } from "../../api/TimeReportsApiClient";
import { useState } from "react";
import { getUserComponent } from "../../helpers/user";
import { sumAllTime } from "../../helpers/timeReports";
import { Roles } from "../../stores/EmployeesStore";
import { useTranslation } from "react-i18next";

export const ProjectsTime = observer(({ setLogUserId, setLogProjectId, showModalAdd, showModalEdit, deleteTime, setIsChangeUser, setEditTimeReport, timeReports }: {
    setLogUserId: (id: string) => void,
    setLogProjectId: (id: string) => void,
    showModalAdd: () => void,
    showModalEdit: () => void,
    deleteTime: (id: string) => Promise<void>,
    setIsChangeUser: (status: boolean) => void,
    setEditTimeReport: (report: TimeReport) => void,
    timeReports: any[],
}) => {
    const { applicationStore, timeReportStore, employeesStore, projectsStore } = useStore();
    const { t } = useTranslation();
    const [expandedRowKey, setExpandedRowKey] = useState<string[]>([]);

    const onExpandHandle = (expand: boolean, record: any) => {
        if (expand) {
            setExpandedRowKey(
                [
                    ...expandedRowKey,
                    record.key
                ]
            );
        } else {
            setExpandedRowKey(
                expandedRowKey.filter(a => a !== record.key)
            );
        }
    };
    const columnsProject: TableProps<any>['columns'] = [
        {
            title: t("Project"),
            dataIndex: 'title',
            key: 'title',
            render: (text, record) =>
                <Link to={`/${applicationStore.company.Tenaut}/projects/${record.project?.id}`}>{record.project?.title || ""}</Link>,
            sorter: (a: any, b: any) => TextSort(a.project.title, b.project.title)


        },
        Table.EXPAND_COLUMN,
        {
            title: t("Time"),
            dataIndex: 'time',
            key: 'time',
            render: (text, record) => {
                if (record.reports && record.reports.length > 0) {
                    return sumAllTime(record.reports);
                } else {
                    return '0.00';
                }
            },
            sorter: (a: any, b: any) => TextSort(sumAllTime(a.reports), sumAllTime(b.reports))
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: any) => {
                return (
                    <>
                        <Flex justify="space-between">
                        <Button
                        disabled={!(
                            applicationStore.user.roleId === Roles.Owner || 
                            applicationStore.user.roleId === Roles.Manager || 
                            applicationStore.user.roleId === Roles.Financier ||
                            record.developers?.some((el:any)=>el.id===applicationStore.user.id)
                        )}
                        type="primary"
                        shape="circle"
                        onClick={() => {
                            setIsChangeUser(
                                applicationStore.user.roleId === Roles.Owner || 
                                applicationStore.user.roleId === Roles.Manager || 
                                applicationStore.user.roleId === Roles.Financier ||
                                applicationStore.user.id === record.user?.id
                            );
                            setLogProjectId(record.project.id);
                            setLogUserId(applicationStore.user.id);
                            showModalAdd();
                        }}
                        icon={<PlusOutlined />}
                    />

                        </Flex>
                    </>
                );
            },
        }
    ];

    return (
        <Table columns={columnsProject} dataSource={timeReports} rowHoverable
            expandable={{
                expandedRowKeys: expandedRowKey,
                onExpand: onExpandHandle,
                expandIcon: ({ expanded, onExpand, record }) => {
                    if (expanded) {
                        return <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                            <UpOutlined />
                        </Button>

                    } else {
                        return <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                            <DownOutlined />
                        </Button>
                    }
                },
                expandRowByClick: true,
                expandedRowRender: (record, index) => {
                    return <ul>
                    {
                        ((applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner)
                            ? timeReportStore.filterByProjectIdFinancier(record.project.id).map((el: any) => el.log)
                            : timeReportStore.filterByProjectId(record.project.id)
                        ).map((el: TimeReport) => {
                            return (
                                <li key={el.id}>
                                    <Flex justify="space-between">
                                        <div style={{ flex: "1 1 200px" }}>{getUserComponent(employeesStore.users.find(user => user.id === el.userId), applicationStore.company.Tenaut, 40)}</div>
                                        <div style={{ flex: "1 1 200px" }}>{el.description}</div>
                                        <div style={{ flex: "1 1" }}>{el.totalTime.slice(0, 5)}
                                            <Button type="text" shape="circle" onClick={() => {
                                                setIsChangeUser(false);
                                                setEditTimeReport(el);
                                                showModalEdit();
                                            }}>
                                                <EditOutlined />
                                            </Button>
                                        </div>
                                        <div style={{ flex: "1 1 50px" }}>{el.endTime.format("DD MMMM")}</div>
                                        <Popconfirm
                                            title={t("Delete the time-report")}
                                            description={t("Are you sure to delete this time-report?")}
                                            okText={t("Yes")}
                                            cancelText={t("No")}
                                            onConfirm={() => {
                                                deleteTime(el.id).then(() => {
                                                    timeReportStore.getMyTime(applicationStore.user.id);
                                                });
                                            }}
                                        >
                                            <Button danger type="text" shape="circle">
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                                    </Flex>
                                </li>
                            );
                        })
                    }
                </ul>
                }
            }}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '50', '100'] }} />
    )
})