import { Modal, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/root-store-context';
import { useEffect } from 'react';
import { Roles } from '../../stores/EmployeesStore';
import { useNavigate } from 'react-router-dom';

const FreezeWarningModal = observer(() => {
  const { applicationStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (applicationStore.isAuthenticated && applicationStore.company?.IsFrozen && applicationStore.user?.roleId !== Roles.Owner) {
      applicationStore.setFreezeModalVisible(true);
    } else {
      applicationStore.setFreezeModalVisible(false);
    }
  }, [applicationStore.isAuthenticated, applicationStore.company?.IsFrozen, applicationStore.user?.roleId]);
  

  const handleLogout = () => {
    applicationStore.logout();
    applicationStore.setFreezeModalVisible(false); 
    navigate('/login');
  };
  

  const handleGoToPayment = () => {
    navigate(`/${applicationStore.company.Tenaut}/payment-and-tariffs`);
    applicationStore.setFreezeModalVisible(false);
  };

  return (
    <Modal
      open={applicationStore.isFreezeModalVisible}
      footer={null}
      closable={false}
      centered
    >
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h3 style={{ marginBottom: '20px' }}>Company subscription has expired</h3>
        <p style={{ marginBottom: '20px' }}>
          Access to functionality is limited. Contact the company owner to unblock.
        </p>
        {applicationStore.user?.roleId === Roles.Owner ? (
          <Button onClick={handleGoToPayment} type="primary" style={{ marginRight: '10px' }}>
           Proceed to payment
          </Button>
        ) : (
          <Button onClick={handleLogout} type="primary" style={{ marginRight: '10px' }}>
          Logout
          </Button>
        )}
      </div>
    </Modal>
  );
});

export default FreezeWarningModal;
