import { DatePicker, Switch, Table, Tag, Modal } from "antd";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { Project } from "../../stores/ProjectsStore";
import { Roles } from "../../stores/EmployeesStore";
import './BarChart.css';
import { getUserComponent } from "../../helpers/user";
import { Employee } from "../../api/EmployeesApiClient";
import { useTranslation } from "react-i18next";

interface TimeReportsByDayProjectsProps {
  startDate: Dayjs;
  endDate: Dayjs;
  setStartDate: (date: Dayjs) => void;
  setEndDate: (date: Dayjs) => void;
}

const TimeReportsByDayProjects = observer(
  ({
    startDate,
    endDate
  }: TimeReportsByDayProjectsProps) => {
    const { projectsStore, timeReportStore, applicationStore, employeesStore } = useStore();
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState<any>([]);
    const [displayMode, setDisplayMode] = useState<"time" | "payment">("time");
    const [loading, setLoading] = useState(true);
    const [totalHoursPerDay, setTotalHoursPerDay] = useState<any>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [logsForSelectedDay, setLogsForSelectedDay] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [timeReports, setTimeReports] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const { t } = useTranslation();
    



    const toggleDisplayMode = () => {
      setDisplayMode((prevMode) => (prevMode === "time" ? "payment" : "time"));
    }; 

    const isFinancier = applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner;

    async function fetchProjects() {
      const projects = await projectsStore.getAll();
      return projects || [];
    }
    
    async function fetchReports() {
      if (isFinancier) {
        return await timeReportStore.getForFinancier();
      } else {
        return await timeReportStore.getAll();
      }
    }

    const showLogsForProjectDay = async (
      project: Project,
      date: string,
      timeReports: any[],
      users: Employee[]
  ) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      const isFinancier = applicationStore.user.roleId === Roles.Financier;
  
      const logs = timeReports.filter((report: any) => {
          const reportDate = isFinancier 
              ? (report.log ? dayjs.utc(report.log.endTime).format("YYYY-MM-DD") : null)
              : dayjs.utc(report.endTime).format("YYYY-MM-DD");
  
          const projectId = isFinancier ? report.log?.projectId : report.projectId;
  
          return projectId === project.id && reportDate === formattedDate;
      });
  
      const formattedLogs = logs.map((report: any) => {
          const userId = isFinancier ? report.log?.userId : report.userId;
          const user = users.find((u: Employee) => u.id === userId);
  
          return {
              user: user ? getUserComponent(user, applicationStore.company?.Tenaut) : "Unknown User",
              description: isFinancier ? report.log?.description : report.description || "(no description)",
              totalTime: isFinancier ? report.log?.totalTime : report.totalTime,
          };
      });
  
      console.log("Filtered Logs:", formattedLogs);
  
      setSelectedProject(project);
      setSelectedDate(date);
      setLogsForSelectedDay(formattedLogs);
      setIsModalVisible(true);
  };
  
  
  

    const generateColumns = (projects: Project[]) => {
      const newColumns: any = [
        {
          title: t("Project"),
          dataIndex: "project",
          key: "project",
          fixed: "left",
          render: (project: Project) => (
            <div>
             {project?.id ? <a href={`/projects/${project.id}`}>{project.title}</a> : ''}

            </div>
          ),
        },
        {
          title: displayMode === "payment" ?  t("Total Payment") : t("Total Time"),
          dataIndex: "totalTime",
          key: "totalTime",
          fixed: "left",
          width: 100,
        },
      ];

      if (isFinancier && displayMode === "time") {
          newColumns.push({
              title: t("Total Payment"),
              dataIndex: "totalPayment",
              key: "totalPayment",
              fixed: "left",
              width: 100,
          });
      }

    
      const groupedColumns: any[] = [];
      let currentDate = startDate.clone();
      let currentMonth = currentDate.format("MMMM");
      let monthColumns: any[] = [];

      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
        const month = currentDate.format("MMMM");
        const dayOfWeek = currentDate.format('dd');
        const dateKey = currentDate.format("YYYY-MM-DD");


        if (month !== currentMonth) {
          groupedColumns.push({
            title: (
              <div style={{ textAlign: 'left', paddingLeft: '10px' }}>
                {`${currentMonth} ${currentDate.format("YYYY")}`}
              </div>
            ),
            children: monthColumns,
          });
          monthColumns = [];
          currentMonth = month;
        }

        monthColumns.push({
          title: (
             <>
                <div>{currentDate.format("DD")}</div>
                <div style={{ fontSize: "12px", color: "#999" }}>{dayOfWeek}</div>
             </>
          ),
          dataIndex: dateKey,
          key: dateKey,
          width: 70
       });

        currentDate = currentDate.add(1, 'day');
      }

      if (monthColumns.length > 0) {
        groupedColumns.push({
          title: (
            <div style={{ textAlign: 'left', paddingLeft: '10px' }}>
              {`${currentMonth} ${currentDate.format("YYYY")}`}
            </div>
          ),
          children: monthColumns,
        });
      }

      setColumns(newColumns.concat(groupedColumns));
    };

    const getUserNameById = (userId: string) => {
        const user = employeesStore.users.find((user) => user.id === userId);
        return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
    };

    const getCellBackgroundColor = (hoursWorked: number) => {
      if (hoursWorked === 0) return '#fcfcfc';
      if (hoursWorked > 0 && hoursWorked <= 8) return '#c8e6c9';
      if (hoursWorked > 8) return '#ffcccb';
    };

    const getPaymentCellBackgroundColor = (payment: number) => {
      if (payment === 0) return '#fcfcfc';
      if (payment > 0 && payment <= 10) return '#e0f5e0';
      if (payment > 10 && payment <= 50) return '#b2e3b2';
      return '#4caf50';
    };
    
    const generatePaymentData = (projects: Project[], paymentReports: any[], isFinancier: boolean) => {
      if (!projects.length || !paymentReports.length) return;
    
      const paymentLogsByDay: any = {};
      const totalPaymentsByDay: { [key: string]: number } = {};
    
      paymentReports.forEach((report: any) => {
        const projectId = isFinancier ? report.log.projectId : report.projectId;
        const reportDate = isFinancier
          ? dayjs.utc(report.log.endTime).format("YYYY-MM-DD")
          : dayjs.utc(report.endTime).format("YYYY-MM-DD");
        const payment = isFinancier ? report.payment : report.payment;
    
        if (payment) {
          if (!paymentLogsByDay[projectId]) {
            paymentLogsByDay[projectId] = {};
          }
          if (!paymentLogsByDay[projectId][reportDate]) {
            paymentLogsByDay[projectId][reportDate] = 0;
          }
          paymentLogsByDay[projectId][reportDate] += payment;
    
          if (!totalPaymentsByDay[reportDate]) {
            totalPaymentsByDay[reportDate] = 0;
          }
          totalPaymentsByDay[reportDate] += payment;
        }
      });
    
      const data = projects.map((project) => {
        let currentDate = startDate.clone();
        const row: any = { project };
        let totalPaymentsForProject = 0;
    
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
          const dateKey = currentDate.format("YYYY-MM-DD");
          const paymentForDay = paymentLogsByDay[project.id]?.[dateKey] || 0;
          totalPaymentsForProject += paymentForDay;
    
          row[dateKey] = (
            <div
              style={{
                backgroundColor: getPaymentCellBackgroundColor(paymentForDay),
                padding: '8px',
                borderRadius: '5px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              {`$${paymentForDay.toFixed(2)}`}
            </div>
          );
    
          currentDate = currentDate.add(1, 'day');
        }
    
        row.totalTime = `$${totalPaymentsForProject.toFixed(2)}`;
        return row;
      });
    
      const totalRow = generateTotalPaymentRow(totalPaymentsByDay);
      data.unshift(totalRow);
    
      setDataSource(data);
    };
    
    const generateTotalPaymentRow = (totalPaymentsByDay: { [key: string]: number }) => {
      const totalRow: any = { user: "Total Payment" };
      let grandTotalPayments = 0;
      let currentDate = startDate.clone();
    
      const maxPayments = Math.max(...Object.values(totalPaymentsByDay));
      const maxAmount = maxPayments > 0 ? maxPayments : 100;
    
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        const dateKey = currentDate.utc().format("YYYY-MM-DD");
        const totalPaymentsForDay = totalPaymentsByDay[dateKey] || 0;
        grandTotalPayments += totalPaymentsForDay;
    
        const heightPercentage = maxAmount > 0 ? (totalPaymentsForDay / maxAmount) * 80 : 0;
    
        totalRow[dateKey] = (
          <div className="bar-chart-container">
            <div className="bar-chart-value" style={{ color: '#2e7d32' }}>
              ${totalPaymentsForDay.toFixed(2)}
            </div>
            <div
              className="bar-chart-bar"
              style={{
                height: `${heightPercentage}px`,
                backgroundColor: '#81c784',
                borderRadius: '5px',
              }}
            />
          </div>
        );
    
        currentDate = currentDate.add(1, "day");
      }
    
      totalRow.totalTime = (
        <div
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#388e3c",
            top: 0,
            padding: "10px",
            background: "#a5d6a7",
            textAlign: "center",
            borderRadius: "8px",
          }}
        >
          ${grandTotalPayments.toFixed(2)}
        </div>
      );
    
      return totalRow;
    };
    

    const generateData = (projects: Project[], timeReports: any[], users: Employee[], isFinancier: boolean) => {
      if (!projects.length || !timeReports.length) return;

      console.log("Generating data for projects:", projects);
      console.log("timeReports from generateData :", timeReports); 
    
      const projectLogsByDay: any = {};
      const totalMinutesByDay: { [key: string]: number } = {};
      const totalPaymentsByDay: { [key: string]: number } = {};

      timeReports.forEach((report: any) => {
        const projectId = isFinancier ? report.log.projectId : report.projectId;
    
        const reportDate = isFinancier
          ? dayjs.utc(report.log.endTime).format("YYYY-MM-DD")
          : dayjs.utc(report.endTime).format("YYYY-MM-DD");
        const totalTime = isFinancier ? report.log?.totalTime : report.totalTime;
        const paymentForDay = isFinancier ? report.payment : report.payment || 0;

        console.log("Processing report:", {
          projectId,
          reportDate,
          totalTime,
          paymentForDay,
       });

        if (totalTime) {
          const [hours, minutes] = totalTime.split(":").map(Number);
          const totalMinutes = hours * 60 + minutes;

          if (!projectLogsByDay[projectId]) projectLogsByDay[projectId] = {};
          if (!projectLogsByDay[projectId][reportDate]) projectLogsByDay[projectId][reportDate] = 0;
          projectLogsByDay[projectId][reportDate] += totalMinutes;

          if (!totalMinutesByDay[reportDate]) totalMinutesByDay[reportDate] = 0;
          totalMinutesByDay[reportDate] += totalMinutes;
        }

        if (!totalPaymentsByDay[reportDate]) totalPaymentsByDay[reportDate] = 0;
        totalPaymentsByDay[reportDate] += paymentForDay;
      });

      const data = projects.map((project) => {
        let currentDate = startDate.clone();
        const row: any = { project };
        let totalMinutesForProject = 0;
        let totalPaymentForProject = 0;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
          const dateKey = currentDate.format("YYYY-MM-DD");
          const totalMinutes = projectLogsByDay[project.id]?.[dateKey] || 0;
          const paymentForDay = totalPaymentsByDay[dateKey] || 0;
          totalMinutesForProject += totalMinutes;
          totalPaymentForProject += paymentForDay;

          const hoursWorked = totalMinutes / 60;
          const dayOfWeek = currentDate.day();

          if (dayOfWeek === 0 || dayOfWeek === 6) {
            row[dateKey] = totalMinutes === 0
              ? <div style={{ padding: '8px', borderRadius: '5px', textAlign: 'center', color: '#999' }}></div>
              : (
                <div
                  style={{
                    backgroundColor: getCellBackgroundColor(hoursWorked),
                    padding: '8px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333',
                    cursor: displayMode === 'time' ? 'pointer' : 'default',
                  }}
                  onClick={displayMode === 'time' ? () => showLogsForProjectDay(project, dateKey, timeReports, users) : undefined}
                >
                  {`${Math.floor(totalMinutes / 60)}:${String(totalMinutes % 60).padStart(2, '0')}`}
                </div>
              );
          } else {
            row[dateKey] = (
              <div
                style={{
                  backgroundColor: getCellBackgroundColor(hoursWorked),
                  padding: '8px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#333',
                  cursor: displayMode === 'time' ? 'pointer' : 'default',
                }}
                onClick={displayMode === 'time' ? () => showLogsForProjectDay(project, dateKey, timeReports, users) : undefined}
              >
                {totalMinutes === 0 ? '0:00' : `${Math.floor(totalMinutes / 60)}:${String(totalMinutes % 60).padStart(2, '0')}`}
              </div>
            );
          }
    

          currentDate = currentDate.add(1, 'day');
        }

        row.totalTime = `${Math.floor(totalMinutesForProject / 60)}:${String(totalMinutesForProject % 60).padStart(2, '0')}`;
        row.totalPayment = `$${totalPaymentForProject.toFixed(2)}`;
        return row;
      });

      const totalRow = generateTotalTimeRow(totalMinutesByDay, totalPaymentsByDay);
      data.unshift(totalRow);

      setDataSource(data);
    };

    const generateTotalTimeRow = (totalMinutesByDay: { [key: string]: number }, totalPaymentsByDay: { [key: string]: number }) => {
      const totalRow: any = { project: "Total Time" };
      let currentDate = startDate.clone();
      let grandTotalMinutes = 0;
      let grandTotalPayments = 0;

      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
        const dateKey = currentDate.utc().format("YYYY-MM-DD");
        const totalMinutesForDay = totalMinutesByDay[dateKey] || 0;
        const totalPaymentsForDay = totalPaymentsByDay[dateKey] || 0;

        grandTotalMinutes += totalMinutesForDay;
        grandTotalPayments += totalPaymentsForDay;

        const hoursWorked = totalMinutesForDay / 60;
        totalRow[dateKey] = (
          <div className="bar-chart-container">
            <div className="bar-chart-value">{hoursWorked.toFixed(1)}</div>
            <div className="bar-chart-bar" style={{ height: `${(hoursWorked / 14) * 100}%` }} />
          </div>
        );

        currentDate = currentDate.add(1, "day");
      }

      totalRow.totalTime = (
        <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#2e1b9a",
            top: 0,
            padding: "10px",
            background: "#c5c4e9",
            textAlign: "center",
            borderRadius: "8px"
        }}>
            {`${Math.floor(grandTotalMinutes / 60)}:${String(grandTotalMinutes % 60).padStart(2, "0")}`}
        </div>
    );

    totalRow.totalPayment = (
        <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#388e3c",
            top: 0,
            padding: "10px",
            background: "#a5d6a7",
            textAlign: "center",
            borderRadius: "8px"
        }}>
            {`$${grandTotalPayments.toFixed(2)}`}
        </div>
    );

      return totalRow;
    };
    

    async function fetchTimeReportsForFinancier() {
      return await timeReportStore.getForFinancier();
    }
    
    async function fetchTimeReports() {
      return await timeReportStore.getAll();
    }
    

    useEffect(() => {
      setLoading(true);
      
      const fetchData = async () => {
        setLoading(true);
    
        let fetchedProjects = await fetchProjects();
        const users = await employeesStore.getUsers();
        setUsers(users);
        let fetchedReports;
    
        if (isFinancier) {
            if (displayMode === 'payment') {
                fetchedReports = await fetchTimeReportsForFinancier();
            } else {
                fetchedReports = await fetchTimeReports();
            }
        } else {
            fetchedReports = await fetchTimeReports();
        }
    
        setTimeReports(fetchedReports);
        console.log("timeReports:", fetchedReports);
    
        generateColumns(fetchedProjects);
        if (displayMode === 'payment') {
            generatePaymentData(fetchedProjects, fetchedReports, isFinancier);
        } else {
            generateData(fetchedProjects, fetchedReports, users, isFinancier);
        }
    
        setLoading(false);
    };
    
      fetchData();
    }, [startDate, endDate, displayMode, isFinancier]);
    

    return (
      <div style={{ overflowX: "auto", overflowY: "hidden"}}>
        {loading ? (
          <div> {t("Loading...")}</div>
        ) : (
          <>
          {isFinancier && (
                <div style={{ marginTop: "10px", textAlign: "center", padding: "0 20px" }}>
                <label style={{ marginBottom: "10px", marginRight: "10px" }}>{t("Time")}</label>
                <Switch onChange={toggleDisplayMode} checked={displayMode === "payment"} style={{ marginRight: "10px" }} /> 
                <label>{t("Payment")}</label>
              </div>
            )}
            <Table 
              columns={columns} 
              dataSource={dataSource} 
              scroll={{ x: 'max-content' }} 
              pagination={false} 
            />
          </>
        )}
        <Modal
          title={`Logs for ${selectedProject?.title} on ${dayjs(selectedDate).format("DD MMMM YYYY")}`}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={800}
        >
          <Table
            columns={[
                {
                    title: t("User"),
                    dataIndex: 'user',
                    key: 'user',
                    align: 'center',
                },
                {
                    title: t("Description"),
                    dataIndex: 'description',
                    key: 'description',
                    align: 'center',
                    render: (description) => description || t("(no description)"),
                },
                {
                    title: t("Time Logged"),
                    dataIndex: 'totalTime',
                    key: 'totalTime',
                    align: 'center',
                    render: (time) => {
                        if (time) {
                            const [hours, minutes] = time.split(':');
                            return `${parseInt(hours)}:${String(minutes).padStart(2, '0')}`;
                        }
                        return '0:00';
                    }
                },
            ]}
            dataSource={logsForSelectedDay}
            pagination={false}
        />
        </Modal>
      </div>
    );
    
    
  }
);

export default TimeReportsByDayProjects;
