import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";

class TariffPlansStore {
  tariffPlans: any[] = [];
  isLoading = true;

  ApiStore!: ApiStore;

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
  }

  getTariffPlans = async () => {
    this.isLoading = true;
    const tariffPlans = await this.ApiStore.TariffPlansApiClient.getTariffPlans();
    this.tariffPlans = tariffPlans;
    this.isLoading = false;
  };
}

export default TariffPlansStore;
