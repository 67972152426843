import React from 'react';
import { Employee } from '../../api/EmployeesApiClient';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Flex,
    Image,
    Tag,
} from "antd";
import { useStore } from '../../stores/root-store-context';
import { Roles } from '../../stores/EmployeesStore';
import { Link, useNavigate } from 'react-router-dom';
import { SkypeOutlined, MobileOutlined, MailOutlined, GiftOutlined } from "@ant-design/icons";
import InfoBlock from '../../components/blocks/InfoBlock';
import { useTranslation } from 'react-i18next';
import { SkillLevelName, SkillLevelNameColor } from '../../helpers/skills';
import EducationBlock from '../../components/blocks/EducationBlock';
import ExperienceBlock from '../../components/blocks/ExperienceBlock';
import dayjs from "dayjs";

const UserComponent = observer(({ user }: { user: Employee }) => {
    const { applicationStore, employeesStore, projectsStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    function ParseToJson(str: string): any[] {
        try {
            return JSON.parse(str);
        } catch (e) {
            return [];
        }
    }
    return (
        <Flex>
            {user && (
                <>
                    <Flex
                        vertical
                        align="center"
                        style={{
                            flex: "1 1 300px",
                            border: "1px solid #f5f4f4",
                            padding: 10,
                        }}
                        gap={10}
                    >
                        <Image
                            width={160}
                            height={160}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                            src={
                                user?.photoId
                                    ? `https://res.cloudinary.com/dnl3x07wo/image/upload/ar_1:1,c_fill,w_160,h_160/${user?.photoId}`
                                    : "https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/ar_1:1,c_fill/z03w7hlsy8hcoic9dlcn"
                            }
                        />


                        <Tag
                            color="blue"
                            style={{
                                backgroundColor: "lightgray",
                                textAlign: "center",
                            }}
                        >
                            <div style={{ fontWeight: "bold" }}>
                                {applicationStore.jobTitles.find(el => el.id === user?.jobTitleId)?.title}
                            </div>
                        </Tag>
                        <span style={{ fontSize: 24 }}>
                            {`${user?.firstName} ${user?.lastName}`}
                            {(applicationStore.user.id === user?.id ||
                                applicationStore.user.roleId === Roles.Owner ||
                                applicationStore.user.roleId === Roles.Manager) && (
                                    <Button
                                        title={`${t("user.UpdateProfile")}`}
                                        onClick={() => navigate("./edit")}
                                        shape="circle"
                                        type="text"
                                    >
                                        <i
                                            className="fa fa-pencil"
                                            style={{ fontSize: 20, color: "gray" }}
                                            aria-hidden="true"
                                        ></i>
                                    </Button>
                                )}
                        </span>
                        {/* <Tag color="gray">{Roles[user.roleId]}</Tag> */}
                        <div style={{ textAlign: 'left' }}>
                            <a href={`mailto:${user?.email}`} style={{display: 'block', marginBottom: '8px'}}>
                                <MailOutlined />
                                {` ${user?.email}`}
                            </a>

                            {user?.phoneNumber && (
                                <>
                                    {(applicationStore.user.id === user?.id || !user?.isPhoneNumberPrivate) && (
                                        <a href={`tel://${user?.phoneNumber}`} style={{display: 'block', marginBottom: '8px'}}>
                                            <MobileOutlined /> {user?.phoneNumber}
                                        </a>
                                    )}
                                </>
                            )}

                            {user?.skype && (
                                <a href={`skype:${user?.skype}?call`} style={{display: 'block', marginBottom: '8px'}}>
                                    <SkypeOutlined /> {user?.skype}
                                </a>
                            )}

                            <div style={{display: 'block', marginBottom: '8px'}}>
                                <GiftOutlined /> {`${dayjs(user?.birthDay)?.format("DD MMMM")} `}
                            </div>
                        </div>

                        <h6>{`${t("user.MySkills")}`}</h6>
                        <Flex style={{ maxWidth: 300 }} wrap gap={10} justify="center">
                            {user?.userSkills?.map((el, index) => {
                                return (
                                    <Tag
                                        key={index}
                                        color="blue"
                                        style={{
                                            backgroundColor:
                                                SkillLevelNameColor[parseInt(el.skillLevel)],
                                            textAlign: "center",
                                        }}
                                    >
                                        <div style={{ fontWeight: "bold" }}>
                                            {el.skill.title}
                                        </div>
                                        <div>{SkillLevelName[parseInt(el.skillLevel)]}</div>
                                        <div style={{ fontWeight: "bold" }}>
                                            {el.experienceYears} {t("years")}
                                        </div>
                                    </Tag>
                                );
                            })}
                        </Flex>
                        <h6>{`${t("user.MyProjects")}`}</h6>
                        <Flex style={{ maxWidth: 200 }} gap={10} vertical>
                            {user?.userProjects?.map((el) => {
                                return (
                                    <div>
                                        <Link
                                            to={`/${applicationStore.company.Tenaut}/projects/${el.project.id}`}
                                            style={{ color: "blue", textDecoration: "underline" }}
                                        >
                                            {el.project.title}
                                        </Link>
                                    </div>
                                );
                            })}
                        </Flex>
                    </Flex>
                    <Flex
                        style={{
                            flex: "1 1 600px",
                            // border: "1px solid #f5f4f4",
                            // padding: 20,
                        }}
                        vertical
                    >
                        <InfoBlock
                            title={`${t("user.AboutMe")}`}
                            onEdit={() => {
                                navigate("./edit");
                            }}
                        >
                            <p style={{ marginBottom: 20 }}>{user?.aboutMe}</p>
                        </InfoBlock>
                        <InfoBlock
                            title={`${t("user.MyHobby")}`}
                            onEdit={() => {
                                navigate("./edit");
                            }}
                        >
                            <p style={{ marginBottom: 20 }}>{user?.hobby}</p>
                        </InfoBlock>
                        <EducationBlock
                            educationList={ParseToJson(user.education)}
                            removeEducation={async () => { }}
                            canRemove={false}
                        ></EducationBlock>
                        <ExperienceBlock experienceList={ParseToJson(user.experience)}
                            removeExperience={async () => { }}
                            canRemove={false}
                        ></ExperienceBlock>
                    </Flex>
                </>
            )}
        </Flex>
    );
});

export default UserComponent;