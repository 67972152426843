import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import ukUA from 'antd/lib/locale/uk_UA';

export const getAntdLocale = (i18nLanguage: string) => {
    switch (i18nLanguage) {
      case 'ru':
        return ruRU;
      case 'uk':
        return ukUA;
      case 'eng':
      case 'en':
        return enUS;
      default:
        return enUS;
    }
  };