
import { Link } from "react-router-dom";
import VideoPopup from '../elements/VideoPopup'
import { useTranslation } from "react-i18next";

export default function Hero1() {
    const { t } = useTranslation();
    return (
        <>
            <section className="hero-section fix hero-1 bg-cover" style={{ backgroundImage: 'url("assets/img/hero/hero-bg.jpg")' }}>
                <div className="text-transparent">
                 <h2>{t('Technology')}</h2>
                </div>
                <div className="line-shape">
                    <img src="/assets/img/hero/line-shape.png" alt="shape-img" />
                </div>
                <div className="dot-shape">
                    <img src="/assets/img/hero/dot-shape.png" alt="shape-img" />
                </div>
                <div className="frame-shape">
                    <img src="/assets/img/hero/frame.png" alt="shape-img" />
                </div>
                <div className="mask-shape wow fadeInRight" data-wow-delay=".7s">
                    <img src="/assets/img/hero/mask-shape.png" alt="shape-img" />
                </div>
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-lg-8">
                            <div className="hero-content">
                                {/* <h6 className="wow fadeInUp" data-wow-delay=".2s">Best it SOULTION Provider</h6> */}
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                                    {t('HeroTitle1')}
                                    <br />
                                    {t('HeroTitle2')}
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">
                                {t('HeroDescription')}
                                </p>
                                <div className="hero-button">
                                    <Link to="/register-company" className="theme-btn wow fadeInUp" data-wow-delay=".8s">
                                    {t('GetStarted')}
                                        <i className="fa-solid fa-arrow-right-long" />
                                    </Link>
                                    <VideoPopup style={3} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="hero-image wow fadeInUp" data-wow-delay=".4s">
                                <img src="/assets/img/hero/hero.png" alt="hero-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
