export enum SkillLevelName {
    Elementary = 1,
    Intermediate = 2,
    Advanced = 3,
    Professional = 4,
    Expert = 5
}

export enum SkillLevelNameColor {
    lightgray = 1,
    lemonchiffon = 2,
    azure = 3,
    lightskyblue = 4,
    aquamarine = 5
}