import axios from "axios";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import { Button, Flex, Form, FormProps, Input, Upload, UploadFile, message } from "antd";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import { useStore } from "../../stores/root-store-context";
import { UploadChangeParam } from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import ReCAPTCHA from 'react-google-recaptcha';
import ApiStore from "../../stores/ApiStore";

const SupportPage = observer(() => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const uploadRef = useRef<HTMLInputElement>(null);
    const [captchaValidated, setCaptchaValidated] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const { applicationStore, employeesStore, educationStore } = useStore();
    const [messageApi] = message.useMessage();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const fileChange = (info: UploadChangeParam) => {
        let fileList = [...info.fileList];

        setFileList(fileList);

        if (info.file.status === "done") {
            message.success(`${info.file.name} ${t("UploadSuccess")}`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} ${t("UploadFailed")}`);
        }
    };

    const onFinish: FormProps<any>["onFinish"] = async (formData: any) => {
        message.open({
            type: "loading",
            content: t("SendingRequest"),
            key: "sending",
        });

        try {
            const data = new FormData();
            data.append("product", "SKTECH");
            data.append("name", formData.name);
            data.append("email", formData.email);
            data.append("problem", formData.problem);
            data.append("g_recaptcha_response", recaptchaRef.current?.getValue() || "");
            data.append("form-type", "support");
            data.append("counter", "0");

            if (fileList.length == 0) {
                data.append("files", "");
            }
            fileList.forEach((file) => {
                if (file.originFileObj) {
                    data.append("files", file.originFileObj);
                }
            });

            applicationStore.ApiStore.CommonApiClient.SupportRequest(data).then((response) => {
                console.log("Response:", response);
                form.resetFields();
                setFileList([]);

            }).finally(() => {
                message.destroy("sending");
                message.success(t("RequestSucessfulSendWaitResponsEmail"));
            });
        } catch (error: any) {
            message.destroy("sending");
            message.error(t("Unexpected error!") + error.message);
            console.error("Unexpected error!:", error);
        }
    };

    const handleCaptchaChange = (value: any) => {
        setCaptchaValidated(!!value);
    };

    const handleFormChange = async () => {
        const isValid = await form.validateFields().then(
            () => true,
            () => false
        );
        console.log(isValid);

        setFormValid(isValid);
    };

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("Support")}>
            <div className="container" style={{ maxWidth: 800, marginTop: 20 }}>
                <h2 className="center" style={{ textTransform: "none" }}>{t("DescribeProblem")}</h2>
                <Form form={form} onFinish={onFinish} layout="vertical" onValuesChange={handleFormChange}>
                    <Form.Item<any> name="product" hidden>
                        <Input value={"SKTECH"} />
                    </Form.Item>
                    <Form.Item<any>
                        label={`${t("user.Name")}`}
                        name="name"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={`${t("user.Name")}`} />
                    </Form.Item>
                    <Form.Item<any>
                        name="email"
                        label={`${t("emailLabel")}`}
                        style={{ width: "100%" }}
                        rules={[{
                            required: true,
                            message: t('email is required!'),
                        }]} >
                        <Input placeholder={t('emailLabel')} pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                    </Form.Item>
                    <Form.Item<any>
                        label={`${t("Problem")}`}
                        name="problem"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <TextArea placeholder={`${t("DescribeProblem")}`} rows={5} />
                    </Form.Item>
                    <Form.Item<any>
                        name="files"
                        style={{ width: "100%" }}
                    >
                        <Upload
                            ref={uploadRef}
                            style={{ alignSelf: "center" }}
                            maxCount={3}
                            multiple
                            listType="text"
                            fileList={fileList}
                            onChange={fileChange}
                            beforeUpload={() => false}
                        >
                            <Button icon={<UploadOutlined />}>{t('AddFiles')}</Button>

                        </Upload>

                    </Form.Item>
                    <Form.Item<any>
                        name="recaptcha"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lc6nZkqAAAAAOtTKcPhSoVwSod5rkHwc27x7YOK"
                            onChange={handleCaptchaChange}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            className="theme-btn"
                            htmlType="submit"
                            style={{ height: 60, display: "flex", float: "left" }}
                        // disabled={!true || !formValid}
                        >
                            {`${t("SendRequest")}`}{" "}
                            <SaveOutlined style={{ fontSize: 20, margin: 5 }} />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
});

export default SupportPage;
