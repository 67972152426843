import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import dayjs from "dayjs";

class PaymentStore {
  balance: number = 0;
  paymentDate: dayjs.Dayjs = dayjs();
  trialDate: dayjs.Dayjs = dayjs();
  transactionHistory: any[] = [];
  isLoading: boolean = true;

  ApiStore!: ApiStore;

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
  }

  getBalance = async (companyId: string) => {
    this.isLoading = true;
    try {
      const balanceData = await this.ApiStore.PaymentApiClient.getBalance(companyId);
      this.balance = balanceData.currentBalance;
    } catch (error) {
      console.error("Error fetching balance and payment date:", error);
    } finally {
      this.isLoading = false;
    }
  };

  getPaymentDate = async (companyId: string) => {
    this.isLoading = true;
    try {
      const paymentDateData = await this.ApiStore.PaymentApiClient.getPaymentDate(companyId);
      this.paymentDate = dayjs(paymentDateData.paymentDueDate);
      this.trialDate = dayjs(paymentDateData.trialEndDate);
    } catch (error) {
      console.error("Error fetching payment date:", error);
    } finally {
      this.isLoading = false;
    }
};

  getTransactionHistory = async (companyId: string) => {
    this.isLoading = true;
    try {
      const transactions = await this.ApiStore.PaymentApiClient.getTransactionHistory(companyId);
      this.transactionHistory = transactions;
    } catch (error) {
      console.error("Error fetching transaction history:", error);
    } finally {
      this.isLoading = false;
    }
  };

  createLiqPayRequest = async (companyId: string, formData?: any) => {
    this.isLoading = true;
    try {
      const result = await this.ApiStore.PaymentApiClient.createLiqPayRequest(companyId, formData);
        return result;
    } catch (error) {
      console.error("Error creating LiqPay request:", error);
      return { isSuccess: false, message: "Failed to create LiqPay request" };
    } finally {
      this.isLoading = false;
    }
  };  

  changeTariffPlan = async (companyId: string, newTariffPlanId: number) => {
    this.isLoading = true;
    try {
      const result = await this.ApiStore.PaymentApiClient.changeTariffPlan(companyId, newTariffPlanId);
      if (result.isSuccess) {
        await this.getBalance(companyId);
        await this.getPaymentDate(companyId);
        await this.getTransactionHistory(companyId);
      }
      return result;
    } catch (error) {
      console.error("Error changing tariff plan:", error);
      return { isSuccess: false, message: "Failed to change tariff plan" };
    } finally {
      this.isLoading = false;
    }
  };

}

export default PaymentStore;
