'use client'
import { Link } from "react-router-dom"
import { useState } from 'react'
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import { Roles } from "../../stores/EmployeesStore";

export default function MobileMenu() {
    const {t} = useTranslation();
    const {applicationStore, employeesStore} = useStore();

    return (
        <>
            <div className="mobile-menu fix mb-3 mean-container">
                <div className="mean-bar">
                    <Link to="/#nav" className="meanmenu-reveal" style={{ right: 0, left: 'auto', display: 'inline' }}>
                        <span>
                            <span><span />
                            </span>
                        </span>
                    </Link>
                    <nav className="mean-nav">
                        {!applicationStore.isAuthenticated ?
                        <ul>
                        <li>
                        <Link to={"/login"}>Log in</Link>
                        </li>
                        <li>
                        <Link to={"/about"}>About us</Link>
                        </li>
                        </ul>
                        
                        :
                        <ul>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut}>{t('Menu.MyCompany')}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut + "/employers"}>{t('Menu.Employers')}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut + "/projects"}>{t('Menu.Projects')}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut + "/tests"}>{t('Menu.Tests')}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut + "/time-reports"}>{t('Menu.TimeEvents')}</Link>
                        </li>
                        {applicationStore.user.roleId.toString()===Roles.Financier.toString()&&(
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenaut + "/employers/salaries"}>Salaries</Link>
                        </li>
                        )}
                        </ul>
                    }
                        
                    </nav>
                    </div></div>

        </>
    )
}
