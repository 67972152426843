import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet";

const AboutRU = observer(() => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 20, marginBottom: 30 }}>
                <h2>О сервисе SkTech компании ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС"</h2>
                <p >
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> — это продукт компании ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС", специализирующаяся на предоставлении решений для эффективного управления проектами,
                    рабочего времени сотрудников, документацией и другими аспектами бизнес-процессов. Мы стремимся предоставить нашим клиентам инструменты,
                    которые помогают оптимизировать рабочие процессы, повысить продуктивность и снизить затраты.
                </p>

                <h3>Наши услуги:</h3>
                <ul>
                    <li>
                        <strong>Управление проектами:</strong> Мы помогаем структурировать все этапы проекта — от планирования до реализации.
                        Предоставляем удобные инструменты для контроля сроков, ресурсов и работы в команде.
                    </li>
                    <li>
                        <strong>Контроль рабочего времени:</strong> Предоставляем решения для отслеживания рабочего времени, планирования задач и анализа продуктивности,
                        что позволяет быстрее достигать поставленных целей.
                    </li>
                    <li>
                        <strong>Управление документацией:</strong> Надежная система хранения, сортировки и доступа к документации проектов для быстрой и эффективной работы всей команды.
                    </li>
                    <li>
                        <strong>Индивидуальные решения для бизнеса:</strong> Мы разрабатываем и внедряем индивидуальные решения для каждого клиента с учётом специфики их отрасли и потребностей.
                    </li>
                </ul>

                <h3>Почему выбирают SkTech?</h3>
                <ul>
                    <li><strong>Инновационные подходы:</strong> Мы используем самые современные технологии, чтобы обеспечить эффективность и надёжность наших решений.</li>
                    <li><strong>Гибкость:</strong> Наши услуги адаптируются к различным масштабам бизнеса — от стартапов до крупных корпораций.</li>
                    <li><strong>Профессиональная команда:</strong> У нас работают квалифицированные специалисты с большим опытом в области управления проектами.</li>
                    <li><strong>Поддержка клиентов:</strong> Мы предоставляем постоянную техническую и консультационную поддержку, чтобы вы могли сосредоточиться на достижении своих бизнес-целей.</li>
                </ul>
                <h3>Наши услуги:</h3>
                <ul style={{ marginLeft: 20, gap: 50, display: "flex" }}>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Месячная подписка:</h4>
                        <label>срок действия 1 месяц</label>
                        <ul>
                            <li>
                                <strong>-Управление проектами</strong>
                            </li>
                            <li>
                                <strong>-Отслеживание рабочего времени</strong>
                            </li>
                            <li>
                                <strong>-Управление документацией</strong>
                            </li>
                        </ul>
                        <p>Решения для учета рабочих часов, планирования задач и анализа продуктивности, которые помогают достигать целей эффективнее.</p>
                        <p>Надежная система хранения, сортировки и доступа к проектной документации для быстрой и эффективной командной работы.</p>
                        <p style={{ fontSize: 20 }}><strong>Тариф:</strong> 415 грн</p>
                    </li>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Годовая подписка:</h4>
                        <label>срок действия 12 месяцев</label>
                        <ul>
                            <li>
                                <strong>-Управление проектами</strong>
                            </li>
                            <li>
                                <strong>-Отслеживание рабочего времени</strong>
                            </li>
                            <li>
                                <strong>-Управление документацией</strong>
                            </li>
                        </ul>
                        <p>Решения для учета рабочих часов, планирования задач и анализа продуктивности, которые помогают достигать целей эффективнее.</p>
                        <p>Надежная система хранения, сортировки и доступа к проектной документации для быстрой и эффективной командной работы.</p>
                        <p style={{ fontSize: 20 }}><strong>Тариф:</strong> 4150 грн</p>
                    </li>
                </ul>

                <p>
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> — ваш надежный партнёр в мире цифровой трансформации бизнеса. Мы верим, что эффективное управление — это ключ к успеху,
                    и поможем вам его достичь.
                </p>
                <p><strong>Давайте создавать будущее вместе!</strong></p>
                <p>Свяжитесь с нами уже сегодня, чтобы узнать больше о наших услугах.</p>
            </div>
        </div>
    )
})
export default AboutRU;