import SkTechApiClient from "./base/SkTechApiClient";

export default class TariffPlansApiClient extends SkTechApiClient {
  public getTariffPlans = async (): Promise<any> => {
    return await this.Get('/api/TariffPlan')
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch(function (error) {
        console.error("Error fetching tariff plans:", error);
        return [];
      });
  };
}
