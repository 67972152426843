import { Button, Flex, Form, message, Modal, Select, Input, DatePicker, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import dayjs, {Dayjs} from "dayjs";



export const AddExperience = observer((props: { isModalOpen: boolean, hideModal: () => void, fetchExperience: () => void }) => {
    const { isModalOpen, hideModal, fetchExperience } = props;
    const { employeesStore } = useStore();
    const [messageApi] = message.useMessage();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    
    const onFinish = async () => {
        const company = form.getFieldValue("company");
        const position = form.getFieldValue("position");
        const description = form.getFieldValue("description");
        const startDate = form.getFieldValue("startDate").format('YYYY-MM-DD');
        const endDate = form.getFieldValue("endDate").format('YYYY-MM-DD');
        
        await employeesStore.addExperience(company,position, description,startDate,endDate).then(async (res: any) => {
            message.success("Success");
            await employeesStore.editUser({
            }).then(() => {
                form.resetFields();
                hideModal();
                employeesStore.getUsers().catch(console.log)
            });
            return res;
        }).catch(console.log)
        fetchExperience();
    };

    return (
        <Spin spinning={employeesStore.isLoading}>
            <Modal
                title="New Experience"
                open={ isModalOpen}
                footer={null}
                onCancel={() => {
                    hideModal();
                    form.resetFields();
                }}
            >
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item<any>
                        label={`${t("Company")}`}
                        name="company"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder={`${t("Company")}`}
                        />
                    </Form.Item>
                    <Form.Item<any>
                        label={`${t("Position")}`}
                        name="position"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder={`${t("Position")}`}
                        />
                    </Form.Item>

                    <Form.Item<any>
                        label={`${t("Description")}`}
                        name="description"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder={`${t("Description")}`}
                        />
                    </Form.Item>

                    <Form.Item<any>
                        label={`${t("Start Date")}`}
                        name="startDate"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <DatePicker/>
                    </Form.Item>

                    <Form.Item<any>
                        label={`${t("End Date")}`}
                        name="endDate"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <DatePicker/>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            className="theme-btn"
                            htmlType="submit"
                            style={{ height: 65, float: "right" }}
                        >
                            {t("Save")} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    )
})
