'use client'
import { Link, useNavigate } from "react-router-dom"
import MobileMenu from "./MobileMenu"
import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "../../stores/root-store-context"

export default function Offcanvas({
    isOffCanvas,
    handleOffCanvas,
}:any) {
    const { t } = useTranslation();
    const { applicationStore } = useStore(); 
    const navigate = useNavigate();
    const isLoggedIn = !!applicationStore.isAuthenticated;
    const handleLogout = () => {
        applicationStore.logout();
        navigate("/login");
    };
    return (
        <>
            <div className="fix-area">
                <div className={`offcanvas__info ${isOffCanvas ? "info-open" : ""}`}>
                    <div className="offcanvas__wrapper">
                        <div className="offcanvas__content">
                            <div className="offcanvas__top mb-5 d-flex justify-content-between align-items-center">
                                <div>
                                    <Link to="/">
                                        <img src="/assets/logo_black.png" alt="logo-img" height={60}/>
                                    </Link>
                                    <Link to="/">
                                            <img src="/assets/logo_title.png" alt="logo-image" height={40} />
                                        </Link>
                                </div>
                                <div className="offcanvas__close" onClick={handleOffCanvas}>
                                    <button>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>
                            <p className="text d-none d-lg-block">
                                {t("EnhanceBusiness")}
                            </p>
                            <div className="d-none d-md-block d-lg-none">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <MobileMenu />
                                </Suspense>
                            </div>

                            <div className="offcanvas__contact">
                                <h4>{t("ContactInfo")}</h4>
                                <ul>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon">
                                            <i className="fal fa-map-marker-alt" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link target="_blank" to="#">{t("CompanyAddress")}</Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fal fa-envelope" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link to="/mailto:info@azent.com"><span className="mailto:SkTech@sunpoint.com">SkTech@sunpoint.com</span></Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="fal fa-clock" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link target="_blank" to="#">{t("MondayToFriday")}, 08am-05pm</Link>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="offcanvas__contact-icon mr-15">
                                            <i className="far fa-phone" />
                                        </div>
                                        <div className="offcanvas__contact-text">
                                            <Link to="/tel:+208-555-0112">+208-555-0112</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="header-button mt-4">
                                    {isLoggedIn ? (
                                        <button
                                            onClick={handleLogout}
                                            className="theme-btn text-center"
                                        >
                                            <span>
                                                {t("LogOut")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </span>
                                        </button>
                                    ) : (
                                        <Link to="/register-company" className="theme-btn text-center">
                                            <span>
                                                {t("GetStarted")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </span>
                                        </Link>
                                    )}
                                </div>
                                <div className="social-icon d-flex align-items-center">
                                    <Link to="#"><i className="fab fa-facebook-f" /></Link>
                                    <Link to="#"><i className="fab fa-twitter" /></Link>
                                    <Link to="#"><i className="fab fa-youtube" /></Link>
                                    <Link to="#"><i className="fab fa-linkedin-in" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`offcanvas__overlay ${isOffCanvas ? "overlay-open" : ""}`} onClick={handleOffCanvas} />
        </>

    )
}
