import React from 'react';
import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { App, Button, Flex, Form, FormProps, Input, Popconfirm, Table, TableProps, Image, Tag, Typography, Space, Modal, Select, AutoComplete, Spin, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, SettingOutlined, EyeOutlined, PlusOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useStore } from "../../stores/root-store-context";
import { ProjectDocument } from "../../stores/ProjectDocumentationStore";
import ReactQuill from "react-quill";
import { AssignUserProject, Project } from "../../stores/ProjectsStore";
import { Employee, Skill } from "../../api/EmployeesApiClient";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { Roles } from '../../stores/EmployeesStore';

const { Option } = Select;

const ProjectSettings = observer(() => {
    const [form] = Form.useForm();
    const [assignForm] = Form.useForm();
    const { message } = App.useApp();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { applicationStore, projectsStore, projectDocumentationStore, employeesStore } = useStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
        assignForm.resetFields();
    };

    const updatePage = () => {
        if (id) {
            projectsStore.getById(id).then(res => {
                form.setFieldsValue(res);
            }).catch(error => {
                message.error(error.message);
            });
            employeesStore.getUsers();
        }
    }

    const onSave = async () => {
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });
        const data = form.getFieldsValue();
        projectsStore.editProject(data).then(() => {
            message.destroy("Saving");
            message.open({
                type: 'success',
                content: 'Saved',
                key: 'Saved'
            });
        });
    }

    const onChangRole = async (userId: string, roleId: string) => {
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        const data: AssignUserProject = {
            projectId: id || "",
            userId: userId,
            roleId: roleId
        }

        projectsStore.editAssignUser(data).then(() => {
            message.destroy("Saving");
            message.open({
                type: 'success',
                content: 'Saved',
                key: 'Saved'
            });
        });
    }

    const onAssignUser: FormProps<AssignUserProject>['onFinish'] = async (item: AssignUserProject) => {
        const data = assignForm.getFieldsValue();
        const userId = data.userId;
        console.log(data);
        
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });
        if (userId) {
            const assignData: AssignUserProject = {
                projectId: data.projectId,
                userId: userId,
                roleId: data.role.key
            };
            projectsStore.assignNewUser(assignData)
                .then(() => {
                    updatePage();
                })
                .finally(() => {
                    hideModal();
                    message.destroy("Saving");
                    message.open({
                        type: 'success',
                        content: 'Saved',
                        key: 'Saved'
                    });
                });
        }
    };



    const optionsRole = [
        { key: Roles.Owner, value: Roles.Owner, label: 'Admin', description: 'All access for project' },
        { key: Roles.Manager, value: Roles.Manager, label: 'User', description: 'Editing, and inviting users' },
        { key: Roles.User, value: Roles.User, label: 'Viewer', description: 'Only viewing' },
    ];

    const columns: TableProps<any>['columns'] = [
        {
            title: '',
            dataIndex: 'photoId',
            key: 'photo',
            render: (photo) => <Image
                width={40}
                height={40}
                style={{ borderRadius: "50%", objectFit: "cover" }}
                src={photo ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${photo}` :
                    `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                }
            />,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, record) => `${record.firstName} ${record.lastName}`,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.lastName)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <div>{record.email}</div>,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.lastName)

        },
        {
            title: 'Roles',
            dataIndex: ['role'],
            key: 'Roles',
            width: 300,
            render: (item, record) => <div>
                <Select
                    labelInValue
                    defaultValue={optionsRole.find(option => option.key === record.role.id)}
                    style={{ width: 120 }}
                    onChange={(value: any) => {
                        console.log(value);
                        onChangRole(record.id, value.key)
                    }}
                    optionLabelProp="label"
                    options={
                        optionsRole.map(option => {
                            return {
                                key: option.value, value: option.label, label: <div>
                                    <div>{option.label}</div>
                                    <div style={{ fontSize: '12px', color: '#888' }}>{option.description}</div>
                                </div>
                            }
                        })
                    }
                /></div>,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: any) => {
                function save(key: any): void {
                    throw new Error("Function not implemented.");
                }

                return false ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => { }}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        <Flex justify="space-between">
                            <Button type="primary" shape="circle" onClick={() => {
                                navigate(`/${applicationStore.company.Tenaut}/employers/${record.id}`)
                            }} icon={<EyeOutlined />} />
                            <Popconfirm
                                title="Delete the user"
                                description="Are you sure to delete this user?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    console.log("delete");
                                    console.log(record);
                                    projectsStore.deleteAssignUser(record.id, projectsStore.project.id)
                                }}
                            >
                                <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Flex>

                    </>
                );
            },
        }
    ];

    useEffect(() => {
        updatePage();
    }, []);
    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div className="container" style={{ margin: "20px auto" }}>
                <Flex justify="space-between">
                    <Button type="primary"
                        style={{ float: "right", marginBottom: 20, borderRadius: 0, }}
                        onClick={() => {
                            navigate("./../");
                        }}>
                        {t("Back")}
                    </Button>
                </Flex>
                <Spin spinning={projectsStore.isLoading}>
                    <Form form={form} layout="vertical">
                        <Form.Item<Project> name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item<Project> name="startDate" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item<Project> label="Title" name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                            <Input placeholder="Title" onBlur={onSave} />
                        </Form.Item>
                        <Form.Item<Project> label="Description" name="description" style={{ width: "100%", marginBottom: 60 }} rules={[{ required: true }]} >
                            <ReactQuill theme="snow" style={{ height: 300 }} onBlur={onSave} />
                        </Form.Item>
                        <Form.Item<Project> label="Is Publish Project" name="isPublish" style={{ width: "100%" }} >
                            <Switch onChange={onSave} />
                        </Form.Item>
                        <Flex gap={10} justify="space-between" >
                            <span>{t("Users")}</span>
                            <Button type="primary"
                                style={{ float: "right", marginBottom: 20, borderRadius: 0, }}
                                onClick={showModal}>
                                {t("Add_user")} <PlusOutlined />
                            </Button>
                        </Flex>
                        <Table columns={columns} dataSource={projectsStore.project?.members?.map((el, index) => { return { ...el, key: index } })}
                        />
                    </Form>
                </Spin>
            </div>
            <Modal title={t("AddUser")} open={isModalOpen} footer={null} onCancel={hideModal} width={900}
                style={{ padding: 10 }}>
                <Form form={assignForm} onFinish={onAssignUser} initialValues={{ projectId: id, creatorId: applicationStore.user.id }} layout="vertical">
                    <Form.Item<any> name="projectId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<any> name="email" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<any> name="userId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<any> label={t('User')} name="user" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <AutoComplete
                            style={{ height: 40 }}
                            placeholder={t('User')}
                            options={employeesStore.users?.map((el, index) => ({
                                key: index,
                                value: `${el.firstName} ${el.lastName}`,
                                data: el,
                                label: <Flex gap={5}>
                                    <Image
                                        width={40}
                                        height={40}
                                        preview={false}
                                        style={{ borderRadius: "50%", objectFit: "cover" }}
                                        src={el.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${el.photoId}` :
                                            `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                                        }
                                    />
                                    <div>
                                        <div>{el.firstName}{el.lastName}</div>
                                        <div>{el.email}</div>
                                    </div>
                                </Flex>
                            }))}
                            onChange={(value, option: any) => {
                                assignForm.setFieldValue("userId", option.data?.id)
                            }}
                        />
                    </Form.Item>
                    <Form.Item<any> label="Role" name="role" style={{ width: "100%", marginBottom: 30 }} rules={[{ required: true }]} >
                        <Select
                            labelInValue
                            optionLabelProp="label"
                            style={{ width: '100%' }}
                            options={optionsRole.map(option => ({
                                key: option.value,
                                value: option.value,
                                label: option.label,
                                children: (
                                    <div>
                                        <div>{option.label}</div>
                                        <div style={{ fontSize: '12px', color: '#888' }}>{option.description}</div>
                                    </div>
                                )
                            }))}
                        />
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                        <Flex justify="space-between">
                            <Popconfirm
                                title="Delete the section"
                                description="Are you sure to delete this section?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                }}
                            >
                                {projectDocumentationStore.document !== null &&
                                    <Button type="default" danger style={{ height: 65, marginTop: 30 }}>
                                        {t('DeleteSection')} <DeleteOutlined style={{ fontSize: 20, marginLeft: 5 }} /></Button>
                                }
                            </Popconfirm>
                            <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, marginTop: 30 }}>
                                {t('Save')} <SaveOutlined style={{ fontSize: 20, marginLeft: 5, height: 65 }} />
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>)
});
export default ProjectSettings;
