import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet";

const AboutEN = observer(() => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 20, marginBottom: 30 }}>
                <h2>About SkTech LLC "SANPOINT IT SOLUTIONS"</h2>
                <p>
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> is a modern sercie of company LLC "SANPOINT IT SOLUTIONS" specializing in providing solutions for effective project management,
                    employee time tracking, documentation, and various other aspects of business processes. We strive to equip our clients with tools
                    that optimize workflows, enhance productivity, and reduce costs.
                </p>

                <h3>Our Services:</h3>
                <ul>
                    <li>
                        <strong>Project Management:</strong> We help structure all project stages — from planning to implementation.
                        Our tools enable seamless control of deadlines, resources, and teamwork.
                    </li>
                    <li>
                        <strong>Employee Time Tracking:</strong> We offer solutions for tracking work hours, task scheduling, and productivity analysis,
                        helping you achieve your goals more efficiently.
                    </li>
                    <li>
                        <strong>Documentation Management:</strong> A reliable system for storing, sorting, and accessing project documentation to ensure
                        fast and efficient teamwork.
                    </li>
                    <li>
                        <strong>Customized Business Solutions:</strong> We design and implement tailored solutions for each client, considering the specifics
                        of their industry and business needs.
                    </li>
                </ul>

                <h3>Why Choose SkTech?</h3>
                <ul>
                    <li><strong>Innovative Approaches:</strong> We utilize cutting-edge technologies to ensure the efficiency and reliability of our solutions.</li>
                    <li><strong>Flexibility:</strong> Our services are adaptable for businesses of all sizes, from startups to large corporations.</li>
                    <li><strong>Professional Team:</strong> Our team comprises skilled experts with extensive experience in project management.</li>
                    <li><strong>Client Support:</strong> We provide ongoing technical and consultative support, so you can focus on achieving your business goals.</li>
                </ul>
                <h3>Our Services:</h3>
                <ul style={{ marginLeft: 20, gap: 50, display: "flex" }}>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Monthly Subscription:</h4>
                        <label>validity period 1 month</label>
                        <ul>
                            <li>
                                <strong>-Project Management</strong>
                            </li>
                            <li>
                                <strong>-Time Tracking</strong>
                            </li>
                            <li>
                                <strong>-Documentation Management</strong>
                            </li>
                        </ul>
                        <p>Solutions for tracking working hours, task scheduling, and productivity analysis to help achieve goals more efficiently.</p>
                        <p>A reliable system for storing, organizing, and accessing project documentation to enable quick and effective teamwork.</p>
                        <p style={{ fontSize: 20 }}><strong>Rate:</strong> 415 UAH</p>
                    </li>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Annual Subscription:</h4>
                        <label>validity period 12 months</label>
                        <ul>
                            <li>
                                <strong>-Project Management</strong>
                            </li>
                            <li>
                                <strong>-Time Tracking</strong>
                            </li>
                            <li>
                                <strong>-Documentation Management</strong>
                            </li>
                        </ul>
                        <p>Solutions for tracking working hours, task scheduling, and productivity analysis to help achieve goals more efficiently.</p>
                        <p>A reliable system for storing, organizing, and accessing project documentation to enable quick and effective teamwork.</p>
                        <p style={{ fontSize: 20 }}><strong>Rate:</strong> 4150 UAH</p>
                    </li>
                </ul>

                <p>
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> is your reliable partner in the world of digital business transformation. We believe that effective management
                    is the key to success and will help you achieve it.
                </p>
                <p><strong>Let's create the future together!</strong></p>
                <p>Contact us today to learn more about our services.</p>
            </div>
        </div>
    )
})
export default AboutEN;