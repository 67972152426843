import { Badge, Button, Calendar, Flex, Spin, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/root-store-context";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import AddEvent from "./AddEvent";
dayjs.extend(utc);

const CalendarEvents = observer(({ events, startDate, endDate }: { events: any[], startDate: Dayjs, endDate: Dayjs }) => {
  const { employeesStore, applicationStore, eventStore } = useStore();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const dateCellRender = (value: dayjs.Dayjs) => {
    const formattedValue = value.format('YYYY-MM-DD');
    const startEventList = events.filter(event => {
      const formattedDate = dayjs(event.startDate).format('YYYY-MM-DD');
      return formattedDate === formattedValue;
    });
    const endEventList = events.filter(event => {
      const formattedDate = dayjs(event.endDate).format('YYYY-MM-DD');
      return formattedDate === formattedValue;
    });

    return (
      <ul >
        {startEventList.map(event => {
          const user = employeesStore.users.find(el => el.id === event.userId);
          return (
            <Tooltip title={
              <>
                {user?.email} <br />
                {t(`${event.type}`)} <br />
                {event.startDate.format('YYYY-MM-DD')} - {event.endDate.format('YYYY-MM-DD')}
              </>
            }>
              <li key={event.id}>
                <Badge color="blue" text={user?.lastName} />
                {" - "} {t(`${event.type}`)}
              </li>
            </Tooltip>
          )
        })}
        {endEventList.map(event => {
          const user = employeesStore.users.find(el => el.id === event.userId);
          return (
            <Tooltip title={
              <>
                {user?.email} <br />
                {t(`${event.type}`)} <br />
                {event.startDate.format('YYYY-MM-DD')} - {event.endDate.format('YYYY-MM-DD')}
              </>
            }>
              <li key={event.id}>
                <Badge color="red" text={user?.lastName} />
                {" - "} {t(`${event.type}`)}
              </li>
            </Tooltip>
          )
        })}
      </ul>
    );
  };

  return (
    <Spin spinning={eventStore.isLoading} style={{ minHeight: 600 }}>
      <div className="container" style={{ margin: "20px auto" }}>
        <Flex justify="end">
          <Button onClick={showModal} type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }}>
            {`${t("Add")}`}
          </Button>
        </Flex>
      </div>
      <div className="container">
        <Calendar
          value={startDate}
          headerRender={() => {
            return <div />;
          }}
          dateCellRender={e => dateCellRender(e)}
        />
      </div>
      <AddEvent isModalOpen={isModalOpen} hideModal={hideModal} />

    </Spin>
  )
})
export default CalendarEvents;