import { useTranslation } from "react-i18next";

export default function MessageNotHaveProjects() {
  const { t } = useTranslation();
    return (
        <>
            <div style={{ width: 400 }}>
                <p>{t("NoProjectsAvailable")}</p>
                <ul>
                    <li><strong>{t("CreatePublicProject")}</strong></li>
                    <li><strong>{t("AddExistingProject")}</strong></li>
                </ul>
            </div>
        </>
    )
}
