import { UploadFile } from "antd";
import { Roles } from "../stores/EmployeesStore";
import { AssignUserProject, NewProject, Project } from "../stores/ProjectsStore";
import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { RcFile } from "antd/es/upload";


export default class ProjectsApiClient extends SkTechApiClient {

  public addProject = async (project: NewProject): Promise<any> => {
    return await this.tryPost(`/api/Project`, {
      title: project.title,
      description: project.description,
      startDate: project.startDate.format('YYYY-MM-DD'),
      isActive: project.isActive,
      isPublish: project.isPublish,
    })
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getProjects = async (): Promise<Project[]> => {
    return await this.Get(`/api/Project`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data.map((el: {
            startDate: string;
          }) => {
            return {
              ...el,
              startDate: dayjs(el.startDate),
            }
          });
        }
        return false;
      });
  }

  public getProjectsById = async (id: string): Promise<Project> => {
    return await this.Get(`/api/Project/${id}`)
      .then(function (response) {
        if (response?.status === 200) {
          return {
            ...response.data,
            startDate: dayjs(response.data.startDate),
            members: response.data.members?.sort((a: any, b: any) => a.id - b.id),
            developers: response.data.developers?.sort((a: any, b: any) => a.id - b.id)
          };
        }
        return false;
      });
  }

  public alwaysDeleteProjectsById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/Project/SetDeleted?projectId=${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public deleteProjectsById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/Project/SetDeleted?projectId=${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public editProjects = async (project: any): Promise<any> => {
    return await this.Put(`/api/Project/${project.id}`, {
      ...project,
      startDate: project.startDate.format('YYYY-MM-DD'),
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public assignNewUser = async (item: AssignUserProject): Promise<any> => {
    return await this.tryPost(`/api/Project/AssignUserToProject?userId=${item.userId}&projectId=${item.projectId}&roleTitle=${Roles[parseInt(item.roleId)]}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public editAssignUser = async (item: AssignUserProject): Promise<any> => {
    return await this.tryPost(`/api/Project/ChangeUserProjectRole?userId=${item.userId}&projectId=${item.projectId}&roleTitle=${Roles[parseInt(item.roleId)]}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public deleteAssignUser = async (userId: string, projectId: string): Promise<any> => {
    return await this.tryPost(`/api/Project/RemoveUserFromProject?userId=${userId}&projectId=${projectId}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public uploadAttachment = async (file: UploadFile, id: string): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file.originFileObj as RcFile);
    return await this.tryPost(`/api/File/UploadProjectAttachment?projectId=${id}`,
      formData
    )
      .then((response) => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public GetAttachmentsListByProject = async (id: string): Promise<any> => {
    return await this.tryGet(`/api/Documentation/GetAttachmentsListByProject?projectId=${id}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }
}