// components/RequireAuth.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface RequireAuthProps {
    children: ReactNode;
    isAuthenticated: boolean;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, isAuthenticated }) => {
    return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

export default RequireAuth;
