import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout"

const PublicOffer = () => {
    return (
        <Layout headerStyle={1} footerStyle={2} >
            <Helmet>
                <meta name="description" content="Public Offer" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 10, marginBottom: 30 }}>
                <h2 style={{ textTransform: "none" }}>Публічний договір оферти з ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС"</h2>
                <p>Цей документ є публічним договором оферти (далі – «Договір»), адресованим невизначеному колу осіб для укладення договору про надання послуг з управління проектами на вебсайті ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС" SkTech (далі – «Сайт»). Користуючись Сайтом та здійснюючи оплату підписки, Користувач автоматично приймає умови цього Договору.</p>

                <h3>1. Загальні положення</h3>
                <p>1.1. Цей Договір регулює відносини між Сайтом SkTech ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС" (далі – «Адміністрація») та користувачем (далі – «Користувач»), що укладаються на основі прийняття оферти.</p>
                <p>1.2. Цей Договір є офіційною пропозицією Адміністрації для укладення договору на умовах, викладених нижче.</p>
                <p>1.3. Використання Сайту означає повну згоду Користувача з усіма умовами Договору.</p>

                <h3>2. Предмет договору</h3>
                <p>2.1. Адміністрація надає Користувачеві доступ до платформи для управління проектами (далі – «Сервіс») на умовах підписки, а Користувач зобов'язується оплатити відповідну вартість.</p>
                <p>2.2. Доступ до Сервісу надається в обмін на оплату обраної підписки: щомісячної або річної.</p>

                <h3>3. Порядок укладення договору</h3>
                <p>3.1. Договір вважається укладеним із моменту прийняття Користувачем оферти шляхом здійснення оплати підписки на Сайті.</p>
                <p>3.2. Прийняття (акцепт) оферти означає повне та безумовне прийняття всіх умов Договору без будь-яких виключень.</p>

                <h3>4. Права та обов’язки сторін</h3>
                <ul style={{ paddingLeft: "2rem" }}>
                    <li style={{ paddingBottom: 10 }}><strong>4.1. Обов’язки Адміністрації:</strong>
                        <ul>
                            <li>Надавати доступ до платформи для управління проектами відповідно до обраної підписки;</li>
                            <li>Забезпечувати належний технічний стан Сервісу для стабільної роботи;</li>
                            <li>Відповідати на запити та вирішувати питання, що виникають під час користування Сервісом.</li>
                        </ul>
                    </li>
                    <li><strong>4.2. Обов’язки Користувача:</strong>
                        <ul>
                            <li>Дотримуватися умов цього Договору та політики конфіденційності;</li>
                            <li>Надавати лише достовірну інформацію під час реєстрації;</li>
                            <li>Не передавати свої дані для доступу до Сервісу третім особам.</li>
                        </ul>
                    </li>
                </ul>
                <h3>5. Вартість послуг та порядок оплати</h3>
                <p>5.1. Вартість підписки публікується на Сайті. Ціна встановлюється в гривнях, відповідно до курсу НБУ для еквіваленту:</p>
                <ul>
                    <li>Щомісячна підписка: 10 доларів США (еквівалент у гривнях).</li>
                    <li>Річна підписка: 100 доларів США (еквівалент у гривнях).</li>
                </ul>
                <p>5.2. Оплата здійснюється через платіжну систему, інтегровану на Сайті.</p>
                <p>5.3. Адміністрація залишає за собою право змінювати вартість підписки, попередньо повідомивши Користувача через Сайт.</p>

                <h3>6. Порядок повернення коштів</h3>
                <p>6.1. У разі технічних проблем із боку Адміністрації, що призвели до неможливості користування Сервісом, Користувач може звернутися із запитом на часткове або повне повернення коштів.</p>
                <p>6.2. Запит має бути поданий протягом 14 календарних днів із моменту виникнення проблеми.</p>
                <p>6.3. Повернення коштів не здійснюється, якщо Користувач добровільно припинив користування Сервісом до завершення терміну дії підписки.</p>

                <h3>7. Відповідальність сторін</h3>
                <p>7.1. Адміністрація не несе відповідальності за збитки, що виникли через:</p>
                <ul>
                    <li>Некоректне використання Сервісу Користувачем.</li>
                    <li>Проблеми в роботі обладнання або програмного забезпечення Користувача.</li>
                    <li>Форс-мажорні обставини.</li>
                </ul>
                <p>7.2. Користувач несе відповідальність за достовірність інформації, наданої під час реєстрації.</p>

                <h3>8. Термін дії договору</h3>
                <p>8.1. Договір набуває чинності з моменту оплати підписки та діє протягом обраного періоду (місяць або рік).</p>
                <p>8.2. Після завершення строку підписки Користувач може подовжити дію договору, здійснивши оплату наступного періоду.</p>

                <h3>9. Інші умови</h3>
                <p>9.1. Адміністрація має право змінювати умови Договору. Зміни набувають чинності з моменту їх публікації на Сайті.</p>
                <p>9.2. Продовження користування Сервісом після внесення змін означає згоду Користувача з такими змінами.</p>

                <h3>10. Контактна інформація</h3>
                <p>ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС"</p>
                <p>Код ЄДРПОУ: 42783354</p>
                <p>Адреса: вул. Серпова, 11, м. Київ, Україна</p>
                <p>Email: sktech@sunpoint.com.ua</p>

                <p>Цей Договір є публічним, безстроковим, та залишається чинним до моменту його відкликання Адміністрацією.</p>

            </div>

        </Layout>
    )
}
export default PublicOffer;