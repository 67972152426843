import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "./base/SkTechApiClient";
import { Company } from "../stores/ApplicationStore";
import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";


export default class CompanyApiClient extends SkTechApiClient {

  public getCompanyById = async (id: string): Promise<any> => {
    return await this.Get(`/api/Companies/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public CompanyCheck = async (name: string): Promise<boolean> => {
    return await this.Get(`/api/Company/Check?companyName=${name}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public editCompany = async (company: Company | any): Promise<any> => {
    return await this.Patch(`/api/Companies/${company.Id}`, {
      ...company
    })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public logoUpload = async (file: UploadFile, companyId: string): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as RcFile);
    return await this.Post(`/api/File/UploadCompanyLogo`, formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

}