import Layout from "../../components/layout/Layout"
import { Helmet } from 'react-helmet';

const PrivacyPolicyEN = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 10, marginBottom: 30 }}>
                <h2>Privacy Policy</h2>
                <p>The Privacy Policy governs the collection, use, and processing of personal data of users of the <strong>SkTech</strong> website (hereinafter referred to as the "Website"), which provides project management services. By using our Website, the User agrees to the terms of this Policy.</p>

                <h3>1. Collection and Use of Personal Data</h3>
                <ol>
                    <li>
                        <p>The Website collects the following data:</p>
                        <ul>
                            <li>First name, last name, email address, phone number;</li>
                            <li>Information voluntarily provided by the User (during registration, inquiries).</li>
                        </ul>
                    </li>
                    <li>
                        <p>Purposes of data processing:</p>
                        <ul>
                            <li>Providing access to the Service;</li>
                            <li>Technical support;</li>
                            <li>Informing about news and updates;</li>
                            <li>Analytics to improve service quality.</li>
                        </ul>
                    </li>
                </ol>

                <h3>2. Data Protection and Storage</h3>
                <ol>
                    <li>The Website ensures an appropriate level of protection for Users' personal data against unauthorized access, loss, or alteration.</li>
                    <li>Storage period: until the User's profile is deleted or the Website ceases operation.</li>
                </ol>

                <h3>3. Disclosure of Personal Data</h3>
                <ol>
                    <li>
                        The Website does not share personal data with third parties, except in cases where data may be shared with:
                        <ul>
                            <li>Payment systems to process transactions;</li>
                            <li>Partners for Website maintenance;</li>
                            <li>Authorized authorities in accordance with the laws of Ukraine.</li>
                        </ul>
                    </li>
                </ol>

                <h3>4. Use of Cookies</h3>
                <ol>
                    <li>Cookies are used to save User settings, analyze traffic, and improve Website performance.</li>
                    <li>The User may disable cookies in browser settings, but this may limit functionality.</li>
                </ol>

                <h3>5. User Rights</h3>
                <ol>
                    <li>
                        The User has the right to:
                        <ul>
                            <li>Access and modify their data;</li>
                            <li>Restrict processing or delete data;</li>
                        </ul>
                    </li>
                    <li>Requests should be submitted using the contact information provided below.</li>
                </ol>

                <h3>6. Changes to the Policy</h3>
                <p>The Website reserves the right to modify the Policy. Updates are published with the effective date. Continued use of the Website signifies acceptance of the changes.</p>

                <h3>7. Contact Information</h3>
                <p>LLC "SUNPOINT IT SOLUTIONS"<br />
                    EDRPOU Code: 42783354<br />
                    Address: Serpova Street 11, Kyiv, Ukraine<br />
                    Email: sktech@sunpoint.com.ua</p>

                <p>Last updated: 27.11.2024</p>

            </div>
        </div>
    )
}
export default PrivacyPolicyEN;