import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { Dayjs } from "dayjs";
import { Employee, Skill } from "../api/EmployeesApiClient";
import { Roles } from "./EmployeesStore";
import { TimeReport } from "../api/TimeReportsApiClient";

class TimeReportStore {
  isFull: boolean = false;
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  reports: TimeReport[];
  reportsForFinancier: { log: TimeReport, payment: number }[];
  myTime: string;
  mySalary: string;
  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.reports = [];
    this.reportsForFinancier = [];
    this.myTime = "00:00:00";
    this.mySalary = "00";
  }

  setFullScreen(isFull: boolean) {
    this.isFull = isFull;
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  add = async (
    projectId: string,
    time: string,
    endTime: string,
    userId: string,
    description: string
  ) => {
    this.isLoading = true;
    const response = await this.ApiStore.TimeReportsApiClient.addTime(
      projectId,
      time,
      endTime,
      userId,
      description
    );
    this.isLoading = false;

    return response;
  };

  edit = async (id: string, time: string,endDate: string, description: string) => {
    this.isLoading = true;
    const response = await this.ApiStore.TimeReportsApiClient.editTime(id, {
      totalTime: time,
      endDate: endDate,
      description,
    });
    this.isLoading = false;

    return response;
  };

  filterByUserIdFinancier = (userId: string) => {
    return this.reportsForFinancier.filter(el => el.log.userId === userId)
  }

  filterByUserId = (userId: string) => {
    return this.reports.filter((el) => el.userId === userId);
  };

  filterByProjectId = (projectId: string) => {
    return this.reports.filter((el) => el.projectId === projectId);
  };

  startTimer = async (projectId: string) => {
    this.isLoading = true;
    await this.ApiStore.TimeReportsApiClient.startTimer(projectId).catch(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    return true;
  };
  
  stopTimer = async (projectId: string, description: string) => {
    this.isLoading = true;
    await this.ApiStore.TimeReportsApiClient.stopTimer(
      projectId,
      description
    ).catch(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    return true;
  };
  
  getTimer = async (userId: string) => {
      this.isLoading = true;
      const reports: any = await this.getAll();
      console.log("all reports output", reports);
      const timer = reports?.find((report: any) => {
        const isFinancier = report.log !== undefined;
    
        const matchWithoutLog = 
            report.totalTime === undefined &&
            report.userId === userId;
      
        const matchWithLog = isFinancier &&
            report.log.totalTime === undefined &&
            report.log.userId.toString() === userId.toString();
    
        return matchWithoutLog || matchWithLog;
    });

      this.isLoading = false;
      return timer;
  }




  
  deleteTimer = async (id: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.TimeReportsApiClient.deleteTimer(
      id
    ).catch(() => {
      this.isLoading = false;
      return false
    });
    this.isLoading = false;
    return result;
  }
  filterByProjectIdFinancier = (projectId: string) => {
    return this.reportsForFinancier.filter(el => el.log.projectId === projectId)
  }

  getAll = async () => {
    this.isLoading = true;
    const result = await this.ApiStore.TimeReportsApiClient.getAll().catch(
      () => {
        this.isLoading = false;
      }
    );
    if (result) {
      this.reports = result;
    }
    this.isLoading = false;
    return result;
  };

  getMyTime = async (userId: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.TimeReportsApiClient.getByUserId(
      userId
    ).finally(() => {
      this.isLoading = false;
    });
    if (result.time) {
      this.myTime = result.time;
      this.mySalary = result.payment
    } else {
      this.myTime = result
    }
  };

  getByUserId = async (id: string): Promise<string> => {
    this.isLoading = true;
    const result = await this.ApiStore.TimeReportsApiClient.getByUserId(
      id
    ).finally(() => {
      this.isLoading = false;
    });
    if (result) {
      return result;
    } else {
      return "00:00:00";
    }
  };

  getByProjectId = async (userId: string, projectId: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.TimeReportsApiClient.getByProjectId(
      userId,
      projectId
    ).catch(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    return result;
  };

  deleteById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.TimeReportsApiClient.deleteById(id)
      .then(() => {
        this.reports = this.reports.filter((el) => el.id !== id);
      })
      .finally(() => {
        this.isLoading = false;
      });

    return project;
  };

  getForFinancier = async () => {
    this.isLoading = true;
    const result =
      await this.ApiStore.TimeReportsApiClient.getForFinancier().catch(() => {
        this.isLoading = false;
      });
    if (result) {
      console.log("reportsForFinancier", result);
      
      this.reportsForFinancier = result;
    }
    this.isLoading = false;
    return result;
  };
}
export default TimeReportStore;
