import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { useStore } from "../../stores/root-store-context";
import {
  Button,
  Card,
  Descriptions,
  Flex,
  Form,
  Input,
  Modal,
  Image,
  Spin,
  Tag,
  Divider,
  Space,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Employee, HistoryItem } from "../../api/EmployeesApiClient";
import { MobileOutlined, MailOutlined, GiftOutlined } from "@ant-design/icons";
import { Roles } from "../../stores/EmployeesStore";
import { SkillLevelName, SkillLevelNameColor } from "../../helpers/skills";
import { useTranslation } from "react-i18next";

import InfoBlock from "../../components/blocks/InfoBlock";
import EducationBlock from "../../components/blocks/EducationBlock";
import ReactPDF, { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CVGenerator from "./CVGenerator";
import ExperienceBlock from "../../components/blocks/ExperienceBlock";
import dayjs from "dayjs";
import { render } from '@testing-library/react';
import Error404 from './../Error404';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import UserComponent from "./UserComponent";
import { useReactToPrint } from "react-to-print";

const Account = observer(() => {
  const { applicationStore, employeesStore, projectsStore } = useStore();
  const navigate = useNavigate();
  const userPage = useRef(null);
  const { id } = useParams();
  const { t } = useTranslation();

  const [isModalPassword, setIsModalPassword] = useState(false);
  const [isModalEmail, setIsModalEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Employee>();
  // const [history, setHistory] = useState<HistoryItem[]>([]);
  const profileRef = useRef(null);
  const showModalPassword = () => {
    setIsModalPassword(true);
  };

  const handleOkPassword = () => {
    setIsModalPassword(false);
  };

  const handleCancelPassword = () => {
    setIsModalPassword(false);
  };

  const showModalEmail = () => {
    setIsModalEmail(true);
  };

  const handleOkEmail = () => {
    setIsModalEmail(false);
  };

  const handleCancelEmail = () => {
    setIsModalEmail(false);
  };
  function ParseToJson(str: string): any[] {
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  }
  useEffect(() => {
    if (id) {
      setLoading(true);
      employeesStore.ApiStore.EmployeesApiClient.getUserById(id)
        .then(async (result) => {
          console.log("result");
          console.log(result);
          setUser(result);

          // if(projectsStore.projects?.length === 0){
          //   await projectsStore.getAll()
          // }
          // await employeesStore.ApiStore.EmployeesApiClient.getHistoryByUser(id).then(res => {
          //   setHistory(res)
          // });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/404");
    }
    // employeesStore.ApiStore.EmployeesApiClient.getUserById(id);
    console.log(id);
  }, [id]);
  const handlePrint = useReactToPrint({
    content: () => profileRef.current,
    documentTitle: 'Employee Profile',
    pageStyle: `@page { size: A4; margin: 0mm }`,
  });
  return (
    <>
      <Layout headerStyle={1} footerStyle={2}>
        <Spin spinning={loading} style={{ minHeight: 600 }}>
          <Flex className="container" gap={10} style={{ marginTop: 20, display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ float: "left", borderRadius: 0 }}
              onClick={() => {
                navigate(-1);
              }}
            >
               {t("back")}
            </Button>
            <Space>
              {/* {user && (
                <PDFDownloadLink
                  document={
                    // <UserComponent user={user} />
                    <CVGenerator user={user} skills={applicationStore.skills} />
                  }
                  fileName="cv.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    <Button type="primary">{loading ? "Loading document..." : "Download CV"}</Button>
                  }
                </PDFDownloadLink>
              )
              } */}
              <Button type="primary" onClick={async () => {
                if (userPage.current && user) {
                  const element = userPage.current;
                  setLoading(true);
                  document.querySelectorAll('.fa.fa-pencil').forEach(el => {
                    const element = el as HTMLElement;
                    element.style.display = 'none';
                  });
                  document.querySelectorAll('.company-logo').forEach(el => {
                    const element = el as HTMLElement;
                    element.style.display = 'block';
                  });
                  const canvas = await html2canvas(element, {
                    useCORS: true, // This helps with capturing external images or resources
                    scale: 3, // Increase scale to improve quality
                    logging: false, // Disable console logging for better performance
                    scrollX: -window.scrollX, // Consider scroll positions
                    scrollY: -window.scrollY,
                  });

                  const imgData = canvas.toDataURL('image/png');

                  const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [595, 842], // set needed dimensions for A4 page
                  });
                  const pdfWidth = pdf.internal.pageSize.getWidth();
                  const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                  pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                  pdf.save(`${user.firstName}_${user.lastName}.pdf`);
                  document.querySelectorAll('.fa.fa-pencil').forEach(el => {
                    const element = el as HTMLElement;
                    element.style.display = 'block';
                  });
                  document.querySelectorAll('.company-logo').forEach(el => {
                    const element = el as HTMLElement;
                    element.style.display = 'none';
                  });
                  setLoading(false);
                }
              }}>{t("downloadCV")}</Button>
              {/* <Button type="primary" onClick={handlePrint}>{"Print"}</Button> */}
            </Space>
          </Flex>
          <Flex
            className="container"
            gap={10}
            style={{ marginTop: 20, marginBottom: 20 }}
            vertical
            ref={userPage}
          >
            <div className="company-logo" style={{ display: "none" }}>
              {applicationStore.company?.LogoId ?
                <img src={`https://res.cloudinary.com/dnl3x07wo/image/upload/${applicationStore.company?.LogoId}`} alt="logo-img" height={60} />
                :
                <img src="/assets/logo_black.png" alt="logo-img" height={60} />
              }
            </div>
            {user && <UserComponent user={user} />}
          </Flex>
        </Spin>
        <Modal
          title={t("enterNewEmail")}
          open={isModalEmail}
          onOk={handleOkEmail}
          onCancel={handleCancelEmail}
        >
          <Form>
            <Form.Item<any>
              name="Email"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <Input placeholder={t("emailPlaceholder")} pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={t("enterNewPassword")}
          open={isModalPassword}
          onOk={handleOkPassword}
          onCancel={handleCancelPassword}
        >
          <Form>
            <Form.Item<any>
              name="Password"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <Input placeholder={t("passwordPlaceholder")} type="password" />
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </>
  );
});
export default Account;
