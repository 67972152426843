import dayjs, { Dayjs } from 'dayjs';

const parseTime = (timeStr: string) => {
    if(timeStr){
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        return dayjs.duration({ hours, minutes, seconds });
    }else{
        return 0;
    }

};

const addAllTimes = (timesArray: any[]) => {
    return timesArray?.reduce((total, current) => {
        const currentDuration = parseTime(current.totalTime);
        return total.add(currentDuration);
    }, dayjs.duration(0));
};
export const sumAllTime = (items: any[]) => {
    const totalDuration = addAllTimes(items);
    const time = totalDuration;
    const hours = time?.asHours().toFixed(2);
    return hours;
}