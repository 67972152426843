import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { Dayjs } from 'dayjs';
import { Employee, Skill } from "../api/EmployeesApiClient";
import { Roles } from "./EmployeesStore";

export type NewProject = {
  title: string,
  description: string,
  startDate: Dayjs,
  isActive: boolean,
  isPublish: boolean,
}
export type AssignUserProject = {
  id?: string,
  userId: string,
  projectId: string,
  roleId: string,
}

export type UserProject = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  photoId: string,
  role: {
    id: string,
    title: string
  }
}

export interface ProjectSkill {
  skillId: string;
  projectId: number;
}

export type Project = {
  id: string,
  title: string,
  description: string,
  startDate: Dayjs,
  isActive: boolean,
  isPublish: boolean,
  companyId: number,
  projectResponsible: Employee,
  userProjects: UserProject[],
  members: UserProject[],
  developers: UserProject[],
  projectSkills: Skill[],
  [x:string]: any
}

class ProjectsStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  project!: Project;
  projects!: Project[];

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.projects = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  createNew = async (project: NewProject) => {
    this.isLoading = true;

    const response = await this.ApiStore.ProjectsApiClient.addProject(project);
    this.isLoading = false;

    return response;
  }

  AssignNewUser = async (project: NewProject) => {
    this.isLoading = true;

    const response = await this.ApiStore.ProjectsApiClient.addProject(project);
    this.isLoading = false;

    return response;
  }

  getAll = async () => {
    this.isLoading = true;
    const projects = await this.ApiStore.ProjectsApiClient.getProjects().catch(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    if (projects) {
      this.projects = projects;
    }
    return projects;
  }

  getById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectsApiClient.getProjectsById(id);
    this.isLoading = false;
    if (project) {
      this.project = project;
    }
    return project;
  }

  updateCurrentProject = async () => {
    const project = await this.ApiStore.ProjectsApiClient.getProjectsById(this.project.id);
    if (project) {
      this.project = project;
    }
    return project;
  }

  editProject = async (newProject: Project) => {
    this.isLoading = true;
    const result = await this.ApiStore.ProjectsApiClient.editProjects(newProject);
    this.isLoading = false;
    if (result) {
      this.project = result;
    }
    return result;
  }

  deleteById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectsApiClient.deleteProjectsById(id).finally(() => {
      this.isLoading = false;
    });
    if (project) {
      this.project = project;
    }
    return project;
  }

  public assignNewUser = async (item: AssignUserProject): Promise<any> => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectsApiClient.assignNewUser(item).finally(async () => {
      await this.updateCurrentProject();
      this.isLoading = false;
    });

    return project;
  }
  public editAssignUser = async (item: AssignUserProject): Promise<any> => {
    const project = await this.ApiStore.ProjectsApiClient.editAssignUser(item).finally(async () => {
      await this.updateCurrentProject();
      this.isLoading = false;
    });

    return project;
  }
  public deleteAssignUser = async (userId: string, projectId: string): Promise<any> => {
    const project = await this.ApiStore.ProjectsApiClient.deleteAssignUser(userId, projectId).finally(async () => {
      await this.updateCurrentProject();
      this.isLoading = false;
    });
    return project;
  }

}
export default ProjectsStore;
