import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { ProjectsTime } from "./ProjectsTime";
import TimeReportsByDayProjects from "./TimeReportsByDayProjects";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

interface ProjectsTimeReportsProps {
  setLogUserId: (id: string) => void;
  setLogProjectId: (id: string) => void;
  showModalAdd: () => void;
  showModalEdit: () => void;
  deleteTime: (id: string) => Promise<void>;
  setIsChangeUser: (status: boolean) => void;
  setEditTimeReport: (report: any) => void;
  timeReports: any[];
  startDate: Dayjs;
  endDate: Dayjs;
  setStartDate: (date: Dayjs) => void;
  setEndDate: (date: Dayjs) => void;
  activeProjectTabKey: string;
  onProjectTabChange: (key: string) => void;
}

const ProjectsTimeReports = observer(({
  setLogUserId,
  setLogProjectId,
  showModalAdd,
  showModalEdit,
  deleteTime,
  setIsChangeUser,
  setEditTimeReport,
  timeReports,
  startDate, 
  endDate,
  setStartDate,
  setEndDate,
  activeProjectTabKey,
  onProjectTabChange
}: ProjectsTimeReportsProps) => {
  const { timeReportStore } = useStore(); 
  const [activeTabKey, setActiveTabKey] = useState('byProjects');
  const { t } = useTranslation();

  return (
    <Tabs activeKey={ activeProjectTabKey} onChange={onProjectTabChange} >
      <TabPane tab={t('TimeReports.ByProjects')} key="byProjects">
        <ProjectsTime
          setLogUserId={setLogUserId}
          setLogProjectId={setLogProjectId}
          showModalAdd={showModalAdd}
          showModalEdit={showModalEdit}
          deleteTime={deleteTime}
          setIsChangeUser={setIsChangeUser}
          setEditTimeReport={setEditTimeReport}
          timeReports={timeReports}
        />
      </TabPane>

      
      <TabPane tab={t('TimeReports.ByDay')} key="byDay">
        <div style={{ 
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)", 
          paddingLeft: "0px",
          paddingRight: "0px",
          boxSizing: "border-box",
        }}>
            <TimeReportsByDayProjects
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
        </div>
      </TabPane>

    </Tabs>
  );
});

export default ProjectsTimeReports;
