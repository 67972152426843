import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import dayjs, { Dayjs } from 'dayjs';
import { Employee } from "../api/EmployeesApiClient";
import { Project } from "./ProjectsStore";
import { UploadFile } from "antd";

export type ProjectDocument = {
  id?: string,
  title: string,
  content: string,
  createdAt?: Dayjs,
  updatedAt?: Dayjs,
  projectId?: string,
  creatorId?: string,
  updaterId?: string,
}

type AttachmentsDictionary = {
  [key: string]: string[];
};

class ProjectDocumentationStore {
  isLoading: boolean = true;
  isLoadingAttachments: boolean = true;
  ApiStore!: ApiStore;
  document!: ProjectDocument | null;
  documents!: ProjectDocument[];
  attachments: AttachmentsDictionary;
  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.document = null;
    this.documents = [];
    this.attachments = {};
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  createNew = async (document: ProjectDocument) => {
    this.isLoading = true;
    const response = await this.ApiStore.ProjectDocumentsApiClient.add(document);
    this.isLoading = false;
    return response;
  }

  edit = async (document: ProjectDocument) => {
    this.isLoading = true;
    const response = await this.ApiStore.ProjectDocumentsApiClient.edit(document);
    this.isLoading = false;
    return response;
  }

  setDocument = async (document: ProjectDocument | null) => {
    this.document = document;
  }

  getAll = async (): Promise<ProjectDocument[]> => {
    this.isLoading = true;
    const tasks = await this.ApiStore.ProjectDocumentsApiClient.getAll().finally(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    if (tasks) {
      this.documents = tasks.map((el: { createdAt: string | number | dayjs.Dayjs | Date | null | undefined; updatedAt: string | number | dayjs.Dayjs | Date | null | undefined; }) => {
        return {
          ...el,
          createdAt: dayjs(el.createdAt),
          updatedAt: dayjs(el.updatedAt)
        }
      });
    }
    return tasks;
  }

  getById = async (id: string): Promise<ProjectDocument> => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectDocumentsApiClient.getById(id).finally(() => {
      this.isLoading = false;
    });
    if (project) {
      this.document = project;
    }
    return project;
  }

  getByProjectId = async (id: string): Promise<ProjectDocument[]> => {
    this.isLoading = true;
    const tasks = await this.ApiStore.ProjectDocumentsApiClient.getByProjectId(id).finally(() => {
      this.isLoading = false;
    });
    if (tasks && tasks.length) {
      this.documents = tasks.map((el: {
        deadLine: string; startDate: string;
      }) => {
        return {
          ...el,
          startDate: dayjs(el.startDate),
          dueDate: dayjs(el.deadLine)
        }
      });
    }
    return tasks;
  }

  deleteById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectDocumentsApiClient.deleteById(id).finally(() => {
      this.document = null;
      this.isLoading = false;
    });
    if (project) {
      this.document = project;
    }
    return project;
  }

  uploadFile = async (file: UploadFile, id: string) => {
    this.isLoadingAttachments = true;
    const result = await this.ApiStore.ProjectDocumentsApiClient.uploadDocumentationAttachment(file, id).finally(() => {
      this.isLoadingAttachments = false;
    });
    return result;
  }

  downloadAttachmentsByDocumentId = async (id: string) => {
    this.isLoadingAttachments = true;
    await this.ApiStore.ProjectDocumentsApiClient.GetAttachmentsByDocumentation(id).then(res => {
      this.attachments[id] = res;
    }).finally(() => {
      this.isLoadingAttachments = false;
    });
    return this.attachments[id];
  }

  getFileList = async (id: string) => {
    if (this.attachments[id]) {
      return this.attachments[id];
    } else {
      return this.downloadAttachmentsByDocumentId(id);
    }
  }
  deleteAttachmentByPublicId = async (publicId: string) => {
    await this.ApiStore.ProjectDocumentsApiClient.DeleteAttachmentByPublicId(publicId)
  }
}
export default ProjectDocumentationStore;
