import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { Employee } from "./EmployeesApiClient";

export type CalendarEvent = {
  id: string;
  type: string;
  startDate: Dayjs;
  endDate: Dayjs;
  userId: string;
}

export default class EventsApiClient extends SkTechApiClient {

  public addEvent = async (value: any) => {
    return await this.Post(`/api/EventCalendar`, value)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public getAll = async (): Promise<CalendarEvent[]> => {
    return await this.Get(`/api/EventCalendar`)

      .then((response) => {
        if (response.status === 200) {
          return response.data.map((el: {
            startDate: string;
            endDate: string;
          }) => {
            return {
              ...el,
              startDate: el.startDate ? dayjs(el.startDate) : null,
              endDate: el.endDate ? dayjs(el.endDate) : null,
            }
          });
        }
        return [];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  }

  public deleteEvent = async (id: string) => {
    return await this.Delete(`/api/EventCalendar?id=${id}`)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }
}
