import { Link, useNavigate } from "react-router-dom"
import Menu from "../Menu"
import OnePageNav from "../OnePageNav"
import { Drawer, Badge, Dropdown, MenuProps } from "antd";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Select, Image, Spin, Table, TableProps, Tag, Typography, message } from "antd";
import { useStore } from "../../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import LanguageSwitcher from "../../elements/LanguageSwitcher";
import { Roles } from "../../../stores/EmployeesStore";
import { startTransition, useEffect, useState } from "react";
import EmployeesStore from "../../../stores/EmployeesStore";
import { BellOutlined, CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useRef } from "react";

const Header1 = observer(({
    scroll,
    isOffCanvas,
    handleOffCanvas,
    isSearch,
    handleSearch,
    onePageNav
}: any) => {
    const { applicationStore, employeesStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [visibleModal, setVisibleModal] = useState("");
    const [modalData, setModalData] = useState({ oldData: "", newData: "" });
    const [notificationDrawerVisible, setNotificationDrawerVisible] = useState(false);
    const [taskDrawerVisible, setTaskDrawerVisible] = useState(false)

    useEffect(() => {
        if (applicationStore.isAuthenticated) {
            applicationStore.checkCompanyStatus();
        }
    }, [applicationStore.company?.IsFrozen]);

    const handleModalOk = async () => {
        message.open({
            type: 'loading',
            content: 'Changing...',
            key: 'changing'
        });
        if (modalData.newData.length === 0) {
            message.destroy("changing");
            message.error("Saving failed! Fill all necessary fields");
        }
        switch (visibleModal) {
            case "email":
                const emailResult = await employeesStore.ApiStore.EmployeesApiClient.changeEmail(modalData.newData);
                message.destroy("changing");
                if (emailResult) {
                    message.success("Email was changed");
                    setVisibleModal("");
                }
                else {
                    message.error("Email was not changed");
                }
                break;

            case "password":
                if (modalData.oldData.length === 0) {
                    message.destroy("changing");
                    message.error("Saving failed! Fill all necessary fields");
                }
                const passwordResult = await employeesStore.ApiStore.EmployeesApiClient.changePassword(modalData.oldData, modalData.newData);
                message.destroy("changing");
                if (passwordResult) {
                    message.success("Password was changed");
                    setVisibleModal("");
                }
                else {
                    message.error("Password was not changed");
                }
                break;
            default:
                break;
        }
        setModalData({ oldData: "", newData: "" });
    }

    const items: MenuProps["items"] = [
        {
            onClick: () => {
                startTransition(() => {
                    navigate(`/${applicationStore.company?.Tenaut}/employers/${applicationStore.user.id}`);
                });
            },
            label: <span style={{ padding: "0px 16px" }}>{t("Menu.MyAccount")}</span>,

            key: "1",
        },
        ...(applicationStore.user?.roleId === Roles.Owner
            ? [{
                onClick: () => {
                    startTransition(() => {
                        navigate(`/${applicationStore.company?.Tenaut}/payment-and-tariffs`);
                    });
                },
                label: <span style={{ padding: "0px 16px" }}>{t("PaymentAndTariffs")}</span>,
                key: "payment-and-tariffs",
            }]
            : []),
        {
            onClick: () => {
                startTransition(() => {
                    setModalData({ oldData: "", newData: "" });
                    setVisibleModal("email");
                    console.log('Email');
                });
            },
            label: <span style={{ padding: "0px 16px" }}>{t("ChangeEmail")}</span>,
            key: "Email",
        },
        {
            onClick: () => {
                startTransition(() => {
                    setModalData({ oldData: "", newData: "" });
                    setVisibleModal("password");
                    console.log('Password');
                });
            },
            label: <span style={{ padding: "0px 16px" }}>{t("ChangePassword")}</span>,
            key: "Password",
        },
        {
            onClick: () => {
                startTransition(() => {
                    applicationStore.logout();
                    navigate('/');
                });

            },
            label: <span style={{ padding: "0px 16px" }}>{t("LogOut")}</span>,
            key: "LogOut",
        },
    ];

    const notificationsEndRef = useRef<HTMLDivElement>(null);
    const tasksEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "auto" });
        }
    };

    const showNotificationDrawer = () => {
        setNotificationDrawerVisible(true);
        setTimeout(() => scrollToBottom(notificationsEndRef), 100);
    };

    const closeNotificationDrawer = () => {
        applicationStore.notifications = applicationStore.notifications.map(notification => {
            if (!notification.isRead) {
                applicationStore.markNotificationAsRead(parseInt(notification.key));
                return { ...notification, isRead: true };
            }
            return notification;
        });
        setNotificationDrawerVisible(false);
    };

    const showTaskDrawer = () => {
        setTaskDrawerVisible(true);
        setTimeout(() => scrollToBottom(tasksEndRef), 100);
    };
    const closeTaskDrawer = () => setTaskDrawerVisible(false);

    return (
        <>

            <header>
                <Modal cancelText={`${t("Cancel")}`} onOk={handleModalOk} open={visibleModal !== ""} onCancel={() => setVisibleModal("")}>
                    {visibleModal === "email" && (
                        <div>
                            <div>{"\u200B"}</div>
                            <Input placeholder="new email" value={modalData.newData} onChange={
                                (e) => setModalData({ ...modalData, newData: e.target.value })
                            } />
                        </div>
                    )}
                    {visibleModal === "password" && (
                        <Flex style={{ lineHeight: "1.6", flexDirection: "column" }}>
                            <div>{"\u200B"}</div>
                            <Input.Password placeholder="old password" value={modalData.oldData} onChange={
                                (e) => setModalData({ ...modalData, oldData: e.target.value })
                            } />
                            <div>{"\u200f"}</div>
                            <Input.Password placeholder="new password" type="password" value={modalData.newData} onChange={
                                (e) => setModalData({ ...modalData, newData: e.target.value })
                            } />
                        </Flex>
                    )}
                </Modal>
                <div id="header-sticky" className={`header-1 ${scroll ? "sticky" : ""}`}>
                    <div className="container-fluid">
                        <div className="mega-menu-wrapper">
                            <div className="header-main style-2">
                                <div className="header-left">
                                    <div className="logo">
                                        <Link to="/" className="header-logo">
                                            {applicationStore.isAuthenticated && applicationStore.company?.LogoId ?
                                                <img src={`https://res.cloudinary.com/dnl3x07wo/image/upload/${applicationStore.company?.LogoId}`} alt="logo-img" height={60} />
                                                :
                                                <img src="/assets/logo_black.png" alt="logo-img" height={60} />
                                            }
                                        </Link>
                                        {applicationStore.company?.LogoId ? <></> :
                                            <Link to="/">
                                                <img src="/assets/logo_title.png" alt="logo-image" height={40} />
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="header-right d-flex justify-content-end align-items-center">
                                    <div className="mean__menu-wrapper">
                                        <div className="main-menu">
                                            <nav id="mobile-menu">
                                                {onePageNav ?
                                                    <OnePageNav />
                                                    :
                                                    <Menu />
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                    {/* <a onClick={handleSearch} className="search-trigger search-icon"><BellOutlined /></a> */}
                                    <LanguageSwitcher />
                                    {applicationStore.isAuthenticated ?
                                        <>
                                            <a onClick={showTaskDrawer} style={{ cursor: 'pointer' }}>
                                                <Badge count={applicationStore.tasks?.filter(task => task.status === 'Active').length || 0}>
                                                    <img src="/assets/img/taskIcon.png" alt="Task Icon" style={{ width: 32, height: 32 }} />
                                                </Badge>
                                            </a>
                                            <Drawer
                                                title={t("Tasks")}
                                                placement="right"
                                                onClose={closeTaskDrawer}
                                                open={taskDrawerVisible}
                                            >
                                                {applicationStore.tasks && applicationStore.tasks.length > 0 ? (
                                                    <ul style={{ padding: 0, listStyleType: 'none' }}>
                                                        {applicationStore.tasks.map((task, index) => {
                                                            if (task && 'label' in task && 'onClick' in task) {
                                                                let backgroundColor = '#ffffff';
                                                                let textColor = '#000000';

                                                                if (task.status === 'Approved') {
                                                                    backgroundColor = '#e6ffed';
                                                                    textColor = '#389e0d';
                                                                } else if (task.status === 'Rejected') {
                                                                    backgroundColor = '#fff1f0';
                                                                    textColor = '#cf1322';
                                                                } else if (task.status === 'Active') {
                                                                    backgroundColor = '#ffffff';
                                                                    textColor = '#000000';
                                                                }

                                                                const label = task.translationKey
                                                                    ? t(task.translationKey, {
                                                                        ...task.translationParams,
                                                                        missingFields: task.translationParams?.missingFields
                                                                            ?.map((fieldKey: string) => t(fieldKey))
                                                                            .join(", "),
                                                                    })
                                                                    : task.label;



                                                                return (
                                                                    <li
                                                                        key={index}
                                                                        onClick={() => {
                                                                            const tenant = applicationStore.company?.Tenaut;
                                                                            const link = `/${tenant}${task.linkTask}`;
                                                                            window.location.href = link;
                                                                        }}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '10px 15px',
                                                                            borderBottom: '1px solid #f0f0f0',
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            backgroundColor: backgroundColor,
                                                                            color: textColor,
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <span style={{ marginRight: '10px', flex: 1 }}>{label}</span>
                                                                        </div>

                                                                        <span style={{ color: '#888', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                                                            {task.date}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <div ref={tasksEndRef} />
                                                    </ul>
                                                ) : (
                                                    <p>{t("NoTasksAvailable")}</p>
                                                )}
                                            </Drawer>

                                            <a onClick={showNotificationDrawer} style={{ cursor: 'pointer' }}>
                                                <Badge count={applicationStore.notifications?.filter(notification => !notification.isRead).length || 0}>
                                                    <BellOutlined style={{ fontSize: 20 }} />
                                                </Badge>
                                            </a>
                                            <Drawer
                                                title={t("Notifications")}
                                                placement="right"
                                                onClose={closeNotificationDrawer}
                                                open={notificationDrawerVisible}
                                            >
                                                {applicationStore.notifications && applicationStore.notifications.length > 0 ? (
                                                    <ul style={{ padding: 0, listStyleType: 'none' }}>
                                                        {applicationStore.notifications.map((notification, index) => {
                                                            if (notification && 'label' in notification && 'onClick' in notification) {

                                                                const label = notification.translationKey
                                                                    ? t(notification.translationKey, notification.translationParams)
                                                                    : notification.label;

                                                                return (
                                                                    <li
                                                                        key={index}
                                                                        onClick={() => {
                                                                            if (!notification.isRead) {
                                                                                applicationStore.markNotificationAsRead(parseInt(notification.key));
                                                                                notification.isRead = true;
                                                                                applicationStore.notifications = [...applicationStore.notifications];
                                                                            }
                                                                            const tenant = applicationStore.company?.Tenaut;
                                                                            const link = `/${tenant}${notification.link}`;
                                                                            window.location.href = link;
                                                                        }}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            padding: '10px 15px',
                                                                            borderBottom: '1px solid #f0f0f0',
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            backgroundColor: notification.isRead ? '#ffffff' : '#ffffff',
                                                                            color: notification.isRead ? '#888888' : '#000000'
                                                                        }}
                                                                    >
                                                                        <span style={{ marginRight: '10px', flex: 1 }}>{label}</span>
                                                                        <span style={{ color: '#888', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                                                            {notification.date}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <div ref={notificationsEndRef} />
                                                    </ul>
                                                ) : (
                                                    <p>No notifications available</p>
                                                )}
                                            </Drawer>


                                            <Dropdown
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                                arrow={{ pointAtCenter: true }}
                                                menu={{ items: items }}
                                            >
                                                <Image
                                                    preview={false}
                                                    width={50}
                                                    height={50}
                                                    style={{ borderRadius: "50%", objectFit: "cover", minWidth: 50 }}
                                                    src={applicationStore.user?.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${applicationStore.user?.photoId}` :
                                                        `https://res.cloudinary.com/dnl3x07wo/image/upload/z03w7hlsy8hcoic9dlcn`
                                                    }
                                                />
                                            </Dropdown></> : <></>}
                                    <div className="header__hamburger d-xl-block my-auto">
                                        <div className="sidebar__toggle" onClick={handleOffCanvas}>
                                            <i className="fas fa-bars" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
});

export default Header1;
