import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import i18n from "../../i18n";
import AboutRU from "./AboutRU";
import AboutUK from "./AboutUK";
import AboutEN from "./AboutEN";
import { useTranslation } from "react-i18next";

const About = observer(() => {
    const [language, setLanguage] = useState(i18n.language);
    const { t } = useTranslation();

    useEffect(() => {
        const handleLanguageChange = () => {
            setLanguage(i18n.language);
        };

        i18n.on("languageChanged", handleLanguageChange);
        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("About")}>
        <div style={{ marginTop: 20 }}>
            {language === "ru" ? <AboutRU /> :
             language === "uk" ? <AboutUK /> :
             language === "en" ? <AboutEN /> :
             <AboutRU />}
        </div>
    </Layout>
    );
});

export default About;
