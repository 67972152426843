import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet";

const AboutUK = observer(() => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 20, marginBottom: 30 }}>
                <h2>Про сервіс SkTech ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС"</h2>
                <p>
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> — це сучасна сервіс компанії ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС", яка спеціалізується на наданні рішень для ефективного управління проектами,
                    часом співробітників, документацією та багатьма іншими аспектами бізнес-процесів. Ми прагнемо забезпечити наших клієнтів інструментами,
                    які дозволяють оптимізувати робочі процеси, підвищити продуктивність та знизити витрати.
                </p>

                <h3>Наші послуги:</h3>
                <ul>
                    <li>
                        <strong>Управління проектами:</strong> Допомагаємо впорядкувати всі етапи проекту — від планування до реалізації.
                        Надаємо зручні інструменти для контролю строків, ресурсів і командної роботи.
                    </li>
                    <li>
                        <strong>Контроль часу працівників:</strong> Забезпечуємо рішення для відстеження робочого часу, планування задач і аналізу продуктивності,
                        що дозволяє досягати поставлених цілей швидше.
                    </li>
                    <li>
                        <strong>Управління документацією:</strong> Ефективна система зберігання, сортування та доступу до документації проектів для швидкої роботи всієї команди.
                    </li>
                    <li>
                        <strong>Індивідуальні рішення для бізнесу:</strong> Ми розробляємо та впроваджуємо індивідуальні рішення для кожного клієнта, враховуючи специфіку галузі та потреби компанії.
                    </li>
                </ul>

                <h3>Чому обирають SkTech?</h3>
                <ul>
                    <li><strong>Інноваційні підходи:</strong> Ми використовуємо найсучасніші технології, щоб забезпечити ефективність і надійність наших рішень.</li>
                    <li><strong>Гнучкість:</strong> Наші сервіси адаптуються до різних розмірів бізнесу — від стартапів до великих корпорацій.</li>
                    <li><strong>Команда професіоналів:</strong> У нас працюють кваліфіковані фахівці з великим досвідом у галузі управління проектами.</li>
                    <li><strong>Підтримка клієнтів:</strong> Ми надаємо постійну технічну та консультаційну підтримку, щоб ви могли сконцентруватися на досягненні ваших бізнес-цілей.</li>
                </ul>

                <h3>Наші послуги:</h3>
                <ul style={{ marginLeft: 20, gap: 50, display: "flex" }}>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Місячна підписка:</h4>
                        <label>термін дії 1 місяць</label>
                        <ul >
                            <li>
                                <strong>-Управління проєктами</strong>
                            </li>
                            <li>
                                <strong>-Відстеження робочого часу</strong>
                            </li>
                            <li>
                                <strong>-Управління документацією</strong>
                            </li>
                        </ul>
                        <p>Рішення для обліку робочих годин, планування завдань і аналізу продуктивності, що допомагають досягати цілей ефективніше.</p>
                        <p>Надійна система зберігання, сортування та доступу до проєктної документації для швидкої та ефективної командної роботи.</p>
                        <p style={{ fontSize: 20 }}><strong>Тариф:</strong> 415 грн</p>
                    </li>
                    <li style={{ marginLeft: 20 }}>
                        <h4>Річна підписка:</h4>
                        <label>термін дії 12 місяців</label>
                        <ul >
                            <li>
                                <strong>-Управління проєктами</strong>
                            </li>
                            <li>
                                <strong>-Відстеження робочого часу</strong>
                            </li>
                            <li>
                                <strong>-Управління документацією</strong>
                            </li>
                        </ul>
                        <p>Рішення для обліку робочих годин, планування завдань і аналізу продуктивності, що допомагають досягати цілей ефективніше.</p>
                        <p>Надійна система зберігання, сортування та доступу до проєктної документації для швидкої та ефективної командної роботи.</p>
                        <p style={{ fontSize: 20 }}><strong>Тариф:</strong> 4150 грн</p>
                    </li>
                </ul>
                <p>
                    <span style={{
                        color: "#007bff",
                        fontWeight: "bold"
                    }}>SkTech</span> — ваш надійний партнер у світі цифрової трансформації бізнесу. Ми віримо, що ефективне управління — це ключ до успіху,
                    і допоможемо вам досягти його.
                </p>
                <p><strong>Давайте створювати майбутнє разом!</strong></p>
                <p>Зв’яжіться з нами вже сьогодні, щоб дізнатися більше про наші послуги.</p>
            </div>
        </div>
    )
})
export default AboutUK;