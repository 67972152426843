import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "./base/SkTechApiClient";

export type Notification = {
  id: number;
  userId: string;
  translationKey?: string;
  translationParams?: Record<string, any>;
  text: string;
  type: string;
  date: string;
  isRead: boolean;
  link: string;
};

export default class NotificationsApiClient extends SkTechApiClient {

  public getNotificationsByUserId = async (userId: string): Promise<Notification[]> => {
    return await this.Get(`/api/Notification/GetNotificationsByUserId/${userId}`)
      .then((response: AxiosResponse<Notification[]>) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch((error: AxiosError) => {
        console.error("Failed to fetch notifications", error);
        return [];
      });
  }

  
  public addNotification = async (notification: Omit<Notification, 'id'>): Promise<boolean> => {
    return await this.Post(`/api/Notification/AddNotification`, notification)
      .then((response: AxiosResponse) => {
        return response.status === 200;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to add notification", error);
        return false;
      });
  }

  public markNotificationAsRead = async (notificationId: number): Promise<boolean> => {
    return await this.Patch(`/api/Notification/MarkAsRead/${notificationId}`, {})
      .then((response: AxiosResponse) => {
        return response.status === 200;
      })
      .catch((error: AxiosError) => {
        console.error("Failed to mark notification as read", error);
        return false;
      });
  }

}
