
'use client'
import "../../public/assets/css/bootstrap.min.css"
import "../../public/assets/css/all.min.css"
import "../../public/assets/css/animate.css"
import "../../public/assets/css/magnific-popup.css"
import "../../public/assets/css/meanmenu.css"
import "../../public/assets/css/swiper-bundle.min.css"
import "../../public/assets/css/nice-select.css"
import "../../public/assets/css/main.css"
import 'react-modal-video/css/modal-video.min.css'

import { Suspense, useEffect, useState } from "react"
import MouseCursor from "./MouseCursor"
import Offcanvas from "./Offcanvas"
import Header1 from "./header/Header1"
import Search from "./Search"
import Breadcrumb from "./Breadcrumb"

import BackToTop from "../elements/BackToTop"
import { Spin } from "antd"
import { useStore } from "../../stores/root-store-context"
import { observer } from "mobx-react-lite"
import FreezeWarningModal from './FreezeWarningModal';
import Footer2 from "./footer/Footer2"


const Layout = observer(({ headerStyle, footerStyle, onePageNav, breadcrumbTitle, children }: any) => {
    const { applicationStore } = useStore();

    const [scroll, setScroll] = useState(0)
    const [isOffCanvas, setOffCanvas] = useState(false)
    const handleOffCanvas = () => setOffCanvas(!isOffCanvas)

    const [isSearch, setSearch] = useState(false)
    const handleSearch = () => setSearch(!isSearch)

    return (
        <>
            <Spin spinning={applicationStore.isLoading} className="layout">
                <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <FreezeWarningModal />
                    <div>
                        <MouseCursor />
                        <Offcanvas isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} />

                        {/* {!headerStyle && <Header2 scroll={scroll} onePageNav={onePageNav} isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} isSearch={isSearch} handleSearch={handleSearch} isMobileMenu={undefined} handleMobileMenu={undefined} />} */}
                        {headerStyle == 1 ?
                            <Suspense fallback={<div>Loading...</div>}>
                                <Header1 scroll={scroll} onePageNav={onePageNav} isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} isSearch={isSearch} handleSearch={handleSearch} />
                            </Suspense> : null}
                        {/* {headerStyle == 2 ? <Header2 scroll={scroll} onePageNav={onePageNav} isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} isSearch={isSearch} handleSearch={handleSearch} isMobileMenu={undefined} handleMobileMenu={undefined} /> : null}
                        {headerStyle == 3 ? <Header3 scroll={scroll} onePageNav={onePageNav} isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} isSearch={isSearch} handleSearch={handleSearch} isMobileMenu={undefined} handleMobileMenu={undefined} /> : null}
                        {headerStyle == 4 ? <Header4 scroll={scroll} onePageNav={onePageNav} isOffCanvas={isOffCanvas} handleOffCanvas={handleOffCanvas} isSearch={isSearch} handleSearch={handleSearch} isMobileMenu={undefined} handleMobileMenu={undefined} /> : null} */}
                        <Search isSearch={isSearch} handleSearch={handleSearch} />

                        {breadcrumbTitle && <Breadcrumb breadcrumbTitle={breadcrumbTitle} />}

                        {children}
                    </div>
                    {/* {!footerStyle && < Footer1 />}
                    {footerStyle == 1 ? < Footer1 /> : null} */}
                    < Footer2 /> 
                    {/* {footerStyle == 2 ? < Footer2 /> : null}
                    {footerStyle == 3 ? < Footer3 /> : null}
                    {footerStyle == 4 ? < Footer4 /> : null} */}
                    <BackToTop />
                </div>
            </Spin>
        </>
    )
})
export default Layout;
