import ApiStore from "./ApiStore";
import ApplicationStore from "./ApplicationStore";
import EducationStore from "./EducationStore";
import EmployeesStore from "./EmployeesStore";
import EventStore from "./EventStore";
import ProjectDocumentationStore from "./ProjectDocumentationStore";
import ProjectsStore from "./ProjectsStore";
import ProjectTaskStore from "./ProjectTasksStore";
import TaskStore from "./TaskStore";
import SalaryStore from "./SalaryStore";
import TimeReportStore from "./TimeReportStore";
import TariffPlansStore from "./TariffPlansStore";
import PaymentStore from "./PaymentStore";

class RootStore {
  apiStore: ApiStore;
  applicationStore: ApplicationStore;
  employeesStore: EmployeesStore;
  projectsStore: ProjectsStore;
  projectTaskStore:ProjectTaskStore;
  taskStore:TaskStore;
  projectDocumentationStore:ProjectDocumentationStore;
  timeReportStore:TimeReportStore;
  eventStore:EventStore;
  educationStore:EducationStore;
  salaryStore: SalaryStore;
  tariffPlansStore: TariffPlansStore;
  paymentStore: PaymentStore;

  constructor() {
    this.apiStore = new ApiStore();
    this.applicationStore = new ApplicationStore(this.apiStore);
    this.employeesStore = new EmployeesStore(this.apiStore, this.applicationStore);
    this.projectsStore = new ProjectsStore(this.apiStore);
    this.projectTaskStore = new ProjectTaskStore(this.apiStore);
    this.projectDocumentationStore = new ProjectDocumentationStore(
      this.apiStore
    );
    this.timeReportStore = new TimeReportStore(this.apiStore);
    this.eventStore = new EventStore(this.apiStore);
    this.taskStore = new TaskStore(this.apiStore);
    this.educationStore = new EducationStore(this.apiStore);
    this.salaryStore = new SalaryStore(this.apiStore);
    this.tariffPlansStore = new TariffPlansStore(this.apiStore);
    this.paymentStore = new PaymentStore(this.apiStore);
  }

  clearLocalStorages = () => {
    // this.applicationStore.clearDropdownsLocalStorages();
  };
}

export default RootStore;
