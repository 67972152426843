export const BoolSort = (a: boolean, b: boolean): number => {
  const aBool: boolean = a ? a as boolean : false
  const bBool: boolean = b ? b as boolean : false
  return (aBool === bBool) ? 0 : aBool ? -1 : 1
}

export const TextSort = (a: string, b: string): number => {
  const aStr: string = a ? a.toString() as string : ""
  const bStr: string = b ? b.toString() as string : ""
  return aStr.localeCompare(bStr)
}

export const DateSort = (a: any, b: any): number => {
  const aStr: string = a ? a as string : ""
  const bStr: string = b ? b as string : ""
  let dateA = new Date(aStr).getTime();
  let dateB = new Date(bStr).getTime();
  if (isNaN(dateA)) {
    dateA = 0;
  }
  if (isNaN(dateB)) {
    dateB = 0;
  }
  return dateA - dateB;
}