import React from "react";
import { Tag, Divider, message } from "antd";
import {
  CloseOutlined,
  BookOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from 'dayjs';

interface EducationItem {
  University: string;
  Degree: string;
  City: string;
  Country: string;
  StartDate: string;
  EndDate: string;
  Profession: string;
}

interface EducationBlockProps {
  educationList: EducationItem[];
  removeEducation: (item: EducationItem) => Promise<void>;
  canRemove: boolean;
}

const EducationBlock: React.FC<EducationBlockProps> = ({
  educationList,
  removeEducation,
  canRemove,
}) => {
  const { t } = useTranslation();

  function ParseToJson(str: string): any[] {
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  }
  
  if (educationList.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        padding: "20px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        // maxWidth: "600px",
        width: "100%",
        marginBottom: "16px",
      }}
    >
      <h3 style={{ marginBottom: "20px", color: "#333" }}>
        {t("user.Education")}
      </h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {educationList.map((item, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
            }}
          >
            {canRemove && (
              <CloseOutlined
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  cursor: "pointer",
                  color: "#ff4d4f",
                  fontSize: "16px",
                }}
                onClick={async () => {
                  try {
                    await removeEducation(item);
                  } catch (error) {
                    message.error("Failed to remove education");
                  }
                }}
              />
            )}
            <h3
              style={{
                marginBottom: "8px",
                fontWeight: "bold",
                color: "#2a2a2a",
                fontSize: "16px",
              }}
            >
              {item.University}
            </h3>
            <div
              style={{
                marginBottom: "8px",
                color: "#4a4a4a",
                fontSize: "14px",
              }}
            >
              <BookOutlined style={{ marginRight: "8px", color: "#1890ff" }} />
              {item.Degree ? ParseToJson(item.Degree).join(', ') : ''}
            </div>
            <div
              style={{
                marginBottom: "8px",
                color: "#6c6c6c",
                fontSize: "14px",
              }}
            >
              <EnvironmentOutlined
                style={{ marginRight: "8px", color: "#52c41a" }}
              />
              {item.City}, {item.Country}
            </div>
            <div
              style={{
                color: "#8c8c8c",
                fontSize: "14px",
              }}
            >
              <CalendarOutlined
                style={{ marginRight: "8px", color: "#faad14" }}
              />
              {item.StartDate ? dayjs(item.StartDate).format("YYYY-MM-DD") : ""} - {item.EndDate ? dayjs(item.EndDate).format("YYYY-MM-DD") : ""}
            </div>
            {item.Profession && (
              <>
                <Divider style={{ margin: "12px 0", borderColor: "#e8e8e8" }} />
                <div
                  style={{
                    backgroundColor: "#e6f7ff",
                    color: "#1890ff",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {item.Profession}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationBlock;
