import { observer } from "mobx-react-lite";
import { Button, Badge, Space, Flex, message, Popconfirm, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useStore} from "../../stores/root-store-context";
import { Task } from "../../api/TasksApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const UserTaskPage = observer(() => {
  const { taskStore, applicationStore } = useStore();
  
  const [disabledTasks, setDisabledTasks] = useState<{ [key: number]: boolean }>({});
  const { t } = useTranslation();

  useEffect(() => {
    taskStore.fetchTasksByUserId(applicationStore.user.id);
  }, []);

  const approveTask = async (taskId: number) => {
    setDisabledTasks(prevState => ({ ...prevState, [taskId]: true }));
    await taskStore.approveTask(taskId);
    message.success(t("Task approved"));
    await taskStore.fetchTasksByUserId(applicationStore.user.id);
    applicationStore.initUserTasks();
    setDisabledTasks(prevState => ({ ...prevState, [taskId]: false }));
  };

  const rejectTask = async (taskId: number) => {
    setDisabledTasks(prevState => ({ ...prevState, [taskId]: true }));
    await taskStore.rejectTask(taskId);
    message.success(t("Task rejected"));
    await taskStore.fetchTasksByUserId(applicationStore.user.id);
    applicationStore.initUserTasks();
    setDisabledTasks(prevState => ({ ...prevState, [taskId]: false }));
  };

  const columns = [
    {
      title: t("Task Title"),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t("Start Date"),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: t("End Date"),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: t("Status"),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Badge
          status={status === 'Approved' ? 'success' : status === 'Rejected' ? 'error' : 'processing'}
          text={status}
        />
      ),
    },
    {
      title: t("Actions"),
      dataIndex: 'operation',
      render: (_: any, record: Task) => {
        return (
          <Space>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => approveTask(record.id)}
              disabled={disabledTasks[record.id] || record.status === 'Approved' || record.status === 'Rejected'}
            >
              {t("Approve")}
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseOutlined />}
              onClick={() => rejectTask(record.id)}
              disabled={disabledTasks[record.id] || record.status === 'Approved' || record.status === 'Rejected'}
            >
              {t("Reject")}
            </Button>
          </Space>
        );
      },
    }
  ];

  const sortedTasks = taskStore.tasks.slice().sort((a, b) => {
    return dayjs(b.startDate).diff(dayjs(a.startDate));
  });

  return (
    <Spin spinning={taskStore.isLoading}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={sortedTasks}
        pagination={false}
      />
    </Spin>
  );
});

export default UserTaskPage;

