import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout"

const Tests = observer(() => {
    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle="Tests">
            <div className="text-center">
                <h1>Empty Tests</h1>
            </div>
        </Layout>
    )
})
export default Tests;