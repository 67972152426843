import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { useStore } from "../stores/root-store-context";
import { Roles } from "../stores/EmployeesStore";

interface CheckCompanyStatusProps {
  children: ReactNode;
}

const CheckCompanyStatus: React.FC<CheckCompanyStatusProps> = ({ children }) => {
  const { applicationStore } = useStore();

  if (applicationStore.company?.IsFrozen) {
    return <Navigate to={`/${applicationStore.company?.Tenaut}/payment-and-tariffs`} />;
  }

  return <>{children}</>;
};

export default CheckCompanyStatus;
