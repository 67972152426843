import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Spin, Table, TableProps, Typography, App, Card, TabsProps, Tabs, Upload, UploadFile, UploadProps } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, SettingOutlined, EditOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import { NewProject, Project } from "../../stores/ProjectsStore";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from 'dayjs';
import { Roles } from "../../stores/EmployeesStore";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { ProjectTask } from "../../stores/ProjectTasksStore";
import ReactQuill from "react-quill";
import ProjectDocumentationStore, { ProjectDocument } from "../../stores/ProjectDocumentationStore";
import { useTranslation } from "react-i18next";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { customItemRender, handleDownload } from "../../helpers/upload";
const { Text } = Typography;



const ProjectPage = observer(() => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { id } = useParams();

    const { applicationStore, projectsStore, projectDocumentationStore } = useStore();

    const [project, setProject] = useState<Project>();
    const [documents, setDocuments] = useState<TabsProps['items']>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [currentTab, setCurrentTab] = useState("");
    const [currentFileList, setCurrentFileList] = useState<any[]>([]);

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        let fileList = [...info.fileList];
        const formData = new FormData();
        // fileList.forEach(file => {
        //     formData.append('files[]', file);
        // });
        console.log(fileList);


        // const { status } = info.file;
        // if (status !== 'uploading') {
        //     console.log(info.file, info.fileList);
        // }
        // if (status === 'done') {
        //     message.success(`${info.file.name} file uploaded successfully.`);
        // } else if (status === 'error') {
        //     message.error(`${info.file.name} file upload failed.`);
        // }
        // setFileList(info.fileList);
    };

    const beforeUpload = (file: RcFile, allFiles: UploadFile[]) => {
        const isLt2M = file.size / 1024 / 1024 < 2000;
        if (!isLt2M) {
            message.error('Image must smaller than 2000MB!');
        }
        console.log(file);

        return isLt2M;
    };

    const handleUpload = ({ file, onSuccess, onError }: any) => {
        const formData = new FormData();
        formData.append('file', file);
        if (projectDocumentationStore.document?.id) {
            projectDocumentationStore.uploadFile(file, projectDocumentationStore.document.id).then(value => {
                updateCurrentFileList();
            })
        }
    }
    const handleAttachmentDeleting = ({publicId}: any) => {
        projectDocumentationStore.deleteAttachmentByPublicId(publicId);
        updateCurrentFileList();
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
        projectDocumentationStore.setDocument(null);
    };

    const deleteSection = () => {
        message.open({
            type: 'loading',
            content: 'Deleting...',
            key: 'Deleting'
        });
        if (projectDocumentationStore.document?.id) {
            projectDocumentationStore.deleteById(projectDocumentationStore.document?.id).then(() => {
                hideModal();
            }).finally(() => {
                updatePage();
                message.destroy("Deleting");
                message.success("The section was deleted");
            })
        }
    };

    const getFiles = () => {
        if (currentTab) {
            projectDocumentationStore.getFileList(currentTab).then((res) => {
                const fileList = res.map(file => {
                    return {
                        uid: file,
                        name: file.split('/').pop(),
                        status: 'done',
                        url: `http://res.cloudinary.com/dnl3x07wo/raw/upload/v1722014093/${file}`
                    }
                })
                setCurrentFileList(fileList);
            })
        }
    }

    const updateCurrentFileList = () => {
        if (currentTab) {
            projectDocumentationStore.downloadAttachmentsByDocumentId(currentTab).then((res) => {
                const fileList = res.map(file => {
                    return {
                        uid: file,
                        name: file.split('/').pop(),
                        status: 'done',
                        url: `http://res.cloudinary.com/dnl3x07wo/raw/upload/v1722014093/${file}`
                    }
                })
                setCurrentFileList(fileList);
            })
        }
    }

    const onChangeTabs = async (key: string) => {
        setCurrentTab(key);
    };

    const updatePage = () => {
        hideModal();
        if (id) {
            projectsStore.getById(id).then(res => {
                setProject(res);
            }).catch(error => {
                message.error(error.message);
            });
            
            projectDocumentationStore.getByProjectId(id).then(res => {
                if (res.length > 0 && res[0]?.id) {
                    const savedTabId = localStorage.getItem("activeTabId");
                    const firstTabId = savedTabId || res[0].id;
                    
                    setCurrentTab(firstTabId);
                    projectDocumentationStore.downloadAttachmentsByDocumentId(firstTabId);
                    onChangeTabs(firstTabId);
                } else {
                    setDocuments([]);
                    console.log("There are no sections available");
                }
    
                setDocuments(res?.map((document, index) => {
                    return {
                        key: document.id?.toString() || Math.random() + index.toString(),
                        label: document.title,
                        children: <div>
                            {(applicationStore.user.roleId !== Roles.Viewer) &&
                                <Button type="primary"
                                    shape="circle"
                                    style={{ position: "absolute", right: 0 }}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        projectDocumentationStore.setDocument(document);
                                        form.setFieldsValue(document);
                                        showModal();
                                    }} />}
                            <div style={{ minHeight: 500, paddingTop: 30 }} dangerouslySetInnerHTML={{ __html: document.content }} />
                        </div>
                    };
                }));
            }).catch(error => {
                message.error(error.message);
            });
        }
    };
    

    const onFinish: FormProps<ProjectDocument>['onFinish'] = async (section: ProjectDocument) => {
        message.open({
            type: 'loading',
            content: 'Saving section...',
            key: 'task'
        });
    
        if (section.id) {
            await projectDocumentationStore.edit(section).then((response) => {
                message.destroy("task");
                message.success("The section was saved");
                projectDocumentationStore.getByProjectId(id as string).then(res => {
                    //
                }).catch(error => {
                    message.error(error.message);
                });
                form.resetFields();
            }).catch(error => {
                message.destroy("task");
                message.error(error.message);
            });
        } else {
            await projectDocumentationStore.createNew(section).then((response) => {
                message.destroy("task");
                message.success("The section was saved");
    
                const newDocumentId = response.id;
                localStorage.setItem("activeTabId", newDocumentId);
    
                setCurrentTab(newDocumentId);
                updateCurrentFileList();
    
                projectDocumentationStore.getByProjectId(id as string).then(res => {
                    //
                }).catch(error => {
                    message.error(error.message);
                });
                form.resetFields();
            }).catch(error => {
                message.destroy("task");
                message.error(error.message);
            });
        }
        updatePage();
    };
    
    useEffect(() => {
        updatePage();
    }, [])

    useEffect(() => {
        getFiles();
    }, [currentTab])

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`Project`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <Spin spinning={projectsStore.isLoading || projectDocumentationStore.isLoading}>
                    {project ? <>
                        <Card style={{ margin: "20 0", textAlign: "center" }}>
                            <Flex justify="space-between">
                                <Button type="primary"
                                    style={{ float: "right", marginBottom: 20, borderRadius: 0 }}
                                    onClick={() => {
                                        navigate(`/${applicationStore.company?.Tenaut}/projects`);
                                    }}>
                                    Back to projects
                                </Button>
                                <Flex vertical gap={10}>
                                    <h4 title="Project name" >{project?.title}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: project.description }} />
                                    <Text type="success">Project started: {project?.startDate.format('YYYY-MM-DD')}</Text>
                                </Flex>
                                {((applicationStore.user.roleId !== Roles.Viewer))  &&
                                    <Button type="primary" shape="circle" icon={<SettingOutlined />}
                                    disabled={!(project.developers?.some(dev=>dev.id==applicationStore.user.id) || project.projectResponsibleId === applicationStore.user.id || applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager)}
                                        onClick={() => {
                                            navigate("./settings");
                                        }} />
                                }
                            </Flex>

                        </Card>
                    </> : <></>}
                    <>
                        <Tabs
                            activeKey={currentTab}
                            items={documents}
                            type="card"
                            onChange={onChangeTabs}
                            tabPosition={"left"}
                            style={{ minHeight: 500, margin: "20px 0" }}
                            tabBarStyle={{ width: 200, maxHeight: 500 }}
                            tabBarExtraContent={
                                <>
                                    {(applicationStore.user.roleId !== Roles.Viewer) &&
                                        <Button type="primary" style={{ marginBottom: 20, borderRadius: 0, width: 200 }} onClick={showModal}>
                                            {t("Add_new_section")}
                                        </Button>
                                    }
                                </>
                            } />
                        <Flex >
                            <div style={{ minWidth: 200 }}></div>
                            <Spin spinning={projectDocumentationStore.isLoadingAttachments}>
                                <Upload
                                    listType='picture-card'
                                    fileList={currentFileList}
                                    className='upload-list-inline'
                                    onDownload={handleDownload}
                                    showUploadList={{
                                        showRemoveIcon: false,
                                        showDownloadIcon: true,
                                        downloadIcon: <DownloadOutlined />
                                    }}
                                // itemRender={customItemRender}
                                >
                                </Upload>
                            </Spin>
                        </Flex>
                    </>
                </Spin>
            </div>
            <Modal title={projectDocumentationStore.document === null ? t("CreateSection") : t("EditSection")} open={isModalOpen} footer={null} onCancel={hideModal} width={900}
                style={{ padding: 10 }}>
                <Form form={form} onFinish={onFinish} initialValues={{ projectId: id, creatorId: applicationStore.user.id }} layout="vertical">
                    <Form.Item<ProjectDocument> name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> name="projectId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> name="creatorId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> label="Title" name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item<ProjectDocument> label="Content" name="content" style={{ width: "100%", marginBottom: 30 }} rules={[{ required: true }]} >
                        <ReactQuill theme="snow" style={{ height: 500 }} onChange={() => {
                            console.log("ReactQuill");
                        }} />
                    </Form.Item>
                    <Flex style={{ marginTop: 60 }} >
                        <Spin spinning={projectDocumentationStore.isLoadingAttachments}>
                            <Upload
                                fileList={currentFileList}
                                customRequest={handleUpload}
                                onChange={handleChange}
                                beforeUpload={beforeUpload}
                                listType="picture"
                                onRemove={(e) => {
                                    console.log("onRemove");
                                    console.log(e);
                                    handleAttachmentDeleting({publicId: e.uid});
                                }}
                                onDrop={(e) => {
                                    console.log("onDrop");
                                    console.log(e);
                                }}
                                onDownload={handleDownload}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Spin>
                    </Flex>
                    <Form.Item style={{ margin: 0 }}>
                        <Flex justify="space-between">
                            <Popconfirm
                                title="Delete the section"
                                description="Are you sure to delete this section?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={deleteSection}
                            >
                                {projectDocumentationStore.document !== null &&
                                    <Button type="default" danger style={{ height: 65, marginTop: 30 }}>
                                        {t('DeleteSection')} <DeleteOutlined style={{ fontSize: 20, marginLeft: 5 }} /></Button>
                                }
                            </Popconfirm>
                            <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, marginTop: 30, display: "flex" }}>
                                {t('SaveSection')} <SaveOutlined style={{ fontSize: 20, marginLeft: 5 }} />
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout >
    )
})
export default ProjectPage;