import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tabs,
  message,
  Radio,
  Image
} from "antd";
import {
  SaveOutlined,
  DownOutlined,
  UpOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { useStore } from "../../stores/root-store-context";
import dayjs, { Dayjs } from "dayjs";
import { Option } from "antd/lib/mentions";
import TabPane from "antd/lib/tabs/TabPane";
import { useForm } from "antd/es/form/Form";
import utc from "dayjs/plugin/utc";
import { useNavigate, Link } from "react-router-dom";
import SalaryStore from "../../stores/SalaryStore";
import { getUserComponent } from "../../helpers/user";
import { useLocation } from "react-router-dom";
import axios from 'axios';
dayjs.extend(utc);

const { RangePicker } = DatePicker;

type SalariesItem = {
  id: any;
  userId: string;
  userEmail: string;
  salaryPerHour: string;
  salaryPerPeriod: string;
}


type SalariesItemString = {
  id: string;
  salaryPerHour: string;
  salaryPerMonth?: string;
  startDate: Dayjs;
  endDate: Dayjs;
  userId: string;
}

const SalariesPage = observer(() => {
  const [addForm] = useForm();
  const { applicationStore, employeesStore, timeReportStore, salaryStore, taskStore } = useStore();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf("month"));
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNewSalart, setIsNewSalart] = useState(true);
  const [salaries, setSalaries] = useState<SalariesItem[]>([]);
  const [salaryToAdd, setSalaryToAdd] = useState<any>({});
  const [users, setUsers] = useState<any[]>();
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentSalaries, setCurrentSalaries] = useState<SalariesItemString[]>([]);
  const [salaryType, setSalaryType] = useState<'hour' | 'month'>('hour');
  const navigator = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState("1");
  const location = useLocation();

  const currency = applicationStore.company?.Currency;

  const fetchSalaries = async () => {
    setIsUpdating(true);
    employeesStore.setLoading(true);
    const users = await employeesStore.getUsers();
    const timeReports = await timeReportStore.getForFinancier();
    const result = await salaryStore.getByPeriod(startDate, endDate);

    const userSalaryList = users.map((user) => {
      const userSalary = result.find(
        (salary: any) => salary.userId === user.id
      );

      let salaryPerPeriod = 0;
      const usersReports = timeReports.filter(
        (report: any) => report.log.userId === user.id
      );
      usersReports.forEach((element: any) => {
        const [hours, minutes, seconds] = element.log.totalTime
          .split(":")
          .map(Number);
        const totalHours = hours + minutes / 60 + seconds / 3600;
        salaryPerPeriod =
          salaryPerPeriod + totalHours * userSalary?.salaryPerHour || 0;
      });
      return {
        ...userSalary,
        id: userSalary?.id,
        userId: user.id,
        userEmail: user.email,
        salaryPerHour: userSalary ? userSalary.salaryPerHour : t("NoSalaryData"),
        salaryPerPeriod: salaryPerPeriod.toFixed(2),
      };
    });
    setSalaries(userSalaryList);
    setUsers(users);
    setIsUpdating(false);
  };

  useEffect(() => {
    fetchSalaries();
  }, [startDate, endDate]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash === "salaryRecords") {
      setActiveTabKey("2");
    } else {
      setActiveTabKey("1");
    }
  }, [location]);

  const handleSalaryTypeChange = (e: any) => {
    const newSalaryType = e.target.value as 'hour' | 'month';
    setSalaryType(newSalaryType);

    console.log(`Salary type changed to: ${newSalaryType}`);

    if (newSalaryType === 'hour') {
      const calculatedSalaryPerHour = salaryToAdd.salaryPerMonth ? salaryToAdd.salaryPerMonth / 160 : undefined;
      console.log(`Calculated Salary Per Hour based on Salary Per Month: ${calculatedSalaryPerHour}`);
      addForm.setFieldsValue({
        salaryPerHour: calculatedSalaryPerHour
      });
      setSalaryToAdd((prev: any) => ({
        ...prev,
        salaryPerHour: calculatedSalaryPerHour,
      }));
    } else {
      const calculatedSalaryPerMonth = salaryToAdd.salaryPerHour ? salaryToAdd.salaryPerHour * 160 : undefined;
      console.log(`Calculated Salary Per Month based on Salary Per Hour: ${calculatedSalaryPerMonth}`);
      addForm.setFieldsValue({
        salaryPerMonth: calculatedSalaryPerMonth
      });
      setSalaryToAdd((prev: any) => ({
        ...prev,
        salaryPerMonth: calculatedSalaryPerMonth,
      }));
    }
  };

  const completeReviewTask = async (financierId: number, userEmail: string) => {
    try {
      await taskStore.fetchTasksByUserId(financierId.toString());

      const task = taskStore.tasks.find(t => t.title.includes(userEmail));

      if (!task) {
        // message.error("Task not found");
        return;
      }

      const success = await taskStore.ApiStore.TasksApiClient.markTaskAsCompleted(task.id);

      if (success) {
        message.success("Task marked as completed");
      } else {
        message.error("Failed to complete the task");
      }
    } catch (error) {
      message.error("Error occurred while completing the task");
      console.error(error);
    }
  };


  const fetchSalaryRecords = async () => {
    await salaryStore.getAll();
    const salaryRecords = salaryStore.salaries.map((salary: any) => ({
      ...salary,
      salaryPerHour: `${salary.salaryPerHour} ${currency}`,
      salaryPerMonth: `${(salary.salaryPerHour * 160).toFixed(2)} ${currency}`,
    }));
    setCurrentSalaries(salaryRecords);
  };

  useEffect(() => {
    fetchSalaries();
  }, [startDate, endDate]);

  useEffect(() => {
    fetchSalaryRecords();
  }, []);


  const addSalary = async () => {
    message.open({
      type: "loading",
      content: t("Saving"),
      key: "saving",
    });

    const startDateFormatted = dayjs(salaryToAdd.startDate).format('YYYY-MM-DD');
    const plannedReviewDateFormatted = dayjs(salaryToAdd.plannedReviewDate).format('YYYY-MM-DD');

    salaryToAdd.startDate = startDateFormatted;
    salaryToAdd.endDate = plannedReviewDateFormatted;
    console.log("salaryToAdd");
    console.log(salaryToAdd);
    

    const result = await salaryStore.add(salaryToAdd);
    message.destroy("saving");
    console.log("result", result);
    
    if (result) {
      message.success(t("Success"));
      fetchSalaries();
      fetchSalaryRecords();

      const financierId = Number(applicationStore.user.id);
      const userEmail = salaryToAdd.userEmail;

      if (userEmail) {
        await completeReviewTask(financierId, userEmail);
        await taskStore.fetchTasksByUserId(applicationStore.user.id);
        applicationStore.initUserTasks();
      } else {
        console.error("User Email is undefined");
      }
    } else {
      message.error(result);
    }
    setUpdateModalVisible(false);
  };

  const updateSalary = async () => {
    message.open({
      type: "loading",
      content: t("Saving"),
      key: "saving",
    });

    const startDateFormatted = dayjs(salaryToAdd.startDate).format('YYYY-MM-DD');
    const plannedReviewDateFormatted = dayjs(salaryToAdd.plannedReviewDate).format('YYYY-MM-DD');
    console.log("salaryToAdd");
    console.log(startDateFormatted.toString());
    
    salaryToAdd.startDate = startDateFormatted;
    salaryToAdd.endDate = plannedReviewDateFormatted;

    const result = await salaryStore.ApiStore.SalaryApiClient.updateSalary(
      salaryToAdd.id,
      salaryToAdd
    );
    message.destroy("saving");

    if (result) {
      message.success(t("Success"));
      addForm.resetFields();
      setUpdateModalVisible(false);
      fetchSalaries();
      fetchSalaryRecords();
      const financierId = Number(applicationStore.user.id);
      const userEmail = salaryToAdd.userEmail;

      if (userEmail) {
        await completeReviewTask(financierId, userEmail);
        await taskStore.fetchTasksByUserId(applicationStore.user.id);
        applicationStore.initUserTasks();
      } else {
        console.error("User Email is undefined");
      }
    } else {
      console.log("result");
      console.log(result);

      message.error(result);
    }
    message.destroy("saving");


    setUpdateModalVisible(false);
  };


  const deleteSalary = async (salaryId: string) => {
    message.open({
      type: "loading",
      content: t("Saving"),
      key: "saving",
    });
    const result = await employeesStore.ApiStore.SalaryApiClient.deleteSalary(
      salaryId
    );
    message.destroy("saving");
    if (result) {
      message.success(t("Success"));
      fetchSalaries();
      fetchSalaryRecords();
      await taskStore.fetchTasksByUserId(applicationStore.user.id);
      applicationStore.initUserTasks();
    } else {
      message.error(t("Error"));
    }
  };

  const handleUpdateClick = async (record: any) => {
    setIsNewSalart(false);

    const user = users?.find((u: any) => u.id === record.userId);
    const items = currentSalaries.filter(salary => salary.userId == record.userId).sort((a, b) => DateSort(a.endDate, b.endDate));
    let lastItem = null;
    if (items.length > 0) {
      lastItem = items[items.length - 1];
    }
    const salaryData = {
      id: record.id,
      userId: record.userId,
      userEmail: user ? user.email : undefined,
      salaryPerHour: parseFloat(record.salaryPerHour),
      startDate: dayjs(record.startDate),
      endDate: dayjs(record.endDate),
      plannedReviewDate: dayjs(record.endDate),
    };
    console.log("salaryData:", salaryData);
    setSalaryToAdd({
      ...salaryData, salaryPerHour: isNaN(parseFloat(record.salaryPerHour))
        ? 0
        : parseFloat(record.salaryPerHour)
    });

    addForm.setFieldsValue({
      salaryPerHour: isNaN(parseFloat(record.salaryPerHour))
        ? 0
        : parseFloat(record.salaryPerHour),
      startDate: dayjs(record.startDate),
      endDate: dayjs(record.endDate),
      plannedReviewDate: salaryData.endDate,
      user: record.userId,
    });

    setUpdateModalVisible(true);
  };

  const handleAddClick = async (value: any) => {
    setIsUpdating(false);
    addForm.resetFields();
    setUpdateModalVisible(true);
    console.log(value);
  };

  useEffect(() => {
    if (isUpdating) {
      addForm.resetFields();
      addForm.setFieldsValue({
        salaryPerHour: salaryToAdd.salaryPerHour,
        salaryPerMonth: salaryToAdd.salaryPerHour * 160,
        userId: salaryToAdd.userId,
      });
    }
  }, [updateModalVisible, addForm, salaryToAdd]);

  const handleReviewSalaryClick = (record: any) => {
    setIsNewSalart(true);
    const user = users?.find((u: any) => u.id === record.userId);
    const items = currentSalaries.filter(salary => salary.userId == record.userId).sort((a, b) => DateSort(a.endDate, b.endDate));
    let lastItem = null;
    if (items.length > 0) {
      lastItem = items[items.length - 1];
    }
    const salaryData = {
      userId: record.userId,
      userEmail: user ? user.email : undefined,
      salaryPerHour: parseFloat(record.salaryPerHour),
      startDate: dayjs(record.endDate).add(1, 'day'),
      endDate: dayjs(record.endDate).add(3, 'month'),
      plannedReviewDate: dayjs(record.endDate).add(3, 'month'),
    };
    console.log("salaryData:", salaryData);
    console.log("record:", record);
    setSalaryToAdd({ ...salaryData, salaryPerHour: isNaN(parseFloat(record.salaryPerHour))
      ? 0
      : parseFloat(record.salaryPerHour) });

    addForm.setFieldsValue({
      salaryPerHour: isNaN(parseFloat(record.salaryPerHour))
      ? 0
      : parseFloat(record.salaryPerHour),
      startDate: dayjs(record.endDate).add(1, 'day'),
      plannedReviewDate: salaryData.plannedReviewDate,
      user: record.userId,
    });

    setUpdateModalVisible(true);
  };


  const columns = [
    {
      title: t("user.User"),
      dataIndex: "userEmail",
      key: "userEmail",
      filters: users?.map((user: any) => ({
        text: user.email,
        value: user.email,
      })),
      onFilter: (value: any, record: any) => record.userEmail.includes(value),
      sorter: (a: any, b: any) => TextSort(a.userEmail, b.userEmail),
      render: (text: string, record: any) => {
        const user = users?.find((u) => u.email === text);

        return (
          <Flex align="center">
            <Image
              width={80}
              height={80}
              style={{ borderRadius: "50%", objectFit: "cover" }}
              src={
                user?.photoId
                  ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${user.photoId}`
                  : `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
              }
              alt={user?.firstName}
            />
            <div style={{ marginLeft: 20 }}>
              <Link
                to={`/${applicationStore.company.Tenaut}/employers/${user?.id}`}
                style={{ display: "block" }}
              >
                {`${user?.firstName} ${user?.lastName}`}
              </Link>
              <div style={{ color: "gray" }}>{user?.email}</div>
            </div>
          </Flex>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: t("SalaryPerHour"),
      dataIndex: "salaryPerHour",
      filters: [
        { text: t("All"), value: "all" },
        { text: t("NoSalaryData"), value: t("NoSalaryData") },
      ],
      onFilter: (value: any, record: any) => {
        if (value === "all") return true;
        return record.salaryPerHour === t("NoSalaryData");
      },
      render: (salaryPerHour: any) => `${salaryPerHour} ${currency}`,
      sorter: (a: any, b: any) => a.salaryPerHour - b.salaryPerHour,
      key: "salaryPerHour",
    },
    {
      title: t("SalaryPerPeriod"),
      dataIndex: "salaryPerPeriod",
      render: (salaryPerPeriod: any) => `${salaryPerPeriod} ${currency}`,
      sorter: (a: any, b: any) => a.salaryPerPeriod - b.salaryPerPeriod,
      key: "salaryPerPeriod",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: any, record: any) => (
        <Flex gap={10}>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => handleReviewSalaryClick(record)}
          >
            {t("ReviewSalary")}
          </Button>
        </Flex>
      ),
    }
  ];
  const currentSalariesColumns = [
    // {
    //   title: t("User"),
    //   dataIndex: "userId",
    //   key: "userId",
    //   render: (data: string) => <div>{getUserComponent(employeesStore.users.find(el => el.id === data), applicationStore.company?.Tenaut)}</div>,
    // },
    {
      title: t("SalaryPerHour"),
      dataIndex: "salaryPerHour",
      key: "salaryPerHour",
    },
    {
      title: t("StartDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string) => dayjs(date).format("YYYY.MM.DD"),
    },
    {
      title: t("ReviewDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => dayjs(date).format("YYYY.MM.DD"),
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: any, record: any) => (
        <Flex gap={10}>
          <Button
            icon={<EditOutlined />}
            // disabled={true}
            onClick={() => {
              handleUpdateClick(record);
            }}
          >
            {t("EditThisRecord")}
          </Button>
        </Flex>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: any) => {
        return (
          <>
            <Flex justify="space-between">
              <Popconfirm
                title={t("DeleteSalary")}
                description={t("ConfirmDeleteSalary")}
                okText={t("Yes")}
                cancelText={t("No")}
                onConfirm={(e) => {
                  deleteSalary(record.id);
                }}
              >
                <Button
                  type="primary"
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Flex>
          </>
        );
      },
    },
  ];
  return (
    <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("Menu.Salaries")}>
      <div className="container" style={{ margin: "20px auto" }}>
        <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey}>
          <TabPane tab={t("Menu.EmployeeSalaries")} key="1">
            <Spin spinning={salaryStore.isLoading || isUpdating} style={{ minHeight: 600 }}>
              <Flex
                style={{ marginTop: "15px", justifyContent: "space-between" }}
                gap={5}
                align="baseline"
              >
                <Flex>
                  <Button
                    shape="circle"
                    onClick={(e) => {
                      setStartDate(startDate.subtract(1, "month").startOf("month"));
                      setEndDate(startDate.subtract(1, "month").endOf("month"));
                    }}
                  >
                    <LeftOutlined />
                  </Button>
                  <RangePicker
                    value={[startDate, endDate]}
                    picker="date"
                    format="DD MMMM YYYY"
                    style={{ marginBottom: 16 }}
                    onChange={(dates: any) => {
                      setStartDate(dates[0]);
                      setEndDate(dates[1]);
                    }}
                  />
                  <Button
                    shape="circle"
                    onClick={(e) => {
                      setStartDate(startDate.add(1, "month").startOf("month"));
                      setEndDate(startDate.add(1, "month").endOf("month"));
                    }}
                  >
                    <RightOutlined />
                  </Button>
                </Flex>

                <Button
                  onClick={(e) => handleAddClick(e)}
                  style={{ marginRight: "115px" }}
                >
                  {t("Add")}
                </Button>
              </Flex>
              <Table dataSource={salaries}
                columns={columns}
                rowKey="id"
                rowHoverable
                expandable={{
                  expandIcon: ({ expanded, onExpand, record }) => {
                    if (expanded) {
                      return (
                        <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                          <UpOutlined />
                        </Button>
                      );
                    } else {
                      return (
                        <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                          <DownOutlined />
                        </Button>
                      );
                    }
                  },
                  expandRowByClick: true,
                  expandedRowRender: (record, index) => {
                    const salaries = currentSalaries.filter(salary => salary.userId == record.userId).sort((a, b) => DateSort(a.endDate, b.endDate));
                    if (salaries.length === 0) {
                      return null;
                    }
                    return (
                      <Table
                        dataSource={salaries}
                        columns={currentSalariesColumns}
                        rowKey="id"
                        key={"expandedRowRender-" + index}
                      />
                    );
                  },
                }}
              />
              <Modal
                open={updateModalVisible}
                footer={null}
                onCancel={() => {
                  setUpdateModalVisible(false);
                  addForm.resetFields();
                }}
                title={isNewSalart ? `${t("Add")}` : t("EditThisRecord")}
              >
                <Form
                  form={addForm}
                  onFinish={isNewSalart ? addSalary : updateSalary}
                  initialValues={{ salaryType: 'hour', }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 12 }}
                  labelAlign="left"
                  style={{ width: '570px' }}
                >
                  <Flex
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      label={t("SalaryType")}
                      name="salaryType"
                      style={{ width: "100%" }}
                    >
                      <Radio.Group
                        onChange={handleSalaryTypeChange}
                        value={salaryType}
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        <Radio.Button value="hour" style={{ width: "40%", textAlign: "center" }}>
                          {t("PerHour")}
                        </Radio.Button>
                        <Radio.Button value="month" style={{ width: "60%", textAlign: "center" }}>
                          {t("PerMonth160Hours")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>

                    {salaryType === "hour" ? (
                      <Form.Item
                        label={`${t("SalaryPerHour")} (${currency})`}
                        name="salaryPerHour"
                        rules={[{ required: true }]}
                        style={{ width: "100%" }}
                      >
                        <InputNumber
                          min={0}
                          disabled={salaryType !== "hour"}
                          onChange={(value) => {
                            if (value !== null) {
                              console.log(`Salary Per Hour changed to: ${value}`);
                              const calculatedSalaryPerMonth = value ? (value as number) * 160 : 0;

                              // addForm.setFieldsValue({
                              //   salaryPerMonth: calculatedSalaryPerMonth
                              // });
                              setSalaryToAdd((prev: any) => ({
                                ...prev,
                                salaryPerHour: value,
                                // salaryPerMonth: calculatedSalaryPerMonth,
                              }));
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label={`${t("SalaryPerMonth")} (${currency})`}
                        name="salaryPerMonth"
                        rules={[{ required: true }]}
                        style={{ width: "100%" }}
                      >
                        <InputNumber
                          disabled={salaryType !== "month"}
                          onChange={(value) => {
                            if (value !== null) {
                              console.log(`Salary Per Month changed to: ${value}`);
                              const calculatedSalaryPerHour = value ? (value as number) / 160 : undefined;
                              addForm.setFieldsValue({
                                salaryPerHour: calculatedSalaryPerHour
                              });
                              setSalaryToAdd((prev: any) => ({
                                ...prev,
                                // salaryPerMonth: value,
                                salaryPerHour: calculatedSalaryPerHour,
                              }));
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    )}

                    <Form.Item
                      label={t("StartDate")}
                      name="startDate"
                      rules={[{ required: true, message: t("PleaseSelectAStartDate") }]}
                      style={{ width: "100%" }}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(date) => {
                          const plannedReviewDate = date ? date.add(3, 'month') : null;
                          setSalaryToAdd({
                            ...salaryToAdd,
                            startDate: date,
                            plannedReviewDate: plannedReviewDate, // Automatically set planned review date
                          });
                          addForm.setFieldsValue({
                            plannedReviewDate: plannedReviewDate, // Update the planned review date field in the form
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("ReviewDate")}
                      name="plannedReviewDate"
                      initialValue={dayjs().add(3, 'month')}
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: t("PleaseSelectAReviewDate") }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(date) => {
                          setSalaryToAdd({ ...salaryToAdd, plannedReviewDate: date });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("User")}
                      name="user"
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: t("PleaseSelectAUser") }]}
                    >
                      <Select
                        onSelect={(e) =>
                          setSalaryToAdd({ ...salaryToAdd, userId: e.userId })
                        }
                        value={salaryToAdd.userId}
                        showSearch={true}
                        filterOption={(input, option) =>
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{ width: "100%" }}
                        optionLabelProp="label"
                      >
                        {users?.map((user) => (
                          <Select.Option
                            value={user.id}
                            key={user.id}
                            label={`${user.firstName} ${user.lastName}`}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Image
                                width={40}
                                height={40}
                                style={{ borderRadius: "50%", objectFit: "cover", marginRight: 10 }}
                                src={
                                  user.photoId
                                    ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${user.photoId}`
                                    : `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                                }
                                alt={user.firstName}
                              />
                              <div>
                                <div>
                                  {user.firstName} {user.lastName}
                                </div>
                                <div style={{ color: "gray" }}>{user.email}</div>
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item style={{alignItems:"start"}}>
                      <Button
                        type="primary"
                        className="theme-btn"
                        htmlType="submit"
                        style={{ height: 70 }}
                      >
                        {`${t("Save")}`}{" "}
                        <SaveOutlined style={{ fontSize: 20, margin: 5 }} />
                      </Button>
                    </Form.Item>
                  </Flex>
                </Form>
              </Modal>
            </Spin>
          </TabPane>
          {/* <TabPane tab={t("Menu.SalaryRecords")} key="2">
            <Spin spinning={salaryStore.isLoading}>
              <Table
                dataSource={currentSalaries}
                columns={currentSalariesColumns}
                rowKey="id"
              />
            </Spin>
          </TabPane> */}
        </Tabs>
      </div>
    </Layout>
  );
});
export default SalariesPage;
