import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import dayjs, { Dayjs } from 'dayjs';
import { Employee } from "../api/EmployeesApiClient";
import { Project } from "./ProjectsStore";

export type ProjectTask = {
  id: string,
  title: string,
  description: string,
  startDate: Dayjs,
  dueDate: Dayjs,
  companyUserId: number,
  projectId: number,
  [x: string]: any,
}
class ProjectTaskStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  project!: Project;
  tasks!: ProjectTask[];

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.tasks = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  createNew = async (task: ProjectTask) => {
    this.isLoading = true;
    const response = await this.ApiStore.ProjectTaskApiClient.addTask(task);
    this.isLoading = false;

    return response;
  }

  getAll = async (): Promise<ProjectTask[]> => {
    this.isLoading = true;
    const tasks = await this.ApiStore.ProjectTaskApiClient.getTasks().finally(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    if (tasks) {
      this.tasks = tasks.map((el: {
        deadLine: string; startDate: string;
      }) => {
        return {
          ...el,
          startDate: dayjs(el.startDate),
          dueDate: dayjs(el.deadLine)
        }
      });
    }
    return tasks;
  }

  getById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectTaskApiClient.getTaskById(id).finally(()=>{
      this.isLoading = false;
    });
    if (project) {
      this.project = project;
    }
    return project;
  }

  getByProjectId = async (id: string) => {
    this.isLoading = true;
    const tasks = await this.ApiStore.ProjectTaskApiClient.getTaskByProjectId(id).finally(()=>{
      this.isLoading = false;
    });
    if (tasks) {
      this.tasks = tasks.map((el: {
        deadLine: string; startDate: string;
      }) => {
        return {
          ...el,
          startDate: dayjs(el.startDate),
          dueDate: dayjs(el.deadLine)
        }
      });
    }
    return tasks;
  }

  deleteById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.ProjectTaskApiClient.deleteTaskById(id).finally(()=>{
      this.isLoading = false;
    });
    if (project) {
      this.project = project;
    }
    return project;
  }

}
export default ProjectTaskStore;
