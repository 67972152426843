import { UploadFile } from "antd";
import { Roles } from "../stores/EmployeesStore";
import { AssignUserProject, NewProject, Project } from "../stores/ProjectsStore";
import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { RcFile } from "antd/es/upload";
import { log } from "console";


export type TimeReport = {
  id: string;
  userId: string;
  description: string;
  projectId: string;
  startTime: Dayjs;
  endTime: Dayjs;
  totalTime: string;
}

export default class TimeReportsApiClient extends SkTechApiClient {

  public getAll = async (): Promise<TimeReport[]> => {
    return await this.Get(`/api/ProjectTimeLog`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data.map((el: {
            totalTime: string;
            endTime: string;
            startTime: string;
          }) => {
            return {
              ...el,
              startTime: dayjs.utc(el.startTime).local(),
              endTime: dayjs.utc(el.endTime).local(),
            }
          });
        }
        return false;
      });
  }

  public deleteById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/ProjectTimeLog?id=${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }


  public getById = async (id: string): Promise<any> => {
    return await this.Get(`/api/ProjectTimeLog/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return { time: dayjs().utc().local() }
        }
        return false;
      });
  }

  public getByUserId = async (id: string): Promise<any> => {
    return await this.tryGet(`/api/ProjectTimeLog/TimeByUser?userId=${id}`)
      .then(function (response) {
        console.log("response.data", response.data);
        if (response?.status === 200) {
          if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
            return {
              ...response.data,
              time: dayjs.utc(response.data.time).local().format('HH:mm:ss'),
            };
          } else if (Array.isArray(response.data)) {
            return response.data.map((log: { startTime: string, endTime: string }) => ({
              ...log,
              startTime: dayjs.utc(log.startTime).local(),
              endTime: dayjs.utc(log.endTime).local()
            }));
          } else {
            console.error("Error: Invalid data format");
            return [];
          }
        }
        return null;
      });
  }
  
  
  

  public getByProjectId = async (userId: string, projectId: string): Promise<any> => {
    return await this.tryGet(`/api/ProjectTimeLog/TimeByUserOnProject?projectId=${projectId}&userId=${userId}`)
      .then(function (response) {
        if (response?.status === 200) {
          return {
            ...response.data,
            startTime: dayjs.utc(response.data.startTime).local(), 
            endTime: dayjs.utc(response.data.endTime).local(), 
            totalTime: dayjs.utc(response.data.totalTime).local(),
          }
        }
        return false;
      });
  }

  public addTime = async (projectId: string, time: string, endTime: string, userId: string, description: string): Promise<any> => {
    return await this.tryPost(`/api/ProjectTimeLog/AddTimeLogging?projectId=${projectId}&time=${time}&endDate=${endTime}&description=${description}&userId=${userId}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public editTime = async (id: string, data: { [x: string]: string }): Promise<any> => {
    return await this.Patch(`/api/ProjectTimeLog/${id}`,
      data
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public startTimer = async (id: string): Promise<any> => {
    return await this.Get(`/api/ProjectTimeLog/StartTimeLogging?projectId=${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public stopTimer = async (id: string, description: string): Promise<any> => {
    return await this.Get(`/api/ProjectTimeLog/StopTimeLogging?projectId=${id}&description=${description}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public deleteTimer = async (id: string): Promise<any> => {
    return await this.Delete(`/api/ProjectTimeLog?id=${id}`)
    .then(function (response) {
      if (response.status === 200) {
        return true;
      }
      return false;
    });
  }

  public getForFinancier = async (): Promise<any> => {
    return await this.Get(`/api/ProjectTimeLog`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data.map((el: any) => {
            return {
              ...el,
              log:{
                ...el.log,
                startTime: dayjs.utc(el.log.startTime).local(),
                endTime: dayjs.utc(el.log.endTime).local(),
              }
            }
          });
        }
        return false;
      });
  }



  // public assignNewUser = async (item: AssignUserProject): Promise<any> => {
  //   return await this.Post(`/api/Project/AssignUserToProject?userId=${item.userId}&projectId=${item.projectId}&roleTitle=${Roles[parseInt(item.roleId)]}`)
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       return false;
  //     });
  // }

  // public editAssignUser = async (item: AssignUserProject): Promise<any> => {
  //   return await this.Post(`/api/Project/ChangeUserProjectRole?userId=${item.userId}&projectId=${item.projectId}&roleTitle=${Roles[parseInt(item.roleId)]}`)
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       return false;
  //     });
  // }

  // public deleteAssignUser = async (userId: string, projectId: string): Promise<any> => {
  //   return await this.Post(`/api/Project/RemoveUserFromProject?userId=${userId}&projectId=${projectId}`)
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       return false;
  //     });
  // }

  // public uploadAttachment = async (file: UploadFile, id: string): Promise<any> => {
  //   const formData = new FormData();
  //   formData.append('file', file.originFileObj as RcFile);
  //   return await this.Post(`/api/File/UploadProjectAttachment?projectId=${id}`,
  //     formData
  //   )
  //     .then((response) => {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       return false;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       return false;
  //     });
  // }

  // public GetAttachmentsListByProject = async (id: string): Promise<any> => {
  //   return await this.Get(`/api/Documentation/GetAttachmentsListByProject?projectId=${id}`)
  //     .then(function (response) {
  //       if (response.status === 200) {
  //         return response.data;
  //       }
  //       return false;
  //     });
  // }
}