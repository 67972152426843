import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { Task } from "../api/TasksApiClient";

class TaskStore {
  isLoading: boolean = false;
  tasks: Task[] = [];
  ApiStore!: ApiStore;

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  async fetchTasksByUserId(userId: string) {
      this.tasks = await this.ApiStore.TasksApiClient.getTasksByUserId(userId);
  }

  async approveTask(taskId: number) {
      const success = await this.ApiStore.TasksApiClient.approveTask(taskId);
      if (success) {
          const task = this.tasks.find(t => t.id === taskId);
          if (task) {
              task.status = 'Approved';
              this.tasks = [...this.tasks];
          }
      }
  }

  async rejectTask(taskId: number) {
      const success = await this.ApiStore.TasksApiClient.rejectTask(taskId);
      if (success) {
          const task = this.tasks.find(t => t.id === taskId);
          if (task) {
              task.status = 'Rejected';
              this.tasks = [...this.tasks];
          }
      }
  }

}

export default TaskStore;
