import Layout from "../../components/layout/Layout"
import { Helmet } from 'react-helmet';

const PrivacyPolicyUA = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Privacy Policy" />
            </Helmet>
            <div className="container" style={{ display: "flex", flexDirection: "column", gap: 10, marginBottom: 30 }}>
                <h2>Політика конфіденційності</h2>
                <p>Політика конфіденційності регулює збір, використання та обробку персональних даних користувачів веб-сайту <strong>SkTech</strong> (далі — «Сайт»), який надає послуги з управління проєктами. Користуючись нашим Сайтом, Користувач погоджується з умовами цієї Політики.</p>

                <h3>1. Збір та використання персональних даних</h3>
                <ol>
                    <li>
                        <p>Сайт збирає такі дані:</p>
                        <ul>
                            <li>Ім'я, прізвище, електронна пошта, номер телефону;</li>
                            <li>Інформація, яку Користувач надає добровільно (під час реєстрації, запитів).</li>
                        </ul>
                    </li>
                    <li>
                        <p>Цілі обробки даних:</p>
                        <ul>
                            <li>Надання доступу до Сервісу;</li>
                            <li>Технічна підтримка;</li>
                            <li>Інформування про новини та оновлення;</li>
                            <li>Аналітика для покращення якості послуг.</li>
                        </ul>
                    </li>
                </ol>

                <h3>2. Захист та зберігання даних</h3>
                <ol>
                    <li>Сайт забезпечує належний рівень захисту персональних даних Користувачів від несанкціонованого доступу, втрати або змін.</li>
                    <li>Термін зберігання: до моменту видалення профілю Користувача або припинення роботи Сайту.</li>
                </ol>

                <h3>3. Розкриття персональних даних</h3>
                <ol>
                    <li>
                        Сайт не передає персональні дані третім особам, за винятком випадків, можуть передаватися:
                        <ul>
                            <li>Платіжним системам для здійснення транзакцій;</li>
                            <li>Партнерам для обслуговування Сайту;</li>
                            <li>Уповноваженим органам відповідно до законодавства України.</li>
                        </ul>
                    </li>
                </ol>

                <h3>4. Використання файлів cookie</h3>
                <ol>
                    <li>Файли cookie застосовуються для збереження налаштувань Користувача, аналізу трафіку, покращення роботи Сайту.</li>
                    <li>Користувач може відмовитися від cookie у налаштуваннях браузера, але це може обмежити функціональність.</li>
                </ol>

                <h3>5. Права Користувачів</h3>
                <ol>
                    <li>
                        Користувач має право:
                        <ul>
                            <li>Доступу до своїх даних та їх зміни;</li>
                            <li>Обмеження обробки або видалення даних;</li>
                        </ul>
                    </li>
                    <li>Запити подаються через контактну інформацію, зазначену нижче.</li>
                </ol>

                <h3>6. Зміни до Політики</h3>
                <p>Сайт залишає за собою право змінювати Політику. Оновлення публікуються з датою набрання чинності. Продовження користування Сайтом означає прийняття змін.</p>

                <h3>7. Контактна інформація</h3>
                <p>ТОВ "САНПОІНТ АЙ-ТІ СОЛЮШНС"<br />
                    Код ЄДРПОУ: 42783354<br />
                    Адреса: вул. Серпова 11, Київ, Україна<br />
                    Email: sktech@sunpoint.com.ua</p>

                <p>Дата останнього оновлення: 27.11.2024</p>
            </div>
        </div>
    )
}
export default PrivacyPolicyUA;