import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import SignalRService from './SignalRService';
import { InviteUser } from "../pages/Users/UsersList";
import { Employee, JobTitle, Skill } from "../api/EmployeesApiClient";
import dayjs from 'dayjs';
import { UploadFile } from "antd";
import { Roles } from './EmployeesStore';

export type Company = {
  Adress?: string;
  CompanyUsers?: string[];
  Description?: string;
  Id?: string;
  Tenaut?: string;
  Title?: string;
  UsersCount?: string;
  ProjectCount?: string;
  LogoId?: string;
  Currency?: string;
  WeekendDays?: number[];
  HoursVacation?: number;
  HoursSickLeave?: number;
  TariffPlanId?: number;
  IsFrozen?: boolean; 
}

interface NotificationItem {
  label: string;
  key: string;
  translationKey?: string;
  translationParams?: Record<string, any>;
  onClick: () => void;
  date: string;
  isRead: boolean;
  link: string;
}

interface TaskItem {
  key: string;
  translationKey?: string;
  translationParams?: Record<string, any>;
  label: string;
  onClick: () => void;
  isCompleted: boolean;
  date: string;
  linkTask: string;
  status: string;
}


class ApplicationStore {
  isLoading: boolean = true;
  isLoadingUser: boolean = true;
  isAuthenticated: boolean = false;
  ApiStore!: ApiStore;
  company!: Company;
  user!: Employee;
  users!: Employee[];
  jobTitles!: JobTitle[];
  skills!: Skill[];
  notifications: NotificationItem[] = [];
  tasks: TaskItem[] = [];
  SignalRService: SignalRService;
  isFreezeModalVisible: boolean = false;
  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.users = [];
    this.jobTitles = [];
    this.notifications = [];
    this.tasks = [];
    this.SignalRService = new SignalRService(this);
  }

  setFreezeModalVisible = (visible: boolean) => {
    this.isFreezeModalVisible = visible;
  };

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  checkCompanyStatus() {
    if (this.company?.IsFrozen) {
      this.setFreezeModalVisible(true);
    } else {
      this.setFreezeModalVisible(false);
    }
  }

  setIsAuthenticated = (status: boolean) => {
    this.isAuthenticated = status;
  };

  setCompany = (company: Company) => {
    this.company = {
        ...company,
        UsersCount: this.users.filter(user => user.isActive).length.toString()
    };
    console.log("Company is frozen",company.IsFrozen);
    this.checkCompanyStatus(); 
  };

  unreadCount: number = 0;

  initNotification = async () => {
    const notifications =
      await this.ApiStore.NotificationsApiClient.getNotificationsByUserId(
        this.user.id
      );
    this.notifications = notifications.map((notification) => {
      let formattedDate;
      try {
        const dateParts = notification.date.split(' ');
        const date = dateParts[0].split('.').reverse().join('-');
        const time = dateParts[1];
        const isoDate = `${date}T${time}Z`;
        const localDate = new Date(isoDate);
        formattedDate = localDate.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }).replace(/\//g, '.').replace(',', '');

      } catch (error) {
        console.error('Error formatting date:', error);
        formattedDate = 'Invalid Date';
      }

      

      const translationParams = typeof notification.translationParams === 'string'
            ? JSON.parse(notification.translationParams)
            : notification.translationParams;


      return {
        label: notification.text,
        translationKey: notification.translationKey,
        translationParams: translationParams,
        key: notification.id.toString(),
        onClick: () => {
          const url = "/" + notification.link;
          window.location.href = url;
        },
        date: formattedDate,
        isRead: notification.isRead,
        link: notification.link,
      };
    });

    this.unreadCount = this.notifications.filter((n) => !n.isRead).length;
  };

  public markNotificationAsRead = async (notificationId: number): Promise<boolean> => {
    try {
      const isSuccess = await this.ApiStore.NotificationsApiClient.markNotificationAsRead(notificationId);
      return isSuccess;
    } catch (error) {
      console.error("Failed to mark notification as read", error);
      return false;
    }
  };

  initUserTasks = async () => {
    const userTasks = await this.ApiStore.TasksApiClient.getTasksByUserId(this.user.id);
  
    this.tasks = userTasks.map((task) => {
      let formattedDate;
      try {
        const cleanedDate = task.startDate.split('.')[0] + 'Z';
        const localDate = new Date(cleanedDate);
  
        formattedDate = localDate.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }).replace(/\//g, '.').replace(',', '');
      } catch (error) {
        console.error('Error formatting task startDate:', error);
        formattedDate = 'Invalid Date';
      }
  
      const translationParams =
      typeof task.translationParams === 'string'
        ? JSON.parse(task.translationParams)
        : task.translationParams;

      console.log(task)
      return {
        key: task.id.toString(),
        translationKey: task.translationKey,
        translationParams: translationParams,
        label: task.title || "",
        onClick: () => {
          const url = `/${this.company?.Tenaut}${task.linkTask}`;
          window.location.href = url;
        },
        isCompleted: task.status === 'Done',
        date: formattedDate,
        linkTask: task.linkTask,
        status: task.status,
      };
    });
  };
  
  

  public editUserAndHandleTasks = async (user: Employee): Promise<any> => {
    const response = await this.ApiStore.EmployeesApiClient.editUser(user);
    if (response) {
      const { companyUser, taskStatus } = response;

      this.user = companyUser;

      if (taskStatus === "Done") {
        const task = this.tasks.find(t => t.label === "Complete your profile");
        if (task) {
          task.isCompleted = true;
          this.tasks = [...this.tasks];
        }
      }
      return companyUser;
    }
    return false;
  }

  public markTaskAsCompleted = async (taskId: number): Promise<boolean> => {
    try {
      const isSuccess = await this.ApiStore.TasksApiClient.markTaskAsCompleted(taskId);
      if (isSuccess) {
        const task = this.tasks.find(t => t.key === taskId.toString());
        if (task) task.isCompleted = true;
      }
      return isSuccess;
    } catch (error) {
      console.error("Failed to mark task as completed", error);
      return false;
    }
  };

  setUser = (user: Employee) => {
    this.user = user;
    this.initNotification();
    this.initUserTasks();
    this.SignalRService.startConnection();
  };

  checkCompany = async (name: string) => {
    return await this.ApiStore.CommonApiClient.CompanyCheck(name)
  };

  checkEmail = async (email: string) => {
    return await this.ApiStore.EmployeesApiClient.EmailCheck(email)
  };

  inviteUser = async (user: InviteUser): Promise<boolean> => {
    return await this.ApiStore.EmployeesApiClient.inviteUser(user.email, user.role, user.chiefId)
  };

  getUsers = async () => {
    this.isLoadingUser = true;
    const users = await this.ApiStore.EmployeesApiClient.getUsers();
    this.isLoadingUser = false;
    this.users = users;
    return users;
  }

  autorizeGoogle = async (googleToken: string) => {
    const response = await this.ApiStore.CommonApiClient.loginGoogle(googleToken).then((response) => {return response});
    return response;
  }
  
  autorize = async () => {
    this.setLoading(true);
    const response = await this.ApiStore.CommonApiClient.autorize();
    const skills = await this.ApiStore.CommonApiClient.getSkills();
    const users = await this.ApiStore.EmployeesApiClient.getUsers();
    const jobTitles = await this.ApiStore.CommonApiClient.getJobTitles();
    if (users) {
      this.users = users;
    }
    if (skills) {
      this.skills = skills;
    }
    if (jobTitles) {
      this.jobTitles = jobTitles;
    }
    if (response) {
      this.setLoading(false);
      this.setIsAuthenticated(true);
      if (response.data?.company) {
        this.setCompany({
          ...JSON.parse(response.data?.company),
          UsersCount: response.data?.usersInCompany,
          ProjectCount: response.data?.projectsInCompany,
        });
        this.checkCompanyStatus();
      }
      if (response.data?.user) {
        this.setUser(JSON.parse(response.data?.user));
      }
    } else {
      localStorage.removeItem("token");
      this.setLoading(false);
      this.setIsAuthenticated(false);
    }
  }

  setConfigApi = (token: string) => {
    this.ApiStore.setConfigApi({
      token: localStorage.getItem("token") as string,
      companyId: "0",
      userId: 0
    });
  }


  login = async (email: string, password: string): Promise<any> => {
    const result = await this.ApiStore.CommonApiClient.login(email, password);
    if (result) {
      this.ApiStore.setConfigApi({
        token: localStorage.getItem("token") as string,
        companyId: "0",
        userId: 0
      });
      await this.autorize();
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
  };

  logout = (): void => {
    localStorage.removeItem("token");
    this.isAuthenticated = false;
    this.isLoading = false;
  };

  uploadPhoto = async (file: UploadFile, companyId: string) => {
    this.isLoading = true;
    const photo = await this.ApiStore.CompanyApiClient.logoUpload(file, companyId).finally(() => {
      this.isLoading = false;
    });
    return photo;
  }

  editCompany = async (company: Company | any) => {
    this.isLoading = true;
    const result = await this.ApiStore.CompanyApiClient.editCompany(company).finally(() => {
      this.isLoading = false;
    });
    return result;
  }

  updateCompany = async () => {
    this.isLoading = true;
    if (this.company.Id) {
      await this.ApiStore.CommonApiClient.autorize().then((response) => {
        if (response.data?.company) {
          this.setCompany({
            ...JSON.parse(response.data?.company),
            UsersCount: response.data?.usersInCompany,
            ProjectCount: response.data?.projectsInCompany,
          });
        }
      }).finally(() => {
        this.isLoading = false;
      });

    }
  }

}
export default ApplicationStore;
