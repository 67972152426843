import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { Button, Flex, message, Popconfirm, Spin, Table, TableProps, Tabs, DatePicker, Image, Switch, Space } from "antd";
import { Project } from "../../stores/ProjectsStore";
import { RightOutlined, DownOutlined, UpOutlined, PlusOutlined, EditOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { useStore } from "../../stores/root-store-context";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Breadcrumb from '../../components/layout/Breadcrumb';
import { TimeReport } from "../../api/TimeReportsApiClient";
import { Employee } from "../../api/EmployeesApiClient";
import { AddTimeReport } from "./AddTimeReport";
import { useTranslation } from "react-i18next";
import { EditTimeReport } from "./EditTimeReport";
import i18n from "../../i18n";
import { MyTime } from "./MyTime";
import { getUserComponent } from "../../helpers/user";
import { UsersTime } from "./UsersTime";
import { sumAllTime } from "../../helpers/timeReports";
import { ProjectsTime } from "./ProjectsTime";
import EventPage from "../Events/EventPage";
import CalendarEvents from "../Events/CalendarEvents";
import { CalendarEvent } from "../../api/EventsApiClient";
import UserTaskPage from "../UserTasks/UserTaskPage";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import TimeReportsByDay from "./TimeReportsByDay";
import LeaveCalendar from './LeaveCalendar';

dayjs.extend(duration);

const { RangePicker } = DatePicker;

interface ByUserProps {
    setLogUserId: (id: string) => void,
    setLogProjectId: (id: string) => void,
    showModalAdd: () => void,
    showModalEdit: () => void,
    deleteTime: (id: string) => Promise<void>,
    setIsChangeUser: (status: boolean) => void,
    setEditTimeReport: (report: TimeReport) => void,
    timeReports: any[],
    startDate: Dayjs,
    endDate: Dayjs,
    setStartDate: (date: Dayjs) => void, 
    setEndDate: (date: Dayjs) => void,
    resetToCurrentMonth: () => void,
    setFullYearRange: () => void,
    activeUserTabKey: string;
    onUserTabChange: (key: string) => void;
    isAllTimeChecked: boolean;
    setIsAllTimeChecked: (checked: boolean) => void;  
}
  
  
const ByUser = observer(({
    setLogUserId,
    setLogProjectId,
    showModalAdd,
    showModalEdit,
    deleteTime,
    setIsChangeUser,
    setEditTimeReport,
    timeReports,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetToCurrentMonth,
    setFullYearRange,
    activeUserTabKey,
    onUserTabChange, 
    isAllTimeChecked, 
    setIsAllTimeChecked 
  }: ByUserProps)=> {
    const { timeReportStore} = useStore();
    const { t } = useTranslation();

    
    const handleUserTabChange = (key: string) => {
        onUserTabChange(key); 
        setIsAllTimeChecked(false);
        if (key === "vacationSickLeave") {
            setFullYearRange();
        } else {
            resetToCurrentMonth();
        }
    };

    return (
        <Spin spinning={timeReportStore.isLoading}>
            <Tabs activeKey={activeUserTabKey} onChange={handleUserTabChange}>
                <TabPane tab={t('TimeReports.ByUsers')} key="ByUser">
                    <UsersTime
                        setLogUserId={setLogUserId}
                        setLogProjectId={setLogProjectId}
                        showModalAdd={showModalAdd}
                        showModalEdit={showModalEdit}
                        deleteTime={deleteTime}
                        setIsChangeUser={setIsChangeUser}
                        setEditTimeReport={setEditTimeReport}
                        timeReports={timeReports}
                    />
                </TabPane>
                <TabPane tab={t('TimeReports.VacationSickLeave')} key="vacationSickLeave">
                    <LeaveCalendar 
                        startDate={startDate}
                        endDate={endDate}
                        isAllTimeChecked={isAllTimeChecked}
                        setIsAllTimeChecked={setIsAllTimeChecked} 
                        onToggleAllTime={(newStartDate: dayjs.Dayjs, newEndDate: dayjs.Dayjs) => {
                            setStartDate(newStartDate);
                            setEndDate(newEndDate);
                        }}
                    />
                </TabPane>
                <TabPane tab={t('TimeReports.ByDay')} key="ByDay">
                <div style={{ 
                        width: "100vw",
                        marginLeft: "calc(-50vw + 50%)",
                        marginRight: "calc(-50vw + 50%)", 
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        boxSizing: "border-box",
                    }}>
                    <TimeReportsByDay 
                        startDate={startDate} 
                        endDate={endDate}
                        setIsChangeUser={setIsChangeUser}
                        setEditTimeReport={setEditTimeReport}
                        showModalEdit={showModalEdit} 
                        deleteTime={deleteTime}/>
                        </div>
                </TabPane>
            </Tabs>
        </Spin>
    );
});
export default ByUser;