'use client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video'
import { Autoplay } from 'swiper/modules';

export default function VideoPopup({ style }: any) {
    const [isOpen, setOpen] = useState(false)
    const { t } = useTranslation();
    return (
        <>
            {style === 1 &&
                <div className="video-box">
                    <a onClick={() => setOpen(true)} className="video-btn ripple video-popup">
                        <i className="fa-solid fa-play" />
                    </a>
                </div>
            }
            {style === 2 &&
                <div className="video-box">
                    <a onClick={() => setOpen(true)} className="video-buttton video-popup">
                        <i className="fa-solid fa-play" />
                        <img src="/assets/img/about/circle-text.png" alt="text-img" className="text-circle" />
                    </a>
                </div>
            }
            {style === 3 &&
                <span className="button-text wow fadeInUp" data-wow-delay=".9s">
                    <a onClick={() => setOpen(true)} className="video-btn ripple video-popup">
                        <i className="fa-solid fa-play" />
                    </a>
                    <span className="ms-4 d-line">{t('WatchInstruction')}</span>
                </span>
            }
            <ModalVideo channel='youtube' isOpen={isOpen} videoId="T5oS5DHtCt0" onClose={() => setOpen(false)} />

        </>
    )
}