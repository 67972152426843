import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout"
import { Button, Checkbox, Flex, Form, Input, Spin, message } from "antd";
import { useStore } from "../stores/root-store-context";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { validateMessages } from "../helpers/validationMessages";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from 'dayjs';
import InputMask from 'react-input-mask';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Register from './Register';

export type ConfirmUserItem = {
    email: string,
    token: string,
    firstName: string,
    lastName: string,
    birthDay?: Dayjs | null,
    phoneNumber?: string | null;
    city?: string;
    skype?: string;
    password?: string;
    confirmPassword?: string;
    isPhoneNumberPrivate?: boolean;
}

const RegisterByInvitation = observer(() => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [confirmUserItem, setConfirmUserItem] = useState<ConfirmUserItem>({ email: "", token: "", lastName: "", password: "", firstName: "", isPhoneNumberPrivate: false });
    const [isLoading, setIsLoading] = useState(false);
    const { applicationStore, apiStore } = useStore();
    const navigate = useNavigate();
    const { companyName } = useParams();


    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const validateForm = () => {
        setTimeout(() => {
            const firstName = form.getFieldValue("firstName") || "";
            const lastName = form.getFieldValue("lastName") || "";
            const hasErrors = form.getFieldsError().some(({ errors }) => errors.length > 0);
            const passwordsMatch = form.getFieldValue("password") === form.getFieldValue("confirmPassword");

            form.getFieldsError().forEach(({ errors }) => {
                console.log(errors);
            });
            console.log(passwordsMatch);

            setIsButtonDisabled((firstName.length === 0 || lastName.length === 0) || hasErrors || !passwordsMatch);
        }, 100);
    };

    useEffect(() => {
        setIsLoading(true);
        const params = new URLSearchParams(window.location.search);
        const email = params.get('userEmail') || "";
        const token = params.get('token') || "";

        apiStore.CommonApiClient.isApproveInvite(email).then((res) => {
            if (res === false) {
                setConfirmUserItem(prev => ({ ...prev, email, token }));
            } else {
                navigate(`/${companyName}/login`);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    async function handleSubmit() {
        setIsLoading(true)
        try {
            if (confirmUserItem) {
                await apiStore.CommonApiClient.registerByInvitation(
                    {...confirmUserItem,
                        phoneNumber: (confirmUserItem.phoneNumber?.startsWith("+38") ? confirmUserItem.phoneNumber : "+38" + confirmUserItem.phoneNumber).replace('X', "")
                    }
                ).then((res) => {
                    message.open({
                        type: 'success',
                        content: t('YouSuccessfullyRegistered'),
                        key: 'checkingTenaut'
                    });
                    applicationStore.login(confirmUserItem.email, confirmUserItem.password || "").then(() => {
                        navigate(`/${companyName}`);
                    }).catch((error) => {
                        navigate(`/${companyName}`);
                        message.open({
                            type: 'error',
                            content: t('Unexpected error!') + error,
                            key: 'checkingTenaut'
                        });
                    })
                }).catch((error) => {
                    message.open({
                        type: 'error',
                        content: t('Unexpected error!') + error,
                        key: 'checkingTenaut'
                    });
                });
            }
        } catch (error) {
            message.open({
                type: 'error',
                content: t('Unexpected error!') + error,
                key: 'checkingTenaut'
            });
        } finally {
            setIsLoading(false)
        }
    }

    const validatePasswords2 = (_: any, value: any) => {
        if (!value || form.getFieldValue("password") === value) {
            return Promise.resolve();
        }
        console.log("validatePasswords");
        console.log(form.getFieldValue("password"));
        console.log(form.getFieldValue("confirmPassword"));
        console.log(value);
        console.log("value");
        console.log(_);

        return Promise.reject(new Error("Passwords do not match!"));
    };

    const validatePasswords = ({ getFieldValue }: any) => ({
        validator(_: any, value: string) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            console.log("validatePasswords");
            console.log(form.getFieldValue("password"));
            console.log(form.getFieldValue("confirmPassword"));
            console.log(value);
            console.log("value");
            console.log(_);
            return Promise.reject(new Error(t("PasswordsDoNotMatch")));
        },
    });
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div>
                <section className="contact-section fix section-padding">
                    <div className="container">
                        <div className="contact-wrapper-2">
                            <div className="col-lg-12">
                                <div className="contact-content">
                                    <div className="center">
                                        <h2 style={{ textTransform: "none" }}>{t('CompanyInvitation', { tenaunt: companyName })}</h2>
                                        <h5>
                                            {t('PleaseFillInTheRequiredFields')}
                                        </h5>
                                    </div>
                                    <Spin spinning={isLoading}>
                                        <Form
                                            className="contact-form-items"
                                            form={form}
                                            variant='outlined'
                                            layout="vertical"
                                            labelCol={{ span: 24 }}
                                            onValuesChange={(changedValues, allValues) => {
                                                setConfirmUserItem(prev => ({ ...prev, ...changedValues }));
                                                validateForm();
                                            }}
                                            validateMessages={validateMessages}
                                            requiredMark={customizeRequiredMark}
                                            autoComplete="off"
                                            onFinish={async (values) => {
                                                console.log("values");
                                                console.log(values);
                                                handleSubmit();
                                            }}
                                            scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                                                <div className="form-clt">
                                                    <Form.Item<ConfirmUserItem>
                                                        name="firstName"
                                                        style={{ width: "100%" }}
                                                        rules={[{ required: true, message: t("PleaseEnterFirstname") }]}
                                                    >
                                                        <Input placeholder={`${t("user.Name")}`} />
                                                    </Form.Item>
                                                    <Form.Item<ConfirmUserItem>
                                                        name="lastName"
                                                        style={{ width: "100%" }}
                                                        rules={[{ required: true, message: t("PleaseEnterSurname") }]}
                                                    >
                                                        <Input placeholder={`${t("user.Surname")}`} />
                                                    </Form.Item>
                                                    <Form.Item<ConfirmUserItem>
                                                        name="phoneNumber"
                                                        rules={[
                                                            { required: true, message: t("PleaseEnterPhoneNumber") },
                                                            {
                                                                validator: (_, value) => {
                                                                    const cleanValue = value
                                                                        ? value.replace('X', "")
                                                                        : "";

                                                                    console.log("Original value:", value);
                                                                    console.log("Clean value:", cleanValue);

                                                                    let fullNumber = cleanValue;
                                                                    if (!cleanValue.startsWith("+38")) {
                                                                        fullNumber = "+38" + cleanValue;
                                                                    }

                                                                    console.log("Full number:", fullNumber);

                                                                    const isValid = /^\+?380\d{9}$/.test(fullNumber);
                                                                    if (!isValid) {
                                                                        return Promise.reject(t("PhoneNumberFormat"));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <InputMask
                                                            mask="+380999999999"
                                                            placeholder={t("PhoneNumber")}
                                                            maskChar="X"
                                                            alwaysShowMask={false}
                                                        >
                                                            {(inputProps: any) => <Input {...inputProps} />}
                                                        </InputMask>
                                                    </Form.Item>

                                                    <Form.Item<ConfirmUserItem>
                                                        name="isPhoneNumberPrivate"
                                                        valuePropName="checked"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <Checkbox style={{ textTransform: "none" }}>{t("HidePhoneNumberFromOthers")}</Checkbox>
                                                    </Form.Item>
                                                    <Form.Item<ConfirmUserItem>
                                                        name="city"
                                                        style={{ width: "100%" }}
                                                        rules={[{ required: false }]}
                                                    >
                                                        <Input placeholder={`${t("City")}`} />
                                                    </Form.Item>

                                                    <Form.Item<ConfirmUserItem>
                                                        name="skype"
                                                        style={{ width: "100%" }}
                                                        rules={[{ required: false }]}
                                                    >
                                                        <Input placeholder="Skype" />
                                                    </Form.Item>
                                                    <Flex gap={10} justify="space-between" align="center" >
                                                        <Form.Item<ConfirmUserItem>
                                                            style={{ flex: 1, alignContent: 'center', justifyContent: "center" }}
                                                            label={t("passwordPlaceholder")}
                                                            name="password"
                                                            hasFeedback
                                                            rules={[
                                                                { required: true, message: t("PleaseEnterYourPassword") },
                                                                {
                                                                    pattern: passwordRegex,
                                                                    message: t("PasswordMustContainUpperLowerDigitSpecial"),
                                                                },
                                                                { min: 8, message: t("PasswordCharacters") },
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                style={{ display: 'flex' }}
                                                                placeholder={t("EnterYourPassword")}
                                                                styles={{ suffix: { fontSize: 30, margin: 0, display: "flex" } }}
                                                                iconRender={(visible) => (
                                                                    visible ? <div><EyeTwoTone /></div> : <div><EyeInvisibleOutlined /></div>
                                                                )}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item<ConfirmUserItem>
                                                            style={{ flex: 1, alignContent: 'center', justifyContent: "center" }}
                                                            label={t("ConfirmPassword")}
                                                            name="confirmPassword"
                                                            dependencies={["password"]}
                                                            rules={[
                                                                { required: true, message: t("PleaseConfirmYourPassword") },
                                                                validatePasswords,
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <Input.Password
                                                                style={{ display: 'flex' }}
                                                                styles={{ suffix: { fontSize: 30, margin: 0, display: "flex" } }}
                                                                placeholder={t("ConfirmYourPassword")}
                                                                iconRender={(visible) => (
                                                                    visible ? <div><EyeTwoTone /></div> : <div><EyeInvisibleOutlined /></div>
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Flex>

                                                </div>
                                            </div>


                                            <div className="col-lg-7 wow fadeInUp" data-wow-delay=".9s">
                                                <Form.Item>
                                                    <Button
                                                        disabled={isButtonDisabled}
                                                        type="primary" className="theme-btn" htmlType="submit" style={{ height: 65 }} onClick={async () => {

                                                            try {
                                                                // await form.validateFields();
                                                                console.log("true")
                                                                // handleSubmit();
                                                            } catch (e) {
                                                                console.log("Not a valid form")
                                                            }
                                                            // console.log(res);
                                                            // handleSubmit()
                                                        }}>
                                                        {t('Register')} <i className="fa-solid fa-arrow-right-long" />
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
})
export default RegisterByInvitation;