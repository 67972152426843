import { Table, Badge, Tooltip, Spin, Radio, Progress, Checkbox} from "antd";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import { Roles } from "../../stores/EmployeesStore"; 
import { CheckboxChangeEvent } from "antd/es/checkbox";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface LeaveCalendarProps {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  onToggleAllTime: (newStartDate: dayjs.Dayjs, newEndDate: dayjs.Dayjs) => void;
  isAllTimeChecked: boolean;
  setIsAllTimeChecked: (checked: boolean) => void;  
}


const LeaveCalendar: React.FC<LeaveCalendarProps> = observer(({ startDate, endDate, onToggleAllTime, isAllTimeChecked, setIsAllTimeChecked }) => { //
  const { employeesStore, eventStore, salaryStore, applicationStore } = useStore();
  const { t } = useTranslation();
  const currency = applicationStore.company?.Currency;
  const isManagerOrFinancier = 
  [Roles.Manager, Roles.Financier, Roles.Owner].includes(applicationStore.user.roleId);
  const [earliestDate, setEarliestDate] = useState<dayjs.Dayjs | null>(null);
  const [latestDate, setLatestDate] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    if (eventStore.events.length > 0) {

        const earliestEvent = eventStore.events.reduce((earliest, event) => {
          const eventStartDate = dayjs(event.startDate).subtract(1, 'day');
            return earliest.isAfter(eventStartDate) ? eventStartDate : earliest;
        }, dayjs());

        const latestEvent = eventStore.events.reduce((latest, event) => {
          const eventEndDate = dayjs(event.endDate);
          return latest.isBefore(eventEndDate) ? eventEndDate : latest;
      }, dayjs());
      

        setEarliestDate(earliestEvent);
        setLatestDate(latestEvent);
    }
}, [eventStore.events]);



const handleCheckboxChange = (e: CheckboxChangeEvent) => {
  const checked = e.target.checked;
  setIsAllTimeChecked(checked);
  console.log("checked", checked);

  if (checked && earliestDate && latestDate) {
    onToggleAllTime(earliestDate, latestDate);
  } else {
    onToggleAllTime(dayjs().startOf('year'), dayjs().endOf('year'));
  }
};  


  const [leaveData, setLeaveData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxHours, setMaxHours] = useState(0);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const isFinancier = applicationStore.user.roleId.toString() === Roles.Financier.toString() ||
  applicationStore.user.roleId.toString() === Roles.Owner.toString();

  const fetchLeaveData = () => {
    const currentYear = dayjs().year();
    let maxHoursTemp = 0;
    const usersToShow = isManagerOrFinancier 
    ? employeesStore.users 
    : employeesStore.users.filter(user => user.id === applicationStore.user.id);
    
    const leaveByUsers = usersToShow.map(user => {
      const userReports = eventStore.events.filter(event => {
        const eventDate = dayjs(event.startDate);
        return (
          event.userId === user.id &&
          (event.type === 'Leave' || event.type === 'SickLeave') &&
          eventDate.isAfter(startDate) && eventDate.isBefore(endDate)
        );
      });

      const { totalDays, totalHours, periods, totalPay } = calculateTotalDaysHoursAndPay(user, userReports);

      if (totalHours > maxHoursTemp) {
        maxHoursTemp = totalHours;
      }

      return {
        key: user.id,
        user: `${user.firstName} ${user.lastName}`,
        totalDays,
        totalHours,
        totalPay,
        periods,
      };
    });

    setMaxHours(maxHoursTemp);
    setLeaveData(leaveByUsers);
  };

  const calculateTotalDaysHoursAndPay = (user: any, events: any) => {
    let totalDays = 0;
    let totalHours = 0;
    let totalPay = 0;

    
  
    const weekendDays = applicationStore.company?.WeekendDays ?? [6, 0];
    const hoursSickLeave = typeof applicationStore.company.HoursSickLeave === 'string' 
    ? parseFloat(applicationStore.company.HoursSickLeave) 
    : applicationStore.company.HoursSickLeave || 0;

    const hoursVacation = typeof applicationStore.company.HoursVacation === 'string' 
    ? parseFloat(applicationStore.company.HoursVacation) 
    : applicationStore.company.HoursVacation || 0;

    const periods = events.map((event: any) => {
      const start = dayjs(event.startDate);
      const end = dayjs(event.endDate);
      let durationInDays = 0;
      let durationInHours = 0;
  
      for (let date = start; date.isSameOrBefore(end); date = date.add(1, 'day')) {
        if (!weekendDays.includes(date.day())) {
          durationInDays += 1;
          
          if (event.type === 'Leave') {
            durationInHours += hoursVacation;
          } else if (event.type === 'SickLeave') {
            durationInHours += hoursSickLeave;
          }
        }
      }
      
  
      totalDays += durationInDays;
      totalHours += durationInHours;
  
      const salary = salaryStore.getSalaryForUserAtDate(user.id, event.startDate);
      const hourlyRate = salary?.salaryPerHour || 0;
  
      totalPay += durationInHours * hourlyRate;
  
      return {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
        type: event.type
      };
    });
  
    return { totalDays, totalHours, totalPay, periods };
  };
  


  useEffect(() => {
    setIsLoading(true);
    
    if (!eventsLoaded) {
      Promise.all([eventStore.getAll(), salaryStore.getAll()]).then(() => {
        setEventsLoaded(true);
        setIsLoading(false);
        fetchLeaveData();
      });   
    } else {
      setIsLoading(false);
      fetchLeaveData();
    }
  }, [startDate, endDate]);

  const columns = [
    {
      title: t("User"),
      dataIndex: "user",
      key: "user",
    },
    {
      title: t("Total Days"),
      dataIndex: "totalDays",
      key: "totalDays",
      render: (totalDays: number) => `${totalDays} ${t("days")}`,
    },
    {
      title: t("Total Hours"),
      dataIndex: "totalHours",
      key: "totalHours",
      render: (totalHours: number) => `${totalHours} ${t("hours")}`,
    },
    {
      title: t("Leave Periods"),
      dataIndex: "periods",
      key: "periods",
      render: (periods: any[]) => (
        <ul>
          {periods.map((period, index) => (
            <li key={index}>
              {t(`${period.type}`)}: {period.start} - {period.end}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: t("Progress (Hours)"),
      dataIndex: "totalHours",
      key: "progress",
      render: (totalHours: number, record: any) => {
        return (
          <>
            <Tooltip title={t("Total Leave and Sick Leave Hours")}>
              <Progress
                percent={(totalHours / maxHours) * 100}
                format={() => `${totalHours} ${t("hours")}`}
                status="active"
                strokeColor="#52c41a"
              />
            </Tooltip>
          </>
        );
      },
    }
    
  ];

  if (isFinancier) {
    columns.push({
      title: t("Total Pay"),
      dataIndex: "totalPay",
      key: "totalPay",
      render: (totalPay: number) => `${currency}${totalPay.toFixed(2)}`
    });
  }

  if (isLoading) {
    return (
      <Spin spinning={true} style={{ minHeight: 600 }}>
        <div style={{ textAlign: 'center', marginTop: 20 }}>{t("Loading...")}</div>
      </Spin>
    );
  }


  return (
    <>
      <Checkbox checked={isAllTimeChecked} onChange={handleCheckboxChange}>
        {t("All Time")}
      </Checkbox>

      <Table
        columns={columns}
        dataSource={leaveData}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
});

export default LeaveCalendar;
