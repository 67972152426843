import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { Dayjs } from 'dayjs';
import { Employee, Skill } from "../api/EmployeesApiClient";
import { Roles } from "./EmployeesStore";
import { TimeReport } from "../api/TimeReportsApiClient";
import { CalendarEvent } from "../api/EventsApiClient";

class EventStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  events: CalendarEvent[];
  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.events = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  add = async (event: any) => {
    this.isLoading = true;
    const response = await this.ApiStore.EventsApiClient.addEvent(event);
    this.isLoading = false;
    return response;
  }
  
  getAll = async () => {
    this.isLoading = true;
    const result = await this.ApiStore.EventsApiClient.getAll().then(res=>{
      this.events = res;
      return res;
    }).finally(() => {
      this.isLoading = false;
    });
    return result;
  }

  deleteById = async (id: string) => {
    this.isLoading = true;
    const project = await this.ApiStore.EventsApiClient.deleteEvent(id).then(() => {
      this.events = this.events.filter(el => el.id !== id)
    }).finally(() => {
      this.isLoading = false;
    });
    return project;
  }

}
export default EventStore;
