import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { Roles } from "../stores/EmployeesStore";
import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";


export default class EmployeesApiClient extends SkTechApiClient {

  public accountRegister = async (email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyId: number,
    role: string = "user"): Promise<any> => {
    return await this.Post('/api/Account/register', {
      "email": email,
      "password": password,
      "FirstName": firstName,
      "LastName": lastName,
      "companyId": companyId,
      "role": role
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public getJobTitles = async (): Promise<JobTitle[]> => {
    return await this.Get('/api/JobTitle').then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  }

  public getUsers = async (): Promise<Employee[]> => {
    return await this.Get('/api/CompanyUsers')
      .then((response) => {
        if (response.status === 200) {
          return response.data.map((el: {
            birthDay: string | number | Date | Dayjs | null | undefined;
          }) => {
            return {
              ...el,
              birthDay: el.birthDay ? dayjs(el.birthDay) : null,
            }
          });
        } else if (response.status === 402) {
          return [];
        }
        return [];
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  

  public getUserById = async (id: string): Promise<Employee> => {
    return await this.Get(`/api/CompanyUsers/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public getHistoryByUser = async (id: string): Promise<HistoryItem[]> => {
    return await this.Get(`/api/CompanyUsers/GetHistoryByUser?userId=${id}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public editUser = async (user: Employee): Promise<any> => {
    return await this.Patch(`/api/CompanyUsers/${user.id}`, {
      ...user
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public changePassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
    return await this.Post(`/api/Account/ChangePassword`, {
      oldPassword,
      newPassword
    })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public changeEmail = async (newEmail: string): Promise<boolean> => {
    return await this.Post(`/api/Account/ChangeEmail?newEmail=${newEmail}`)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }
  
  public EmailCheck = async (email: string): Promise<boolean> => {
    return await this.Get(`/api/CompanyUsers/CheckEmail?email=${email}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public addExperience = async(company: string, position: string, description: string, startDate: any, endDate: any)=>{
    return await this.Post(`/api/CompanyUsers/AddExperience`, {
      "Company": company,
      "Position": position,
      "Description": description,
      "StartDate": startDate,
      "EndDate": endDate
    })
      .then(function (response) {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }
  
  public removeExperience = async(company: string, position: string, description: string, startDate: any, endDate: any)=>{
    return await this.Post(`/api/CompanyUsers/RemoveExperience`, {
      "Company": company,
      "Position": position,
      "Description": description,
      "StartDate": startDate,
      "EndDate": endDate
    })
      .then(function (response) {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

  public inviteUser = async (email: string, role: string, chiefId: string): Promise<boolean> => {
    return await this.Post(`/api/Account/Invite?userEmail=${email}&userRole=${role}&chiefId=${chiefId}`).catch(error => {
      throw new Error(error.response.data)
    })
  }

  public addJobTitle = async (title: string, description: string): Promise<any> => {
    return await this.Post(`/api/JobTitle`, {
      "title": title,
      "description": description
    }).catch(error => {
      throw new Error(error.response.data)
    })
  }

  public deleteUser = async (userId: string): Promise<void> => {
    return await this.Delete(`/api/CompanyUsers/${userId}`)
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Failed to delete user");
        }
      })
      .catch(error => {
        console.error("Error deleting user:", error);
        throw error;
      });
  };

  public photoUpload = async (file: UploadFile, userId: string): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as RcFile);
    return await this.Post(`/api/File/UploadUserPhoto?id=${userId}`, formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
}

export interface HistoryItem {
  id: string,
  userId: string,
  projectId: string,
  startDate: string,
  endDate: string
}
export interface Skill {
  skillId: string;
  title: string;
  rate?: number;
  experience?: number;
  level?: number;
}

export interface JobTitle {
  id: string;
  title: string;
  description: string;
}

export interface Employee {
  id: string;
  companyId: number;
  chiefId?:string;
  firstName: string;
  lastName: string;
  role: string;
  roleId: Roles;
  manager: number;
  birthDay: Dayjs | null;
  aboutMe: string;
  isActive: boolean;
  company: any;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  phoneNumber: string | null;
  phoneNumberConfirmed: boolean;
  isPhoneNumberPrivate?: boolean;
  twoFactorEnabled: boolean;
  lockoutEnd: Date | null;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  userSkills: {
    skill: {
      skillId: string,
      title: string
    },
    skillLevel: string,
    experienceYears: string,
    confirmationDate: string
  }[],
  userProjects: any[];
  photoId?: string;
  education: string;
  experience: string;
  city: string;
  jobTitle: string;
  jobTitleId: string;
  skype: string;
  hobby: string;
  socialNetwork: string;
  isApproveInvite?: boolean;
}